<template lang="pug">
  Favors
</template>

<script>
import Favors from '@/components/Favors'

export default {
  name: 'FavorsView',

  components: {
    Favors,
  },
}
</script>
