import * as types from './types'

export default {
  [types.SET_RECEPTION_POINTS]: (state, receptionPoints) => {
    state.receptionPoints = receptionPoints.items
    state.total = receptionPoints.total
  },

  [types.CLEAR]: (state) => {
    state.receptionPoints = []
    state.total = null
  },

  [types.SET_CURRENT_RECEPTION_POINT]: (state, id) => {
    let receptionPoint = state.receptionPoints.find((item) => item.id === id)
    state.currentReceptionPoint = receptionPoint
  },

  [types.CREATE_RECEPTION_POINT]: (state, receptionPoint) => {
    state.receptionPoints.push(receptionPoint)
  },

  [types.UPDATE_RECEPTION_POINT]: (state, payload) => {
    let receptionPoint = state.receptionPoints.find(
      (item) => item.id === payload.id
    )

    receptionPoint.name = payload.name
    receptionPoint.company = payload.company
    receptionPoint.location = payload.location
    receptionPoint.slug = payload.slug
    receptionPoint.contacts = payload.contacts
    receptionPoint.avatar = payload.avatar
    receptionPoint.city = payload.city
  },

  [types.DELETE_RECEPTION_POINT]: (state, id) => {
    const removeIndex = state.receptionPoints.map((item) => item.id).indexOf(id)

    state.receptionPoints.splice(removeIndex, 1)

    state.currentReceptionPoint = null
  },

  [types.SET_RECEPTION_POINT_METALS]: (state, { id, payload }) => {
    let receptionPoint = state.receptionPoints.find((item) => item.id === id)

    receptionPoint.prices_validity_time = payload.prices_validity_time
    receptionPoint.metals = payload.metals
  },

  [types.SET_RECEPTION_POINT_SCHEDULE]: (state, { id, payload }) => {
    let receptionPoint = state.receptionPoints.find((item) => item.id === id)

    receptionPoint.schedule = payload.schedule
  },

  [types.SET_RECEPTION_POINT_FAVORS]: (state, { id, payload }) => {
    let receptionPoint = state.receptionPoints.find((item) => item.id === id)

    receptionPoint.favors = payload.favors
  },
}
