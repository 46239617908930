<script>
import { CREATE_CITY } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'

import CitiesTextEditor from './CitiesTextEditor'

const formTemplate = {
  name: '',
  postalCode: null,
  region: '',
  federalDistrict: null,
  longitude: null,
  latitude: null,
  population: null,
  seo: {
    reception: '',
    export: '',
    dismantling: '',
    prices: '',
  },
  priority: null,
  slug: '',
  regionType: null,
  declination: {
    V: '',
    D: '',
    P: '',
    R: '',
    T: '',
    multiple: {
      V: '',
      D: '',
      I: '',
      P: '',
      R: '',
      T: '',
    },
  },
}

export default {
  name: 'CitiesCreateModal',

  components: {
    CitiesTextEditor,
  },

  data: () => ({
    form: { ...formTemplate },

    isCityFormValid: false,
    federalDistrictItems: [
      'Центральный',
      'Северо-Западный',
      'Южный',
      'Северо-Кавказский',
      'Приволжский',
      'Уральский',
      'Сибирский',
      'Дальевосточный',
    ],
    regionTypeItems: [
      'республика',
      'область',
      'край',
      'чувашия',
      'автономный округ',
    ],

    slugError: null,
    nameError: null,
    postalCodeError: null,
    priorityError: null,
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('cities', {
      $_cities: 'cities',
      $_total: 'total',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'CitiesCreateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'CitiesCreateModal', open: value })

        this.nameError = null
        this.slugError = null
        this.postalCodeError = null
        this.priorityError = null

        this.form = { ...formTemplate }
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_CITY)
    },

    isDisabled() {
      return !this.isCityFormValid
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('cities', {
      $_createCity: 'createCity',
    }),

    closeModal() {
      this.dialogVisible = false
      this.form = { ...formTemplate }
    },

    createCity() {
      this.$wait.start(CREATE_CITY)

      this.$refs.form.resetErrorBag()
      this.nameError = null
      this.slugError = null
      this.postalCodeError = null

      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_cities.some((item) => {
            return item.name.toUpperCase() === this.form.name.toUpperCase()
          })

          const isPostalCodeDublicate = this.$_cities.some((item) => {
            return item.postal_code === this.form.postalCode
          })

          const isSlugDublicate = this.$_cities.some((item) => {
            if (!item.slug) return
            return item.slug.toUpperCase() === this.form.slug.toUpperCase()
          })

          const isPriorityDublicate = this.$_cities.some((item) => {
            if (!item.priority) return
            return Number(item.priority) === Number(this.form.priority)
          })

          if (
            isNameDublicate ||
            isSlugDublicate ||
            isPostalCodeDublicate ||
            isPriorityDublicate
          ) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'
            if (isPostalCodeDublicate) this.postalCodeError = 'Найден дубликат.'
            if (isPriorityDublicate) this.priorityError = 'Найден дубликат.'

            this.$nextTick(() => {
              const el = document.querySelector(
                '.v-input.error--text:first-of-type'
              )

              el.scrollIntoView({ block: 'center' })
            })

            return
          }

          if (!this.isCityFormValid) return

          await this.$_createCity(this.form)

          this.nameError = null
          this.slugError = null
          this.postalCodeError = null

          this.form = { ...formTemplate }

          this.$wait.end(CREATE_CITY)

          this.dialogVisible = false
        } catch (error) {
          console.log(error)
        } finally {
          this.$wait.end(CREATE_CITY)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
    fullscreen
  )
    v-card(ref="card")
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-toolbar-title Добавить город
        v-spacer
        v-btn(color="red" @click="closeModal") Закрыть
      v-card-actions
        v-col
          v-form(v-model="isCityFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="form.name"
                :rules="[() => Boolean(form.name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-text-field(
                label="Почтовый код"
                type="number" 
                required
                v-model="form.postalCode"
                :rules="[() => Boolean(form.postalCode) || 'Поле обязательно']"
                :error-messages="postalCodeError"
              )
            v-row
              v-text-field(
                label="Регион"
                required
                v-model="form.region"
                :rules="[() => Boolean(form.region) || 'Поле обязательно']"
              )
            v-row
              v-select(
                label="Федеральный округ"
                :items="federalDistrictItems"
                v-model='form.federalDistrict'
                required
                :rules="[() => Boolean(form.federalDistrict) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Долгота"
                required
                type="number"
                v-model="form.longitude"
                :rules="[() => Boolean(form.longitude) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Широта"
                required
                type="number"
                v-model="form.latitude"
                :rules="[() => Boolean(form.latitude) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Население"
                type="number"
                required
                v-model="form.population"
                :rules="[() => Boolean(form.population) || 'Поле обязательно']"
              )
            v-row.mt-8
              .font-weight-bold SEO
            v-row Прием металлолома
            v-row
              CitiesTextEditor(v-model="form.seo.reception" placeholder="Прием")
            v-row.mt-8 Вывоз металлолома
            v-row
              CitiesTextEditor(v-model="form.seo.export" placeholder="Вывоз")
            v-row.mt-8 Демонтаж металлолома
            v-row
              CitiesTextEditor(v-model="form.seo.dismantling" placeholder="Демонтаж")
            v-row.mt-8 Цены
            v-row
              CitiesTextEditor(v-model="form.seo.prices" placeholder="Цены")
            v-row.mt-8
              .font-weight-bold Не обязательно
            v-row
              v-text-field(
                label="Приоритет"
                type="number"
                v-model="form.priority"
                :error-messages="priorityError"
              )
            v-row
              v-text-field(
                label="Slug"
                v-model="form.slug"
                :error-messages="slugError"
              )
            v-row
              v-select(
                :items="regionTypeItems"
                label="Тип региона"
                v-model="form.regionType"
                clearable
              )
            v-row
              .font-weight-medium Склонения
            v-row
              v-text-field(
                label="В"
                v-model="form.declination.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="form.declination.D"
              )
            v-row
              v-text-field(
                label="П"
                v-model="form.declination.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="form.declination.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="form.declination.T"
              )
            v-row
              .font-weight-medium Множественные Склонения
            v-row
              v-text-field(
                label="В"
                v-model="form.declination.multiple.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="form.declination.multiple.D"
              )
            v-row
              v-text-field(
                label="И"
                v-model="form.declination.multiple.I"
              )
            v-row
              v-text-field(
                label="П"
                v-model="form.declination.multiple.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="form.declination.multiple.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="form.declination.multiple.T"
              )
            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click="createCity" :loading="isLoading") Добавить
</template>
