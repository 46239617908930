<script>
import ReceptionPointsCreateFavorsModal from './ReceptionPointsCreateFavorsModal.vue'
import ReceptionPointsUpdateFavorsModal from './ReceptionPointsUpdateFavorsModal.vue'

export default {
  name: 'ReceptionPointsFavorsModals',

  components: {
    ReceptionPointsCreateFavorsModal,
    ReceptionPointsUpdateFavorsModal,
  },
}
</script>

<template lang="pug">
  .Favors
    ReceptionPointsCreateFavorsModal
    //ReceptionPointsUpdateFavorsModal
</template>
