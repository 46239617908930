<script>
import { GET_USERS } from '@/common/constants/wait'
import { format } from 'date-fns'
import debounce from 'lodash/debounce'
import { mapGetters, mapActions } from 'vuex'

import UsersCreateModal from './UsersCreateModal.vue'
import UsersDeleteModal from './UsersDeleteModal.vue'
import UsersUpdateModal from './UsersUpdateModal.vue'

export default {
  name: 'Users',

  components: {
    UsersCreateModal,
    UsersUpdateModal,
    UsersDeleteModal,
  },

  data: () => ({
    search: {
      phone: '',
      q: '',
    },
    page: 1,
    itemsPerPage: 10,
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'name', text: 'ФИО', align: 'center' },
      { value: 'phone', text: 'Телефон', align: 'center' },
      { value: 'is_admin', text: 'Администратор', align: 'center' },
      { value: 'joined_at', text: 'Дата регистрации', align: 'center' },
      { value: 'actions', text: 'Действия', align: 'center' },
    ],
    phoneMask: ['+', ...Array(20).fill(/[0-9]|[-+() ]/)],
    phoneRules: [
      (v) => !!v || 'Заполните номер телефона',
      (v) =>
        /\+*(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
          v
        ) || 'Введите правильный номер телефона',
    ],
  }),

  watch: {
    page: function (value) {
      if (Number(this.$route.query.page) === value) return

      this.$router.replace({
        path: 'users',
        query: value === 1 ? null : { page: value },
      })
    },
  },

  computed: {
    ...mapGetters('users', {
      $_users: 'users',
      $_total: 'total',
    }),

    isLoading() {
      return this.$wait.is(GET_USERS)
    },

    pagesCount() {
      if (!this.$_total) return
      return Math.ceil(this.$_total / 10)
    },

    query() {
      return {
        limit: this.itemsPerPage,
        offset: this.page * this.itemsPerPage - this.itemsPerPage,
        q: this.search.q,
        phone: this.search.phone,
      }
    },
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
    }),

    ...mapActions('users', {
      $_getUsers: 'getUsers',
      $_setCurrentUser: 'setCurrentUser',
      $_getUserToken: 'getUserToken',
      $_clearUsers: 'clear',
    }),

    async fetchUsers() {
      try {
        this.$wait.start(GET_USERS)

        await this.$_getUsers(this.query)
      } catch (error) {
        return
      } finally {
        this.$wait.end(GET_USERS)
      }
    },

    openCreateModal() {
      this.$_showModal({ name: 'UsersCreateModal' })
    },

    openUpdateModal({ id }) {
      this.$_setCurrentUser(id)
      this.$_showModal({ name: 'UsersUpdateModal', open: true })
    },

    timeFormat(time) {
      if (!time) return 'Не установлено'
      return format(new Date(time * 1000), 'dd/MM/yyyy HH:mm')
    },

    async openSite({ id }) {
      const token = await this.$_getUserToken(id)

      if (process.env.NODE_ENV === 'development') {
        window.open(
          `https://web.dev.sdavalych.ru/auth?token=${token}`,
          '_blank'
        )
      } else {
        window.open(`https://sdavalych.ru/auth?token=${token}`, '_blank')
      }
    },

    async openCompanies({ id }) {
      window.open(`/companies?user=${id}`)
    },

    onNameSearch: debounce(function (value) {
      if (!value) this.$router.replace({ query: null })
      else this.$router.replace({ query: { q: value } })

      this.page = 1
      this.search.phone = ''

      this.fetchUsers()
    }, 500),

    onPhoneSearch: debounce(function (value) {
      if (!value) this.$router.replace({ query: null })
      else this.$router.replace({ query: { phone: value } })

      this.page = 1
      this.search.q = ''

      this.fetchUsers()
    }, 500),
  },

  mounted() {
    if (this.$route.query.page) this.page = Number(this.$route.query.page)
    if (this.$route.query.q) this.search.q = this.$route.query.q
    if (this.$route.query.phone) this.search.phone = this.$route.query.phone

    this.fetchUsers()
  },

  beforeDestroy() {
    this.$_clearUsers()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Пользователи
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_users"
      :loading="isLoading"
      disable-sort
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @click:row="openUpdateModal"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        UsersCreateModal
        UsersUpdateModal
        UsersDeleteModal
        v-row

          v-col(cols="3")
            v-text-field.ma-4(
              label="Номер телефона" 
              v-model="search.phone"
              @input="onPhoneSearch"
              v-mask="phoneMask"
              :rules="optionalPhoneRules"
            ) 
          v-col.d-flex.justify-center.align-center(cols="1")
            .font-weight-medium или
          v-col(cols="3")
            v-text-field.ma-4(
              label="ФИО" 
              v-model="search.q"
              @input="onNameSearch"
            )
      template(#item.id="{ item }")
        span {{ item.id }}
      template(#item.name="{ item }")
        .text-truncate {{ item.name && `${item.name.last} ${item.name.first} ${item.name.middle}` || 'Отсутствует' }}
      template(#item.phone="{ item }")
        .text-truncate {{ item.phone }}
      template(#item.joined_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.joined_at) }}
      template(#item.is_admin="{item}")
        v-icon(color="primary" v-if="item.is_admin") mdi-check
        v-icon(color="secondary" v-else) mdi-close
      template(#item.actions="{ item }")
        v-btn(text color="primary" @click.stop="openSite(item)") ЛК на сайте
        router-link.text-decoration-none( :to='`/companies?user=${item.id}`' target='_blank')
          v-btn(text color="primary" @click.stop) Компании пользователя

        
    .text-center.pt-2
      v-pagination.my-6(
        :disabled="isLoading"
        color="primary"
        v-model='page'
        :length="pagesCount"
        @input="fetchUsers"
      )
</template>

<style scoped>
.text-truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}
@media (max-width: 1024px) {
  .text-truncate {
    max-width: 200px;
  }
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
