import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.metals = []
    state.total = null
  },

  [types.SET_METALS]: (state, metals) => {
    state.metals = metals.items
    state.total = metals.total
  },

  [types.SET_CURRENT_METAL]: (state, id) => {
    let metal = state.metals.find((item) => item.id === id)
    state.currentMetal = metal
  },

  [types.SET_CURRENT_SUBMETAL]: (state, id) => {
    let metal = state.currentMetal

    let submetal = metal.sub_metals.find((item) => item.id === id)

    state.currentSubmetal = submetal
  },

  [types.CREATE_METAL]: (state, metal) => {
    state.metals.push(metal)
  },

  [types.UPDATE_METAL]: (state, payload) => {
    let metal = state.metals.find((item) => item.id === payload.id)

    metal.name = payload.name
    metal.slug = payload.slug
    metal.priority = payload.priority
    metal.declination = payload.declination
    metal.max_price = payload.max_price
  },

  [types.DELETE_METAL]: (state, id) => {
    const removeIndex = state.metals.map((item) => item.id).indexOf(id)

    state.metals.splice(removeIndex, 1)

    state.currentMetal = null
  },

  [types.CREATE_SUBMETAL]: (state, { submetal }) => {
    let metal = state.currentMetal

    if (!metal.sub_metals) metal.sub_metals = []

    metal.sub_metals.push(submetal)
  },

  [types.UPDATE_SUBMETAL]: (state, payload) => {
    let metal = state.currentMetal

    let submetal = metal.sub_metals.find((item) => item.id === payload.id)

    submetal.name = payload.name
    submetal.slug = payload.slug
    submetal.photo = payload.photo
    submetal.description = payload.description
    submetal.priority = payload.priority
    submetal.max_price = payload.max_price
  },

  [types.DELETE_SUBMETAL]: (state, id) => {
    let metal = state.currentMetal

    const removeIndex = metal.sub_metals.map((item) => item.id).indexOf(id)

    metal.sub_metals.splice(removeIndex, 1)

    state.currentSubmetal = null
  },
}
