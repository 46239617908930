import * as types from './types'

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  setCurrentFaq({ commit }, id) {
    commit(types.SET_CURRENT_FAQ, id)
  },

  async createFaq({ commit, dispatch }, { title, description }) {
    try {
      const faq = await this.$faqApi.createFaq({ data: { title, description } })

      commit(types.CREATE_FAQ, faq)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'ЧаВО создан' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать ЧаВО' },
        { root: true }
      )

      throw error
    }
  },

  async getFaq({ commit }) {
    try {
      const faq = await this.$faqApi.getFaq()

      commit(types.SET_FAQ, faq)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async deleteFaq({ commit, dispatch }, id) {
    try {
      await this.$faqApi.deleteFaq(id)()

      commit(types.DELETE_FAQ, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'ЧаВО удален' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить ЧаВО' },
        { root: true }
      )

      throw error
    }
  },

  async updateFaq({ commit, dispatch }, { id, title, description }) {
    try {
      const faq = await this.$faqApi.updateFaq(id)({
        data: { title, description },
      })

      commit(types.UPDATE_FAQ, faq)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'ЧаВО обновлен' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить ЧаВО' },
        { root: true }
      )

      throw error
    }
  },
}
