import App from '@/App'
import * as plugins from '@/plugins'
import router from '@/router'
import store from '@/store'
import Vue from 'vue'

Vue.config.productionTip = false

const appConfig = {
  router,
  store,
  render: (h) => h(App),
}

plugins.setup(appConfig)

new Vue(appConfig).$mount('#app')
