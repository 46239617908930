import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.users = []
    state.total = null
  },

  [types.SET_USERS]: (state, users) => {
    state.users = users.items
    state.total = users.total
  },

  [types.SET_CURRENT_USER]: (state, id) => {
    let user = state.users.find((item) => item.id === id)
    state.currentUser = user
  },

  [types.CREATE_USER]: (state, user) => {
    state.users.push(user)
  },

  [types.UPDATE_USER]: (state, payload) => {
    let user = state.users.find((item) => item.id === payload.id)

    user.name = payload.name
    user.phone = payload.phone
  },

  [types.DELETE_USER]: (state, id) => {
    const removeIndex = state.users.map((item) => item.id).indexOf(id)

    state.users.splice(removeIndex, 1)

    state.currentUser = null
  },
}
