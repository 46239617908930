import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

export function setup(app) {
  const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: colors.green.lighten2,
          secondary: colors.orange.darken2,
        },
      },
    },
  })

  Vue.use(Vuetify)

  app.vuetify = vuetify

  Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi',
  })
}
