export default {
  metals: (state) => state.metals,
  total: (state) => state.total,
  currentMetal: (state) => state.currentMetal,
  currentSubmetal: (state) => state.currentSubmetal,
  submetals: (state) => {
    const id = state.currentMetal?.id
    if (id) return state.metals.find((item) => item.id === id).sub_metals
  },
}
