import values from 'lodash/values'

import * as types from './types'

const getDeclination = (declination) => {
  let result = {
    В: declination.V || undefined,
    Д: declination.D || undefined,
    П: declination.P || undefined,
    Р: declination.R || undefined,
    Т: declination.T || undefined,
  }

  if (values(declination.multiple).some((item) => Boolean(item))) {
    result.множественное = {
      В: declination.multiple.V || undefined,
      Д: declination.multiple.D || undefined,
      И: declination.multiple.I || undefined,
      П: declination.multiple.P || undefined,
      Р: declination.multiple.R || undefined,
      Т: declination.multiple.T || undefined,
    }
  }

  return result
}

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  async getMetals({ commit }) {
    try {
      const metals = await this.$metalsApi.getMetals()

      commit(types.SET_METALS, metals)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  setCurrentMetal({ commit }, id) {
    commit(types.SET_CURRENT_METAL, id)
  },

  setCurrentSubmetal({ commit }, id) {
    commit(types.SET_CURRENT_SUBMETAL, id)
  },

  async createMetal(
    { commit },
    { name, slug, priority, declination, max_price }
  ) {
    try {
      const metal = await this.$metalsApi.createMetal({
        data: {
          name,
          slug,
          priority,
          declination: getDeclination(declination),
          max_price,
        },
      })

      commit(types.CREATE_METAL, metal)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металл создан' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать металл' },
        { root: true }
      )
    }
  },

  async updateMetal(
    { commit },
    { id, name, slug, priority, declination, max_price }
  ) {
    try {
      const metal = await this.$metalsApi.updateMetal(id)({
        data: {
          name,
          slug,
          priority,
          declination: getDeclination(declination),
          max_price,
        },
      })

      commit(types.UPDATE_METAL, metal)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металл обновлен' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить металл' },
        { root: true }
      )
    }
  },

  async deleteMetal({ commit, dispatch }, id) {
    try {
      await this.$metalsApi.deleteMetal(id)()

      commit(types.DELETE_METAL, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металл удален' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить металл' },
        { root: true }
      )
    }
  },

  async createSubmetal(
    { commit },
    { name, slug, photo, description, parentId, priority, max_price }
  ) {
    try {
      const submetal = await this.$metalsApi.createMetal({
        data: {
          name,
          slug,
          photo,
          description,
          parent_id: parentId,
          priority,
          max_price,
        },
      })

      commit(types.CREATE_SUBMETAL, { parentId, submetal })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металл создан' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать металл' },
        { root: true }
      )
    }
  },

  async updateSubmetal(
    { commit },
    { id, name, slug, photo, description, priority, max_price }
  ) {
    try {
      const submetal = await this.$metalsApi.updateMetal(id)({
        data: {
          name,
          slug,
          photo,
          description,
          priority,
          max_price,
        },
      })

      commit(types.UPDATE_SUBMETAL, {
        id,
        name,
        slug,
        photo,
        description,
        priority,
        max_price,
      })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металл обновлен' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить металл' },
        { root: true }
      )
    }
  },

  async deleteSubmetal({ commit, dispatch }, id) {
    try {
      await this.$metalsApi.deleteMetal(id)()

      commit(types.DELETE_SUBMETAL, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металл удален' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить металл' },
        { root: true }
      )
    }
  },

  async uploadFile({ dispatch }, file) {
    try {
      const fileUrl = await this.$metalsApi.uploadMetalFile({
        data: file,
      })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Файл загружен' },
        { root: true }
      )

      return fileUrl
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось загрузить файл' },
        { root: true }
      )
    }
  },
}
