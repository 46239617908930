import {
  object,
  string,
  integer,
  array,
  nullable,
  optional,
  assign,
  unknown,
} from 'superstruct'

import { user } from './Users'

export const company = object({
  id: optional(integer()),
  name: string(),
  created_at: optional(integer()),
  updated_at: optional(nullable(integer())),
  avatar: optional(nullable(string())),
  email: optional(nullable(string())),
  site: optional(nullable(string())),
  user: optional(user),
  user_id: optional(integer()),
  about: unknown(),
})

export const getCompaniesRequestStruct = object({
  params: object({
    company_id: optional(integer()),
    user_id: optional(integer()),
    limit: optional(integer()),
    offset: optional(integer()),
    name: optional(string()),
    company_id: optional(integer()),
    location: optional(string()),
  }),
})

export const getCompaniesResponseStruct = object({
  total: integer(),
  items: array(company),
})

export const createCompanyRequestStruct = object({
  data: company,
})

export const createCompanyResponseStruct = company

export const updateCompanyRequestStruct = object({
  data: company,
})

export const updateCompanyResponseStruct = company
