var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap mt-2 mb-4"},[_c('h1',[_vm._v("Города")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_cities,"loading":_vm.isLoading,"items-per-page":_vm.search.itemsPerPage,"disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.openUpdateModal},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',[_vm._v("Загрузка")])]},proxy:true},{key:"top",fn:function(){return [_c('CitiesDeleteModal'),_c('CitiesCreateModal'),_c('CitiesUpdateModal'),_c('div',{staticClass:"font-weight-medium mx-4 pt-4"},[_vm._v("Городов: "+_vm._s(_vm.$_total))]),_c('div',{staticClass:"d-flex flex-wrap pt-4"},[_c('v-text-field',{staticClass:"ml-4 pa-0",staticStyle:{"max-width":"200px"},attrs:{"label":"Поиск"},on:{"input":_vm.onSearch},model:{value:(_vm.search.city),callback:function ($$v) {_vm.$set(_vm.search, "city", $$v)},expression:"search.city"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"primary"},on:{"click":_vm.clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-autorenew")])],1)]}}])},[_c('span',[_vm._v("Очистить фильтры")])])],1)]},proxy:true},{key:"item.region_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.region_type || 'Не указано'))])]}},{key:"item.postal_code",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.postal_code || 'Не указано'))])]}},{key:"item.federal_district",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.federal_district || 'Не указано'))])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.priority)?_c('span',[_vm._v(_vm._s(item.priority))]):_c('span',[_vm._v("Нет")])])]}},{key:"item.declination",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(!_vm.isObjectEmpty(item.declination))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-close")])],1)]}},{key:"item.seo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},_vm._l((item.seo),function(seo){return _c('div',{staticClass:"seo"},[(seo)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-close")])],1)}),0)]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"my-6 px-4",attrs:{"disabled":_vm.isLoading,"color":"primary","total-visible":10,"length":_vm.pagesCount},on:{"input":_vm.onPageChange},model:{value:(_vm.search.page),callback:function ($$v) {_vm.$set(_vm.search, "page", $$v)},expression:"search.page"}})],1),_vm._m(0),_c('v-data-table',{staticClass:"elevation-1 mb-4",attrs:{"headers":_vm.regionHeaders,"items":_vm.$_regions,"loading":_vm.isLoading,"items-per-page":_vm.$_regions.length,"disable-sort":"","hide-default-footer":""}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center flex-wrap mt-8 mb-4"},[_c('h1',[_vm._v("Регионы")])])}]

export { render, staticRenderFns }