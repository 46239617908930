import { createAxiosInstance } from './utils/createAxiosInstance'
import { validateData } from './utils/validateData'

export class BaseClient {
  constructor({ getToken, clearToken, axios }) {
    this.axiosInstance = createAxiosInstance({ getToken, clearToken, axios })
  }

  createRequest(requestConfig, requestType, responseType) {
    return async (requestData = {}) => {
      validateData(requestType, requestData, 'Invalid request data.')

      try {
        const response = await this.axiosInstance.request({
          ...requestConfig,
          ...requestData,
        })

        validateData(responseType, response.data, 'Invalid response data.')

        return response.data
      } catch (error) {
        throw error.response.data
      }
    }
  }
}
