<script>
import ReceptionPointsCreateScheduleModal from './ReceptionPointsCreateScheduleModal.vue'
import ReceptionPointsUpdateScheduleModal from './ReceptionPointsUpdateScheduleModal.vue'

export default {
  name: 'ReceptionPointsScheduleModals',

  components: {
    ReceptionPointsCreateScheduleModal,
    ReceptionPointsUpdateScheduleModal,
  },
}
</script>

<template lang="pug">
  .schedule
    ReceptionPointsCreateScheduleModal
    //ReceptionPointsUpdateScheduleModal
</template>
