export default {
  favors: (state) => state.favors,
  total: (state) => state.total,
  currentFavor: (state) => state.currentFavor,
  currentSubFavor: (state) => state.currentSubFavor,
  subFavors: (state) => {
    const id = state.currentFavor?.id
    if (id) return state.favors.find((item) => item.id === id).sub_favors || []
  },
}
