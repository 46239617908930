import { BaseClient } from './BaseClient'
import {
  getUsersRequestStruct,
  getUsersResponseStruct,
  createUserRequestStruct,
  createUserResponseStruct,
  updateUserRequestStruct,
  updateUserResponseStruct,
  getUserTokenResponseStruct,
} from './types/Users'

export class UsersApi extends BaseClient {
  getUsers = this.createRequest(
    {
      url: '/api/admin/users',
      method: 'get',
    },
    getUsersRequestStruct,
    getUsersResponseStruct
  )

  createUser = this.createRequest(
    {
      url: '/api/admin/users',
      method: 'post',
    },
    createUserRequestStruct,
    createUserResponseStruct
  )

  updateUser = (id) =>
    this.createRequest(
      {
        url: `/api/admin/users/${id}`,
        method: 'put',
      },
      updateUserRequestStruct,
      updateUserResponseStruct
    )

  deleteUser = (id) =>
    this.createRequest(
      {
        url: `/api/admin/users/${id}`,
        method: 'delete',
      },
      null,
      null
    )

  getUserToken = (id) =>
    this.createRequest(
      {
        url: `/api/admin/users/${id}/token`,
        method: 'get',
      },
      null,
      getUserTokenResponseStruct
    )
}
