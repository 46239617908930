import { BaseClient } from './BaseClient'
import {
  getGoldStatusesRequestStruct,
  getGoldStatusesResponseStruct,
  createGoldStatusRequestStruct,
  createGoldStatusResponseStruct,
  updateGoldStatusRequestStruct,
  updateGoldStatusResponseStruct,
} from './types/GoldStatuses'

export class GoldStatusesApi extends BaseClient {
  getGoldStatuses = this.createRequest(
    {
      url: '/api/admin/gold',
      method: 'get',
    },
    getGoldStatusesRequestStruct,
    getGoldStatusesResponseStruct
  )

  createGoldStatus = this.createRequest(
    {
      url: '/api/admin/gold',
      method: 'post',
    },
    createGoldStatusRequestStruct,
    createGoldStatusResponseStruct
  )

  updateGoldStatus = (id) =>
    this.createRequest(
      {
        url: `/api/admin/gold/${id}`,
        method: 'put',
      },
      updateGoldStatusRequestStruct,
      updateGoldStatusResponseStruct
    )

  deleteGoldStatus = (id) =>
    this.createRequest(
      {
        url: `/api/admin/gold/${id}`,
        method: 'delete',
      },
      null,
      null
    )
}
