<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NotAdminModal',

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'NotAdminModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'NotAdminModal', open: value })
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_USER)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="red"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Ошибка
        v-spacer
      v-card-actions
        span Вы не являетесь администратором!
</template>
