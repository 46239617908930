import { BaseClient } from './BaseClient'
import {
  sendCodeRequestStruct,
  verifyCodeReqestStruct,
  getUserResponseStruct,
  sendCodeResponseStruct,
} from './types/Auth'

export class AuthApi extends BaseClient {
  sendCode = this.createRequest(
    {
      url: '/api/auth/code',
      method: 'post',
    },
    sendCodeRequestStruct,
    sendCodeResponseStruct
  )

  verifyCode = this.createRequest(
    {
      url: '/api/auth',
      method: 'get',
    },
    verifyCodeReqestStruct,
    null
  )

  getUser = this.createRequest(
    {
      url: '/api/user',
      method: 'get',
    },
    null,
    getUserResponseStruct
  )
}
