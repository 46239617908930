import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.favors = []
    state.total = null
  },

  [types.SET_FAVORS]: (state, favors) => {
    state.favors = favors.items
    state.total = favors.total
  },

  [types.SET_CURRENT_FAVOR]: (state, id) => {
    let favor = state.favors.find((item) => item.id === id)
    state.currentFavor = favor
  },

  [types.SET_CURRENT_SUBFAVOR]: (state, id) => {
    let favor = state.currentFavor

    let subFavor = favor.sub_favors.find((item) => item.id === id)

    state.currentSubFavor = subFavor
  },

  [types.UPDATE_FAVOR]: (state, payload) => {
    let favor = state.favors.find((item) => item.id === payload.id)

    favor.name = payload.name
    favor.description = payload.description
    favor.slug = payload.slug
    favor.sub_favors = payload.sub_favors
  },

  [types.DELETE_FAVOR]: (state, id) => {
    const removeIndex = state.favors.map((item) => item.id).indexOf(id)

    state.favors.splice(removeIndex, 1)

    state.currentFavor = null
  },

  [types.CREATE_FAVOR]: (state, favor) => {
    state.favors.push(favor)
  },

  [types.CREATE_SUBFAVOR]: (state, { subFavor }) => {
    let favor = state.currentFavor

    if (!favor.sub_favors) favor.sub_favors = []

    favor.sub_favors.push(subFavor)
  },

  [types.UPDATE_SUBFAVOR]: (state, payload) => {
    let favor = state.currentFavor

    let subFavor = favor.sub_favors.find((item) => item.id === payload.id)

    subFavor.name = payload.name
    subFavor.slug = payload.slug
    subFavor.description = payload.description
  },

  [types.DELETE_SUBFAVOR]: (state, id) => {
    let favor = state.currentFavor

    const removeIndex = favor.sub_favors.map((item) => item.id).indexOf(id)

    favors.sub_favors.splice(removeIndex, 1)

    state.currentSubFavor = null
  },
}
