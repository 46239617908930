import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.cities = []
    state.regions = []
    state.total = null
  },

  [types.SET_CITIES]: (state, cities) => {
    state.cities = cities.items
    state.regions = cities.regions
    state.total = cities.total
  },

  [types.SET_MORE_CITIES]: (state, cities) => {
    state.cities = [...state.cities, ...cities.items]
  },

  [types.UPDATE_CITY]: (state, payload) => {
    let city = state.cities.find((item) => item.id === payload.id)

    city.name = payload.name
    city.population = payload.population
    city.slug = payload.slug
    city.seo = payload.seo
    city.priority = payload.priority
    city.latitude = payload.latitude
    city.longitude = payload.longitude
    city.postal_code = payload.postal_code
    city.region = payload.region
    city.region_type = payload.region_type
    city.federal_district = payload.federal_district
    city.declination = payload.declination
  },

  [types.DELETE_CITY]: (state, id) => {
    const removeIndex = state.cities.map((item) => item.id).indexOf(id)
    state.cities.splice(removeIndex, 1)
  },

  [types.CREATE_CITY]: (state, city) => {
    state.cities.push(city)
  },

  [types.SET_CURRENT_CITY]: (state, id) => {
    let city = state.cities.find((item) => item.id === id)
    state.currentCity = city
  },
}
