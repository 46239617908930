import types from './types'

export default {
  async sendCode(_, { phone, token }) {
    try {
      const isSend = await this.$authApi.sendCode({
        data: { phone: phone },
        params: { token },
      })

      return isSend
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async verifyCode(_, { phone, code }) {
    try {
      const isVerified = await this.$authApi.verifyCode({
        params: { phone, code },
      })

      return isVerified
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async getUser({ commit }) {
    try {
      const user = await this.$authApi.getUser()

      if (user.is_admin) {
        commit(types.SET_USER, { user })
      }

      return user
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async setupUser({ dispatch }) {
    const token = this.$token.get()

    if (!token) return

    try {
      dispatch('getUser')
    } catch (error) {
      console.error(error)
    }
  },

  async logout({ commit }) {
    this.$token.remove()

    commit(types.CLEAR_USER)
  },
}
