import {
  assign,
  object,
  string,
  array,
  integer,
  nullable,
  optional,
} from 'superstruct'

const favor = object({
  id: optional(integer()),
  name: string(),
  description: optional(nullable(string())),
  slug: string(),
})

export const extendedFavor = assign(
  favor,
  object({
    sub_favors: optional(nullable(array(favor))),
    parent_id: optional(integer()),
  })
)

export const getFavorsResponseStruct = object({
  total: integer(),

  items: array(extendedFavor),
})

export const updateFavorRequestStruct = object({
  data: extendedFavor,
})

export const updateFavorResponseStruct = extendedFavor

export const createFavorRequestStruct = object({
  data: extendedFavor,
})

export const createFavorResponseStruct = extendedFavor
