export const SET_RECEPTION_POINTS = 'SET_RECEPTION_POINTS'
export const SET_CURRENT_RECEPTION_POINT = 'SET_CURRENT_RECEPTION_POINT'
export const CREATE_RECEPTION_POINT = 'CREATE_RECEPTION_POINT'
export const UPDATE_RECEPTION_POINT = 'UPDATE_RECEPTION_POINT'
export const DELETE_RECEPTION_POINT = 'DELETE_RECEPTION_POINT'

export const SET_RECEPTION_POINT_METALS = 'SET_RECEPTION_POINT_METALS'
export const SET_RECEPTION_POINT_FAVORS = 'SET_RECEPTION_POINT_FAVORS'
export const SET_RECEPTION_POINT_SCHEDULE = 'SET_RECEPTION_POINT_SCHEDULE'

export const CLEAR = 'CLEAR'
