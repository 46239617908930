var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap mt-2 mb-4"},[_c('h1',[_vm._v("Услуги")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_favors,"loading":_vm.isLoading,"disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.openUpdateModal},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',[_vm._v("Загрузка")])]},proxy:true},{key:"top",fn:function(){return [_c('FavorsDeleteModal'),_c('FavorsCreateModal'),_c('FavorsUpdateModal'),_c('FavorsSubFavors')]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.description || 'Отсутствует'))])]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.slug))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openSubFavorsModal(item)}}},[_vm._v("Список услуг")])]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }