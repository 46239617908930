<script>
import { UPDATE_USER } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'UsersUpdateModal',

  data: () => ({
    phone: '',
    name: {
      first: '',
      last: '',
      middle: '',
    },
    isUserFormValid: false,
    phoneError: null,
    phoneMask: ['+', ...Array(20).fill(/[0-9]|[-+() ]/)],
    phoneRules: [
      (v) => !!v || 'Заполните номер телефона',
      (v) =>
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
          v
        ) || 'Введите правильный номер телефона',
    ],
  }),

  watch: {
    $_currentUser: function (value) {
      if (!value) return

      this.phone = value.phone
      this.name = value.name || {
        first: '',
        last: '',
        middle: '',
      }
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('users', {
      $_users: 'users',
      $_currentUser: 'currentUser',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'UsersUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'UsersUpdateModal', open: value })

        this.phoneError = null

        this.name = this.$_currentUser.name || {
          first: '',
          last: '',
          middle: '',
        }
        this.phone = this.$_currentUser.phone
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_USER)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('users', {
      $_updateUser: 'updateUser',
    }),

    async updateUser() {
      this.$wait.start(UPDATE_USER)

      this.phoneError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isUserDublicate = this.$_users.some((item) => {
            return (
              item.phone.toUpperCase() === this.phone.toUpperCase() &&
              item.id !== this.$_currentUser.id
            )
          })

          if (isUserDublicate) {
            this.phoneError = 'Пользователь с таким телефоном уже существует.'

            return
          }

          if (!this.isUserFormValid) return

          await this.$_updateUser({
            id: this.$_currentUser.id,
            phone: this.phone,
            name: this.name,
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_USER)
        }
      })
    },

    openDeleteModal() {
      this.$_showModal({ name: 'UsersDeleteModal' })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Изменить пользователя
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isUserFormValid" ref="form")
            v-row
              v-text-field(
                label="Номер телефона" 
                required
                :rules="phoneRules"
                v-model="phone"
                v-mask="phoneMask"
                :error-messages="phoneError"
              )
            v-row
              v-text-field(
                label="Фамилия" 
                required
                v-model="name.last"
                :rules="[(v) => Boolean(name.last) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Имя" 
                required
                v-model="name.first"
                :rules="[(v) => Boolean(name.first) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Отчество (если есть)" 
                v-model="name.middle"
              )
            v-row(justify='end')
              v-col(cols='6')
                v-btn(block color="primary" @click="updateUser" :loading="isLoading") Сохранить
</template>
