<template lang="pug">
  ReceptionPoints
</template>

<script>
import ReceptionPoints from '@/components/ReceptionPoints'

export default {
  name: 'ReceptionPointsView',

  components: {
    ReceptionPoints,
  },
}
</script>
