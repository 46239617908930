<script>
import { GET_METALS } from '@/common/constants/wait'
import { format } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'

import MetalsCreateModal from './MetalsCreateModal'
import MetalsDeleteModal from './MetalsDeleteModal'
import MetalsSubmetals from './MetalsSubmetals'
import MetalsUpdateModal from './MetalsUpdateModal'

export default {
  name: 'Metals',

  components: {
    MetalsUpdateModal,
    MetalsCreateModal,
    MetalsDeleteModal,
    MetalsSubmetals,
  },

  computed: {
    ...mapGetters('metals', {
      $_metals: 'metals',
      $_total: 'total',
    }),

    isLoading() {
      return this.$wait.is(GET_METALS)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
    }),

    ...mapActions('metals', {
      $_getMetals: 'getMetals',
      $_setCurrentMetal: 'setCurrentMetal',
      $_clearMetals: 'clear',
    }),

    async getMetals() {
      await this.$_getMetals()
    },

    openCreateModal() {
      this.$_showModal({ name: 'MetalsCreateModal' })
    },

    openUpdateModal(id) {
      this.$_setCurrentMetal(id)
      this.$_showModal({ name: 'MetalsUpdateModal', open: true })
    },

    openSubmetalsModal(id) {
      this.$_setCurrentMetal(id)
      this.$_showModal({ name: 'MetalsSubmetalsModal', open: true })
    },

    timeFormat(time) {
      if (!time) return 'Не обновлялось'
      return format(new Date(time), 'dd/MM/yyyy HH:mm')
    },

    getSubmetalTotal(submetals) {
      if (!submetals) return 0

      return submetals.length
    },
  },

  mounted() {
    this.getMetals()
  },

  beforeDestroy() {
    this.$_clearMetals()
  },
}
</script>

<template lang="pug">
  div
    MetalsCreateModal
    MetalsUpdateModal
    MetalsDeleteModal
    MetalsSubmetals
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Виды металла
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    .d-flex.flex-wrap.flex-row
      v-card.mr-4.mb-4(
        width="350" 
        v-for="metal, index in $_metals" 
        :key="`${metal.name}-${index}`" 
        align="center"
        @click="openUpdateModal(metal.id)"
      )
        v-card-text
          h2 {{ metal.name }}
        v-card-text Количество подметаллов: {{ getSubmetalTotal(metal.sub_metals) }}
        v-card-text.font-weight-bold Приоритет: {{ metal.priority || 'не установлен' }}
        v-card-text.font-weight-bold Макс. цена: {{ metal.max_price || 'не установлена' }}
        v-card-actions
          v-row(justify='center')
            v-col.px-0(cols="12")
              v-btn(color="primary" text @click.stop="openSubmetalsModal(metal.id)") Подметаллы
</template>
