<script>
import { UPDATE_RECEPTION_POINT_METALS } from '@/common/constants/wait'
import { format, parseISO } from 'date-fns'
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReceptionPointsUpdateMetalsModal',

  data: () => ({
    isDatePickerVisible: false,
    pricesValidityTime: null,
    metals: [],
    isReceptionPointsFormValid: false,
  }),

  watch: {
    $_currentReceptionPoint: function (value) {
      if (!value) return

      const filteredMetals = value.metals?.filter((metal) => metal.sub_metals)

      if (filteredMetals) {
        this.metals = filteredMetals.reduce(
          (prev, curr) => [
            ...prev,
            ...curr.sub_metals.map((_metal) => ({
              id: _metal.id,
              price: _metal.price || null,
              minWeight: _metal.min_weight || null,
              priority: _metal.priority || null,
            })),
          ],
          []
        )

        return
      }

      this.metals = [{ id: null, price: null, minWeight: null, priority: null }]
    },

    metals: {
      handler() {
        if (this.isEveryMetalsFieldsNotEmpty) {
          this.addMetalsField()
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('receptionPoints', {
      $_currentReceptionPoint: 'currentReceptionPoint',
    }),

    ...mapGetters('metals', {
      $_metals: 'metals',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'ReceptionPointsUpdateMetalsModal' })
      },

      set(value) {
        this.$_toggleModal({
          name: 'ReceptionPointsUpdateMetalsModal',
          open: value,
        })

        this.$refs.form.resetValidation()
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_RECEPTION_POINT_METALS)
    },

    isEveryMetalsFieldsNotEmpty() {
      return this.metals.every((item) => {
        return Boolean(item.id)
      })
    },

    metalOptions() {
      const filteredMetals = this.$_metals.filter((metal) => metal.sub_metals)

      const subMetals = filteredMetals.reduce(
        (prev, curr) => [
          ...prev,
          ...curr.sub_metals.map((_metal) => ({
            name: `${curr.name} / ${_metal.name}`,
            slug: _metal.slug,
            id: _metal.id,
          })),
        ],
        []
      )

      return subMetals.filter(
        (metal) =>
          !this.$_metals.some((item) => get(item.metal, 'slug') === metal.slug)
      )
    },

    timeFormat() {
      if (!this.pricesValidityTime) return null
      return format(parseISO(this.pricesValidityTime), 'dd/MM/yyyy')
    },

    minDate() {
      return format(new Date(Date.now() + 3600 * 1000 * 24), 'yyyy-MM-dd')
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_closeModal: 'close',
    }),

    ...mapActions('receptionPoints', {
      $_updateReceptionPoint: 'updateReceptionPoint',
    }),

    addMetalsField() {
      this.metals.push({ id: null, price: null, min_weight: null })
    },

    async updateReceptionPointMetals() {
      this.$wait.start(UPDATE_RECEPTION_POINT_METALS)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isReceptionPointsFormValid) return

          this.$_updateReceptionPointMetals({
            id: this.$_currentReceptionPoint.id,
            metals: this.metals,
            pricesValidityTime: this.pricesValidityTime,
          })

          this.$_closeModal({ name: 'ReceptionPointsUpdateMetalsModal' })

          this.$_closeModal({ name: 'ReceptionPointsCreateMetalsModal' })
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_RECEPTION_POINT_METALS)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="800"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Редактировать металолома
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isReceptionPointsFormValid" ref="form")
            v-row(v-for="(metal, index) in metals" :key="`metal-${index}`")
              v-col.pa-0.pr-2
                v-autocomplete(
                  :items="metalOptions"
                  label="Металлолом"
                  v-model="metal.id"
                  clearable
                  item-text="name"
                  item-value="id"
                  :required="index===0"
                  :rules="[() => Boolean(metal.id) || index !== 0 || 'Поле обязательно']"
                )
              v-col.pa-0.pr-2
                v-text-field(
                  label="Цена (руб.)"
                  type="number"
                  v-model="metal.price"
                )
              v-col.pa-0.pr-2
                  v-text-field(
                  label="Мин. вес (кг.)"
                  type="number"
                  v-model="metal.minWeight"
                )
              v-col.pa-0.pr-2
                  v-text-field(
                  label="Приоритет"
                  type="number"
                  v-model="metal.priority"
                )
            v-row
              v-col
                v-menu(
                  ref="menu"
                  v-model="isDatePickerVisible"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                )
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field(
                      :value="timeFormat"
                      label="Актуальность цен до:"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      required
                      :rules="[(v) => Boolean(v) || 'Выберите дату']"
                      v-on="on"
                    )
                  v-date-picker(
                    v-model="pricesValidityTime"
                    no-title
                    :min="minDate"
                    @input="isDatePickerVisible = false"
                  )
            v-row
              v-col(cols='6')
                v-btn(block color="primary" @click="updateReceptionPointMetals" :loading="isLoading") Сохранить
</template>
