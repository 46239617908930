import * as vMask from './v-mask'
import * as vueHead from './vue-head'
import * as recaptcha from './vue-recaptcha-v3'
import * as vueWait from './vue-wait'
import * as vuetify from './vuetify'

export function setup(app) {
  vueWait.setup(app)
  vueHead.setup(app)
  vuetify.setup(app)
  vMask.setup(app)
  recaptcha.setup(app)
}
