<script>
import { GET_CITIES } from '@/common/constants/wait'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { mapGetters, mapActions } from 'vuex'

import CitiesCreateModal from './CitiesCreateModal'
import CitiesDeleteModal from './CitiesDeleteModal'
import CitiesUpdateModal from './CitiesUpdateModal'

export default {
  name: 'Cities',

  data: () => ({
    page: 1,
    search: {
      page: 1,
      itemsPerPage: 10,
      city: '',
    },

    headers: [
      { value: 'id', text: 'ID', align: 'center' },
      { value: 'name', text: 'Название', align: 'center' },
      { value: 'postal_code', text: 'Почтовый код', align: 'center' },
      { value: 'region', text: 'Регион', align: 'center' },
      { value: 'slug', text: 'Slug', align: 'center' },
      { value: 'federal_district', text: 'Федеральный округ', align: 'center' },
      { value: 'population', text: 'Население', align: 'center' },
      { value: 'declination', text: 'Склонения', align: 'center' },
      { value: 'region_type', text: 'Тип региона', align: 'center' },
      { value: 'seo', text: 'SEO', align: 'center', align: 'center' },
      { value: 'priority', text: 'Приоритет', align: 'center' },
    ],

    regionHeaders: [
      { value: 'id', text: 'ID', align: 'center' },
      { value: 'name', text: 'Название', align: 'center' },
      { value: 'slug', text: 'Slug', align: 'center' },
    ],
  }),

  components: {
    CitiesCreateModal,
    CitiesDeleteModal,
    CitiesUpdateModal,
  },

  computed: {
    ...mapGetters('cities', {
      $_cities: 'cities',
      $_total: 'total',
      $_regions: 'regions',
    }),

    pagesCount() {
      if (!this.$_total) return

      return Math.ceil(this.$_total / this.search.itemsPerPage)
    },

    query() {
      const val = {
        limit: this.search.itemsPerPage,
        offset:
          this.search.page * this.search.itemsPerPage -
          this.search.itemsPerPage,
      }

      if (this.search.city) val.query = this.search.city

      return val
    },

    isLoading() {
      return this.$wait.is(GET_CITIES)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
    }),

    ...mapActions('cities', {
      $_getCities: 'getCities',
      $_setCurrentCity: 'setCurrentCity',
      $_clearCities: 'clear',
    }),

    async fetchCities() {
      try {
        this.$wait.start(GET_CITIES)

        await this.$_getCities(this.query)
      } catch (error) {
        return
      } finally {
        this.$wait.end(GET_CITIES)
      }
    },

    openCreateModal() {
      this.$_showModal({ name: 'CitiesCreateModal' })
    },

    openUpdateModal(value) {
      this.$_setCurrentCity(value.id)
      this.$_showModal({ name: 'CitiesUpdateModal', open: true })
    },

    isObjectEmpty(object) {
      return isEmpty(object)
    },

    onSearch: debounce(function (value) {
      if (!value) this.$router.replace({ query: null })
      else this.$router.replace({ query: { search: value } })

      this.search.page = 1
      this.search.query = value

      this.fetchCities()
    }, 1000),

    onPageChange() {
      this.$router.replace({
        path: 'cities',
        query: {
          ...this.$router.query,
          page: this.search.page === 1 ? undefined : this.search.page,
        },
      })

      this.fetchCities()
    },

    clearFilters() {
      this.search.city = ''
      this.search.page = 1

      this.onPageChange()
    },
  },

  mounted() {
    if (this.$route.query.page)
      this.search.page = Number(this.$route.query.page)
    if (this.$route.query.city)
      this.search.city = Number(this.$route.query.city)

    this.fetchCities()
  },

  beforeDestroy() {
    this.$_clearCities()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Города
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_cities"
      :loading="isLoading"
      :items-per-page="search.itemsPerPage"
      disable-sort
      hide-default-footer
      @click:row="openUpdateModal"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        CitiesDeleteModal
        CitiesCreateModal
        CitiesUpdateModal
        .font-weight-medium.mx-4.pt-4 Городов: {{ $_total }}
        .d-flex.flex-wrap.pt-4
          v-text-field.ml-4.pa-0(
            label="Поиск"
            v-model="search.city"
            @input="onSearch"
            style="max-width: 200px"
          )
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn.ml-4(icon color="primary" @click="clearFilters" v-bind="attrs" v-on="on") 
                v-icon mdi-autorenew
            span Очистить фильтры
      template(#item.region_type="{ item }")
        span {{ item.region_type || 'Не указано' }}
      template(#item.postal_code="{ item }")
        span {{ item.postal_code || 'Не указано' }}
      template(#item.federal_district="{ item }")
        span {{ item.federal_district || 'Не указано' }}
      template(#item.priority="{item}")
        .d-flex.justify-center
          span( v-if="item.priority") {{ item.priority }}
          span(v-else) Нет
      template(#item.declination="{item}")
        .d-flex.justify-center
          v-icon(color="primary" v-if="!isObjectEmpty(item.declination)") mdi-check
          v-icon(color="secondary" v-else) mdi-close
      template(#item.seo="{item}")
        .d-flex.justify-center
          .seo(v-for="seo in item.seo")
            v-icon(color="primary" v-if="seo") mdi-check
            v-icon(color="secondary" v-else) mdi-close
    .text-center.pt-2
      v-pagination.my-6.px-4(
        :disabled="isLoading"
        color="primary"
        v-model='search.page'
        :total-visible="10"
        :length="pagesCount"
        @input="onPageChange"
      )
    .d-flex.align-center.flex-wrap.mt-8.mb-4
      h1 Регионы
    v-data-table.elevation-1.mb-4(
      :headers="regionHeaders"
      :items="$_regions"
      :loading="isLoading"
      :items-per-page="$_regions.length"
      disable-sort
      hide-default-footer
    )
</template>

<style scoped>
.text-truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}
@media (max-width: 1024px) {
  .text-truncate {
    max-width: 200px;
  }
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
