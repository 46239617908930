<script>
import { UPDATE_SUBMETAL, PHOTO_LOADING } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MetalsUpdateModal',

  watch: {
    $_currentSubmetal: function (value) {
      if (!value) return

      this.name = value?.name || ''
      this.description = value?.description || ''
      this.slug = value?.slug || ''
      this.photo = value?.photo || ''
      this.priority = value?.priority || null
    },
  },

  data: () => ({
    name: '',
    description: '',
    slug: '',
    photo: null,
    priority: null,
    isSubmetalsFormValid: false,
    nameError: null,
    slugError: null,
    priorityError: null,
    maxPrice: null,
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('metals', {
      $_currentSubmetal: 'currentSubmetal',
      $_submetals: 'submetals',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'MetalsSubmetalsUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'MetalsSubmetalsUpdateModal', open: value })

        this.name = this.$_currentSubmetal?.name || ''
        this.description = this.$_currentSubmetal?.description || ''
        this.slug = this.$_currentSubmetal?.slug || ''
        this.photo = this.$_currentSubmetal?.photo || ''
        this.priority = this.$_currentSubmetal?.priority || null

        this.nameError = null
        this.slugError = null
        this.priorityError = null

        this.maxPrice = this.$_currentSubmetal?.max_price || null
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_SUBMETAL)
    },

    isPhotoLoading() {
      return this.$wait.is(PHOTO_LOADING)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('metals', {
      $_uploadFile: 'uploadFile',
      $_updateSubmetal: 'updateSubmetal',
    }),

    openDeleteModal() {
      this.$_showModal({ name: 'MetalsSubmetalsDeleteModal' })
    },

    updateMetalPhoto() {
      this.$refs.fileInput.click()
    },

    async uploadFile(files) {
      this.$wait.start(PHOTO_LOADING)

      const file = files[0]

      const formData = new FormData()

      formData.append('file', file, file.name)

      const photo = await this.$_uploadFile(formData)

      this.photo = photo.url

      this.$wait.end(PHOTO_LOADING)
    },

    async updateSubmetal() {
      this.$wait.start(UPDATE_SUBMETAL)

      this.nameError = null
      this.slugError = null
      this.priorityError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_submetals.some((item) => {
            return (
              item.name.toUpperCase() === this.name.toUpperCase() &&
              item.id !== this.$_currentSubmetal.id
            )
          })

          const isSlugDublicate = this.$_submetals.some((item) => {
            return (
              item.slug.toUpperCase() === this.slug.toUpperCase() &&
              item.id !== this.$_currentSubmetal.id
            )
          })

          const isPriorityDublicate = this.$_submetals.some((item) => {
            if (!item.priority) return
            return (
              Number(item.priority) === Number(this.priority) &&
              item.id !== this.$_currentSubmetal.id
            )
          })

          if (isNameDublicate || isSlugDublicate || isPriorityDublicate) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'
            if (isPriorityDublicate) this.priorityError = 'Найден дубликат.'

            return
          }

          if (!this.isSubmetalsFormValid) return

          await this.$_updateSubmetal({
            id: this.$_currentSubmetal.id,
            name: this.name,
            slug: this.slug,
            photo: this.photo || null,
            description: this.description,
            priority: Number(this.priority),
            max_price: Number(this.maxPrice),
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_SUBMETAL)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Изменить вид металлолома
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isSubmetalsFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-textarea(
                label="Описание" 
                required
                v-model="description"
                auto-grow
                rows="1"
                :rules="[() => Boolean(description) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="slug"
                required
                v-model="slug"
                :rules="[() => Boolean(slug) || 'Поле обязательно']"
                :error-messages="slugError"
              )
            v-row
              v-text-field(
                label="Приоритет"
                v-model="priority"
                type="number"
                :error-messages="priorityError"
              )
            v-row
              v-text-field(
                label="Максимальная цена (руб.)"
                v-model="maxPrice"
                type="number"
              )
            v-row
              input(
                type="file"
                ref="fileInput"
                v-show="false"
                accept=".png, .jpg, .jpeg"
                @change="uploadFile($event.target.files)"
              )
              v-card(@click="updateMetalPhoto" width="100%")
                v-card-title Фото
                v-card-actions.pa-0
                  v-row(
                      v-if="!photo"
                      align="center"
                      justify="center"
                    )
                      v-progress-circular.mb-6(
                        v-if="isPhotoLoading && !photo"
                        indeterminate
                        color="primary"
                      )
                  v-img(
                    max-width="100%"
                    v-else
                    :src="photo"
                
                  )
                    template(slot:placeholder v-if="isPhotoLoading")
                      v-row.fill-height.ma-0.background(
                        align="center"
                        justify="center"
                        color="primary"
                      )
                        v-progress-circular(
                          indeterminate
                          color="primary"
                        )
            v-row
              v-col(cols='6')
                v-btn(block color="primary" @click="updateSubmetal" :loading="isLoading") Сохранить
              v-col(cols='6')
                v-btn(
                  color="red"
                  block
                  @click="openDeleteModal"
                  :md="24"
                ) Удалить
</template>

<style scoped>
.background {
  background-color: rgba(255, 255, 255, 0.6);
}
</style>
