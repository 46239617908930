<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  ListItem,
  Link,
  BulletList,
  OrderedList,
  Paragraph,
  HardBreak,
} from 'tiptap-vuetify'

export default {
  name: 'CitiesTextEditorModal',

  components: {
    TiptapVuetify,
  },

  data: () => ({
    test: null,
    extensions: [
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Paragraph,
      HardBreak,
    ],
  }),

  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },

  computed: {
    model: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<template lang="pug">
    tiptap-vuetify.tiptap(v-model="model" :extensions="extensions" :placeholder="placeholder")
</template>

<style scoped>
.tiptap {
  width: 100%;
}
</style>
