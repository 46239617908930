<script>
import { UPDATE_FAQ } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FaqUpdateModal',

  data: () => ({
    title: '',
    description: '',
    isFaqFormValid: false,
    titleError: null,
  }),

  watch: {
    $_currentFaq: function (value) {
      if (!value) return

      this.title = value.title
      this.description = value.description
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('faq', {
      $_faq: 'faq',
      $_currentFaq: 'currentFaq',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'FaqUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'FaqUpdateModal', open: value })

        this.nameError = null

        this.name = this.$_currentFaq.name
        this.description = this.$_currentFaq.description
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_FAQ)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('faq', {
      $_updateFaq: 'updateFaq',
    }),

    async updateFaq() {
      this.$wait.start(UPDATE_FAQ)

      this.titleError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isTitleDublicate = this.$_faq.some((item) => {
            return (
              item.title.toUpperCase() === this.title.toUpperCase() &&
              item.id !== this.$_currentFaq.id
            )
          })

          if (isTitleDublicate) {
            this.titleError = 'Найден дубликат.'

            return
          }

          if (!this.isFaqFormValid) return

          await this.$_updateFaq({
            id: this.$_currentFaq.id,
            title: this.title,
            description: this.description,
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_FAQ)
        }
      })
    },

    openDeleteModal() {
      this.$_showModal({ name: 'FaqDeleteModal' })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Изменить пункт ЧАВО
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isFaqFormValid" ref="form")
            v-row
              v-text-field(
                label="Заголовок" 
                required
                v-model="title"
                :rules="[() => Boolean(title) || 'Поле обязательно']"
                :error-messages="titleError"
              )
            v-row
              v-textarea(
                label="Описание" 
                required
                v-model="description"
                auto-grow
                rows="1"
                :rules="[() => Boolean(description) || 'Поле обязательно']"
              )
            v-row
              v-col(cols='6')
                v-btn(block color="primary" @click="updateFaq" :loading="isLoading") Сохранить
              v-col(cols='6')
                v-btn(
                  color="red"
                  block
                  @click="openDeleteModal"
                  :md="24"
                ) Удалить
</template>
