<script>
import { UPDATE_FAVOR } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FavorsUpdateModal',

  data: () => ({
    name: '',
    description: '',
    slug: '',
    nameError: null,
    slugError: null,
    isFavorsFormValid: false,
  }),

  watch: {
    $_currentFavor: function (value) {
      if (!value) return

      this.name = value.name
      this.slug = value.slug
      this.description = value.description

      this.nameError = null
      this.slugError = null
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('favors', {
      $_currentFavor: 'currentFavor',
      $_favors: 'favors',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'FavorsUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'FavorsUpdateModal', open: value })

        this.nameError = null
        this.slugError = null

        this.name = this.$_currentFavor.name
        this.slug = this.$_currentFavor.slug
        this.description = this.$_currentFavor.description
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_FAVOR)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('favors', {
      $_updateFavor: 'updateFavor',
    }),

    updateFavor() {
      this.$wait.start(UPDATE_FAVOR)

      this.nameError = null
      this.slugError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_favors.some((item) => {
            return (
              item.name.toUpperCase() === this.name.toUpperCase() &&
              item.id !== this.$_currentFavor.id
            )
          })

          const isSlugDublicate = this.$_favors.some((item) => {
            return (
              item.slug.toUpperCase() === this.slug.toUpperCase() &&
              item.id !== this.$_currentFavor.id
            )
          })

          if (isNameDublicate || isSlugDublicate) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'

            return
          }

          if (!this.isFavorsFormValid) return

          await this.$_updateFavor({
            id: this.$_currentFavor.id,
            name: this.name,
            slug: this.slug,
            description: this.description,
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_FAVOR)
        }
      })
    },

    openDeleteModal() {
      this.$_showModal({ name: 'FavorsDeleteModal' })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Изменить услугу
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isFavorsFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-text-field(
                label="Slug" 
                required
                v-model="slug"
                :rules="[() => Boolean(slug) || 'Поле обязательно']"
                :error-messages="slugError"
              )
            v-row
              v-textarea(
                label="Описание (не обязательно)" 
                v-model="description"
                auto-grow
                rows="1"
              )
            v-row
              v-col(cols='6')
                v-btn(block color="primary" @click="updateFavor" :loading="isLoading") Сохранить
              v-col(cols='6')
                v-btn(
                  color="red"
                  block
                  @click="openDeleteModal"
                  :md="24"
                ) Удалить
</template>
