export const SET_METALS = 'SET_METALS'
export const SET_CURRENT_METAL = 'SET_CURRENT_METAL'
export const SET_CURRENT_SUBMETAL = 'SET_CURRENT_SUBMETAL'
export const CREATE_METAL = 'CREATE_METAL'
export const UPDATE_METAL = 'UPDATE_METAL'
export const DELETE_METAL = 'DELETE_METAL'
export const CREATE_SUBMETAL = 'CREATE_SUBMETAL'
export const UPDATE_SUBMETAL = 'UPDATE_SUBMETAL'
export const DELETE_SUBMETAL = 'DELETE_SUBMETAL'
export const CLEAR = 'CLEAR'
