import { BaseClient } from './BaseClient'
import {
  getFavorsResponseStruct,
  updateFavorRequestStruct,
  updateFavorResponseStruct,
  createFavorRequestStruct,
  createFavorResponseStruct,
} from './types/Favors'

export class FavorsApi extends BaseClient {
  createFavor = this.createRequest(
    {
      url: '/api/admin/favors',
      method: 'post',
    },
    createFavorRequestStruct,
    createFavorResponseStruct
  )

  deleteFavor = (id) =>
    this.createRequest(
      {
        url: `/api/admin/favors/${id}`,
        method: 'delete',
      },
      null,
      null
    )

  updateFavor = (id) =>
    this.createRequest(
      {
        url: `/api/admin/favors/${id}`,
        method: 'put',
      },
      updateFavorRequestStruct,
      updateFavorResponseStruct
    )

  getFavors = this.createRequest(
    {
      url: '/api/favors',
      method: 'get',
    },
    null,
    getFavorsResponseStruct
  )
}
