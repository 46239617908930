import {
  object,
  string,
  integer,
  array,
  nullable,
  optional,
  assign,
  number,
  boolean,
} from 'superstruct'

import { city, region } from './Cities'
import { company } from './Companies'
import { extendedFavor } from './Favors'

const contacts = object({
  phone: string(),
  whatsapp: optional(nullable(string())),
  viber: optional(nullable(string())),
  telegram: optional(nullable(string())),
})

const location = object({
  address: string(),
  longitude: number(),
  latitude: number(),
  region: region,
  city: city,
})

const work = nullable(
  object({
    from_time: integer(),
    to_time: integer(),
  })
)

const schedule = array(
  object({
    day: string(),
    dinner: work,
    is_weekend: boolean(),
    work,
  })
)

const ReceptionPoint = object({
  id: optional(integer()),
  name: string(),
  city: optional(nullable(city)),
  city_id: optional(integer()),
  region_id: optional(integer()),
  avatar: optional(nullable(string())),
  location: optional(location),
  contacts: optional(contacts),
  created_at: optional(integer()),
  updated_at: optional(nullable(integer())),
  metals: optional(nullable()),
  prices_validity_time: optional(nullable(integer())),
  step: optional(integer()),
  schedule: optional(nullable(schedule)),
  favors: nullable(extendedFavor),
  slug: optional(string()),
  is_done: boolean(),
  is_verified: boolean(),
  company: optional(company),
})

export const getReceptionPointsRequestStruct = object({
  params: object({
    user_id: optional(integer()),
    company_id: optional(integer()),
    limit: optional(integer()),
    offset: optional(integer()),
  }),
})

export const getReceptionPointsResponseStruct = object({
  total: integer(),
  items: array(ReceptionPoint),
})

export const createReceptionPointRequestStruct = object({
  data: ReceptionPoint,
})

export const createReceptionPointResponseStruct = ReceptionPoint

export const updateReceptionPointRequestStruct = object({
  data: ReceptionPoint,
})

export const updateReceptionPointResponseStruct = ReceptionPoint

export const createReceptionPointFavorsRequestStruct = object({
  data: object({
    favors: array(
      object({
        id: integer(),
        priority: optional(integer()),
      })
    ),
  }),
})

export const createReceptionPointFavorsResponseStruct = object({
  favors: array(
    object({
      id: integer(),
      priority: nullable(integer()),
    })
  ),
})

export const updateReceptionPointFavorsRequestStruct = object({
  data: object({
    favors: array(
      object({
        id: integer(),
        priority: optional(integer()),
      })
    ),
  }),
})

export const updateReceptionPointFavorsResponseStruct = object({
  favors: array(
    object({
      id: integer(),
      priority: nullable(integer()),
    })
  ),
})

export const createReceptionPointScheduleRequestStruct = object({
  data: object({
    schedule: schedule,
  }),
})

export const createReceptionPointScheduleResponseStruct = object({
  schedule: schedule,
})

export const updateReceptionPointScheduleRequestStruct = object({
  data: object({
    schedule: schedule,
  }),
})

export const updateReceptionPointScheduleResponseStruct = object({
  schedule: schedule,
})

export const createReceptionPointMetalsRequestStruct = object({
  data: object({
    prices_validity_time: integer(),
    metals: array(
      object({
        id: integer(),
        priority: optional(integer()),
        price: optional(number()),
        min_weight: optional(integer),
      })
    ),
  }),
})

export const createReceptionPointMetalsResponseStruct = object({
  prices_validity_time: integer(),
  metals: array(
    object({
      id: integer(),
      priority: nullable(integer()),
      price: nullable(number()),
      min_weight: nullable(integer),
    })
  ),
})

export const updateReceptionPointMetalsRequestStruct = object({
  data: object({
    prices_validity_time: integer(),
    metals: array(
      object({
        id: integer(),
        priority: optional(integer()),
        price: optional(number()),
        min_weight: optional(integer()),
      })
    ),
  }),
})

export const updateReceptionPointMetalsResponseStruct = ReceptionPoint
