import Vue from 'vue'

import * as api from './api'
import * as config from './config'
import * as token from './token'

export function setup(app) {
  const inject = (plugin) => {
    Object.assign(app.store, plugin)

    Vue.use({
      install(_Vue) {
        Object.assign(_Vue.prototype, plugin)
      },
    })
  }

  const setupCustomPlugins = (...params) => {
    token.setup(...params)
    api.setup(...params)
    config.setup(...params)
  }

  setupCustomPlugins(app, inject)
}
