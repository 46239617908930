import getEnv from '@/common/utils/getEnv'

export class Config {
  get(name) {
    return getEnv(name)
  }
}

export function setup(_, inject) {
  inject({
    $config: new Config(),
  })
}
