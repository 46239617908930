<script>
import MetalsSubmetalsCreateModal from './MetalsSubmetalsCreateModal'
import MetalsSubmetalsDeleteModal from './MetalsSubmetalsDeleteModal'
import MetalsSubmetalsModal from './MetalsSubmetalsModal'
import MetalsSubmetalsUpdateModal from './MetalsSubmetalsUpdateModal'

export default {
  name: 'Metals',

  components: {
    MetalsSubmetalsModal,
    MetalsSubmetalsCreateModal,
    MetalsSubmetalsUpdateModal,
    MetalsSubmetalsDeleteModal,
  },
}
</script>

<template lang="pug">
  div
    MetalsSubmetalsModal
    MetalsSubmetalsDeleteModal
    MetalsSubmetalsUpdateModal
    MetalsSubmetalsCreateModal
</template>
