import { BaseClient } from './BaseClient'
import {
  getCompaniesRequestStruct,
  getCompaniesResponseStruct,
  createCompanyRequestStruct,
  createCompanyResponseStruct,
  updateCompanyRequestStruct,
  updateCompanyResponseStruct,
} from './types/Companies'

export class CompaniesApi extends BaseClient {
  getCompanies = this.createRequest(
    {
      url: '/api/admin/companies',
      method: 'get',
    },
    getCompaniesRequestStruct,
    getCompaniesResponseStruct
  )

  createCompany = this.createRequest(
    {
      url: '/api/admin/companies',
      method: 'post',
    },
    createCompanyRequestStruct,
    createCompanyResponseStruct
  )

  updateCompany = (id) =>
    this.createRequest(
      {
        url: `/api/admin/companies/${id}`,
        method: 'put',
      },
      updateCompanyRequestStruct,
      updateCompanyResponseStruct
    )

  deleteCompany = (id) =>
    this.createRequest(
      {
        url: `/api/admin/companies/${id}`,
        method: 'delete',
      },
      null,
      null
    )
}
