<template lang="pug">
  Users
</template>

<script>
import Users from '@/components/Users'

export default {
  name: 'UsersView',

  components: {
    Users,
  },
}
</script>
