import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.goldStatuses = []
    state.total = null
  },

  [types.SET_GOLD_STATUSES]: (state, payload) => {
    state.goldStatuses = payload.items
    state.total = payload.total
  },

  [types.SET_CURRENT_GOLD_STATUS]: (state, id) => {
    if (!id) state.currentGoldStatus = null

    let goldStatus = state.goldStatuses.find((item) => item.id === id)
    state.currentGoldStatus = goldStatus
  },

  [types.CREATE_GOLD_STATUS]: (state, payload) => {
    state.goldStatuses.push(payload)
    state.total++
  },

  [types.UPDATE_GOLD_STATUS]: (state, payload) => {
    const removeIndex = state.goldStatuses
      .map((item) => item.id)
      .indexOf(payload.id)

    state.goldStatuses.splice(removeIndex, 1, payload)
  },

  [types.DELETE_GOLD_STATUS]: (state, id) => {
    const removeIndex = state.goldStatuses.map((item) => item.id).indexOf(id)

    state.goldStatuses.splice(removeIndex, 1)

    state.currentGoldStatus = null

    state.total--
  },
}
