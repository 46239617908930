import {
  object,
  string,
  integer,
  array,
  nullable,
  optional,
  assign,
  unknown,
} from 'superstruct'

const metal = object({
  id: optional(integer()),
  name: string(),
  slug: string(),
  photo: optional(nullable(string())),
  description: optional(nullable(string())),
  parent_id: optional(nullable(integer())),
  priority: optional(nullable(integer())),
  declination: optional(nullable(unknown())),
  max_price: optional(nullable(integer())),
})

const extendedMetal = assign(
  metal,
  object({
    sub_metals: nullable(array(metal)),
  })
)

export const getMetalsResponseStruct = object({
  total: integer(),
  items: array(extendedMetal),
})

export const createMetalRequestStruct = object({
  data: metal,
})

export const createMetalResponseStruct = metal

export const updateMetalRequestStruct = object({
  data: metal,
})

export const uploadMetalFileRequestStruct = object({
  data: object({
    file: string(),
  }),
})

export const uploadMetalFileResponseStruct = object({
  url: string(),
})
