<template lang="pug">
  Faq
</template>

<script>
import Faq from '@/components/Faq'

export default {
  name: 'FaqView',

  components: {
    Faq,
  },
}
</script>
