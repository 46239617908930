import { BaseClient } from './BaseClient'
import {
  getFaqResponseStruct,
  updateFaqRequestStruct,
  updateFaqResponseStruct,
  createFaqRequestStruct,
  createFaqResponseStruct,
} from './types/Faq'

export class FaqApi extends BaseClient {
  createFaq = this.createRequest(
    {
      url: '/api/admin/faq',
      method: 'post',
    },
    createFaqRequestStruct,
    createFaqResponseStruct
  )

  deleteFaq = (id) =>
    this.createRequest(
      {
        url: `/api/admin/faq/${id}`,
        method: 'delete',
      },
      null,
      null
    )

  updateFaq = (id) =>
    this.createRequest(
      {
        url: `/api/admin/faq/${id}`,
        method: 'put',
      },
      updateFaqRequestStruct,
      updateFaqResponseStruct
    )

  getFaq = this.createRequest(
    {
      url: '/api/faq',
      method: 'get',
    },
    null,
    getFaqResponseStruct
  )
}
