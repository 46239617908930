<script>
import {
  CREATE_GOLD_STATUS,
  GET_CITIES,
  GET_COMPANIES,
  GET_RECEPTION_POINTS,
} from '@/common/constants/wait'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { mapActions, mapGetters } from 'vuex'

import GoldStatusConfirmModal from './GoldStatusConfirmModal'

export default {
  name: 'GoldStatusFormModal',

  components: {
    GoldStatusConfirmModal,
  },

  data: () => ({
    company: null,
    location: null,
    isGoldStatusFormValid: false,
    searchLocation: null,
    point: null,
    cities: {
      limit: 100,
      offset: 0,
    },
    initialGoldStatusParams: null,
  }),

  watch: {
    $_currentGoldStatus: function (value) {
      if (!value) return

      this.searchLocation = value.location.name

      this.location = value.location.slug
      this.company = value.company.id
      this.point = value.point ? value.point.id : null

      this.onCompanyChange()

      this.getCities()

      const initialGoldStatusParams = {
        location: this.location,
        company: this.company,
        point: this.point,
      }

      this.initialGoldStatusParams = cloneDeep(initialGoldStatusParams)

      this.getCompanies()
    },

    searchLocation: debounce(function () {
      this.getCities()
    }, 500),
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('companies', {
      $_companies: 'companies',
    }),

    ...mapGetters('cities', {
      $_cities: 'cities',
      $_regions: 'regions',
    }),

    ...mapGetters('goldStatuses', {
      $_goldStatuses: 'goldStatuses',
      $_currentGoldStatus: 'currentGoldStatus',
    }),

    ...mapGetters('receptionPoints', {
      $_receptionPoints: 'receptionPoints',
    }),

    locations() {
      return [...this.$_cities, ...this.$_regions]
    },

    dialogVisible: {
      get() {
        const isVisible = this.$_isVisible({ name: 'GoldStatusFormModal' })

        return isVisible
      },

      set(value) {
        this.$_toggleModal({ name: 'GoldStatusFormModal', open: value })

        this.$_setCurrentGoldStatus({ id: null, company: null })
        this.$_clearReceptionPoints()

        this.company = null
        this.region = null
        this.point = null

        this.$refs.form.reset()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_GOLD_STATUS)
    },

    isCitiesLoading() {
      return this.$wait.is(GET_CITIES)
    },

    isCompaniesLoading() {
      return this.$wait.is(GET_COMPANIES)
    },

    isReceptionsLoading() {
      return this.$wait.is(GET_RECEPTION_POINTS)
    },

    isChanged() {
      if (!this.$_currentGoldStatus) return

      const goldStatusParams = {
        company: this.company,
        location: this.location,
        point: this.point,
      }

      return isEqual(goldStatusParams, this.initialGoldStatusParams)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
    }),

    ...mapActions('receptionPoints', {
      $_getReceptionPoints: 'getReceptionPoints',
      $_clearReceptionPoints: 'clear',
    }),

    ...mapActions('goldStatuses', {
      $_createGoldStatus: 'createGoldStatus',
      $_updateGoldStatus: 'updateGoldStatus',
      $_setCurrentGoldStatus: 'setCurrentGoldStatus',
    }),

    ...mapActions('companies', {
      $_getCompanies: 'getCompanies',
      $_clearCompanies: 'clear',
    }),

    ...mapActions('cities', {
      $_getCities: 'getCities',
      $_getMoreCities: 'getMoreCities',
      $_clearCities: 'clear',
    }),

    async getCompanies() {
      this.$wait.start(GET_COMPANIES)

      await this.$_getCompanies({
        limit: undefined,
        offset: undefined,
        location: this.location,
      })

      this.$wait.end(GET_COMPANIES)
    },

    openConfirmModal() {
      this.$_showModal({ name: 'GoldStatusConfirmModal' })
    },

    async onLocationChange() {
      this.$wait.start(GET_COMPANIES)

      this.$_clearReceptionPoints()

      this.$_clearCompanies()

      this.company = null

      await this.$_getCompanies({
        limit: undefined,
        offset: undefined,
        location: this.location,
      })

      this.$refs.form.resetErrorBag()

      this.$wait.end(GET_COMPANIES)
    },

    async onCompanyChange() {
      this.$_clearReceptionPoints()
      this.receptionPoint = null

      if (!this.company) {
        return
      }

      this.$wait.start(GET_RECEPTION_POINTS)

      await this.$_getReceptionPoints({
        limit: undefined,
        offset: undefined,
        company_id: this.company,
      })

      this.$wait.end(GET_RECEPTION_POINTS)
    },

    async onSubmit() {
      this.$wait.start(CREATE_GOLD_STATUS)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isGoldStatusFormValid) return

          const goldStatusParams = {
            company: this.company,
            location: this.location,
            point: this.point,
          }

          if (this.$_currentGoldStatus)
            await this.$_updateGoldStatus({
              id: this.$_currentGoldStatus.id,
              ...goldStatusParams,
            })
          else await this.$_createGoldStatus(goldStatusParams)

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(CREATE_GOLD_STATUS)
        }
      })
    },

    async getCities() {
      this.$wait.start(GET_CITIES)

      await this.$_getCities({
        limit: this.cities.limit,
        offset: this.cities.offset,
        query: this.searchLocation,
      })

      this.$wait.end(GET_CITIES)
    },

    getItemName(item) {
      if (item.name) {
        if (item.region) {
          return `${item.name} - ${item.region}`
        } else {
          return `${item.name}`
        }
      } else {
        return `${item.region} ${item.region_type}`
      }
    },

    onOutsideClick() {
      if (!this.isChanged && this.$_currentGoldStatus) this.openConfirmModal()
      else this.dialogVisible = false
    },

    onCancel() {
      this.dialogVisible = false
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
    eager
    persistent
    @click:outside="onOutsideClick"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title(v-if="$_currentGoldStatus") Редактировать золотой статус
        v-toolbar-title(v-else) Добавить золотой статус
        v-spacer
        GoldStatusConfirmModal(@confirmUpdate="onSubmit" @cancel="onCancel")
      v-card-actions
        v-col
          v-form(v-model="isGoldStatusFormValid" ref="form")
            v-row
              v-autocomplete(
                  :items="locations"
                  clearable
                  label="Локация"
                  v-model="location"
                  item-text="name"
                  item-value="slug"
                  @change="onLocationChange"
                  :loading="isCitiesLoading"
                  :search-input.sync="searchLocation"
                  :disabled="Boolean($_currentGoldStatus)"
                )
                  template(slot="item" slot-scope="data")
                    span {{ getItemName(data.item) }}
            v-row
              v-autocomplete(
                  :disabled="!Boolean(location)"
                  :items="$_companies"
                  clearable
                  label="Компания"
                  required
                  v-model="company"
                  item-text="name"
                  item-value="id"
                  @change="onCompanyChange"
                  :rules="[() => Boolean(company) || 'Поле обязательно']"
                  :loading="isCompaniesLoading"
                )
              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.ml-4(v-bind="attrs" v-on="on") mdi-help-circle-outline
                span Если в списке нет компании которую вы ищите убедитесь в том что в этой местности есть приёмки данной компании 
            v-row.flex-nowrap
              v-autocomplete(
                :disabled="!company && !$_receptionPoints.length"
                :items="$_receptionPoints"
                clearable
                label="Пункт приема"
                v-model="point"
                item-value="id"
                :loading="isReceptionsLoading"
              )
                template(slot="selection" slot-scope="data")
                  span {{ data.item.location.city.name }}, {{ data.item.location.address }}
                template(slot="item" slot-scope="data")
                  span {{ data.item.location.city.name }}, {{ data.item.location.address }}
              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  v-icon.ml-4(v-bind="attrs" v-on="on") mdi-help-circle-outline
                span Вы можете оставить поле незаполненным, по умолчанию золотой статус добавится приемке у которой самый большой балл по выдачи.
            v-row.mt-2(v-if="$_currentGoldStatus" )
              v-col
                v-btn(small v-if="isChanged" block color="secondary" @click="dialogVisible = false" :loading="isLoading") Закрыть
                v-btn(small v-else block color="secondary" @click="openConfirmModal" :loading="isLoading") Закрыть без сохранения
              v-col
                v-btn(small block color="primary" @click="onSubmit" :loading="isLoading" :disabled="isChanged") Сохранить изменения
            v-row.mt-2(v-else)
              v-col(cols='12')
                v-btn(block color="secondary" @click="onSubmit" :loading="isLoading") Добавить
</template>
