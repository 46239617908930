<script>
import { CREATE_FAQ } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FaqCreateModal',

  data: () => ({
    title: '',
    description: '',
    isFaqFormValid: false,
    titleError: null,
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('faq', {
      $_faq: 'faq',
      $_total: 'total',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'FaqCreateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'FaqCreateModal', open: value })

        this.title = ''
        this.description = ''

        this.titleError = null

        this.$refs.form.reset()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_FAQ)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),

    ...mapActions('faq', {
      $_createFaq: 'createFaq',
    }),

    async createFaq() {
      this.$wait.start(CREATE_FAQ)

      this.titleError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isTitleDublicate = this.$_faq.some((item) => {
            return item.title.toUpperCase() === this.title.toUpperCase()
          })

          if (isTitleDublicate) {
            this.titleError = 'Найден дубликат.'

            return
          }

          if (!this.isFaqFormValid) return

          await this.$_createFaq({
            title: this.title,
            description: this.description,
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(CREATE_FAQ)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Добавить пункт ЧАВО
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isFaqFormValid" ref="form")
            v-row
              v-text-field(
                label="Заголовок" 
                required
                v-model="title"
                :rules="[() => Boolean(title) || 'Поле обязательно']"
                :error-messages="titleError"
              )
            v-row
              v-textarea(
                label="Описание" 
                required
                v-model="description"
                auto-grow
                rows="1"
                :rules="[() => Boolean(description) || 'Поле обязательно']"
              )
            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click="createFaq") Добавить
</template>
