import get from 'lodash/get'

import * as types from './types'

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  async getGoldStatuses({ commit }, { limit, offset, location }) {
    try {
      const goldStatuses = await this.$goldStatusesApi.getGoldStatuses({
        params: {
          limit,
          offset,
          location,
        },
      })

      commit(types.SET_GOLD_STATUSES, goldStatuses)
    } catch (error) {
      const errorMessageDescription = get(error, 'error_description', null)

      dispatch(
        'snackbar/toggleSnackbar',
        {
          value: true,
          text: `${errorMessageDescription}`,
        },
        { root: true }
      )

      throw error
    }
  },

  setCurrentGoldStatus({ commit, dispatch }, { id, company }) {
    commit(types.SET_CURRENT_GOLD_STATUS, id)

    if (!company) return

    dispatch('companies/setCompany', { company }, { root: true })
  },

  async createGoldStatus({ commit, dispatch }, { company, location, point }) {
    try {
      const goldStatus = await this.$goldStatusesApi.createGoldStatus({
        data: {
          location,
          company_id: company,
          point_id: point,
        },
      })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Золотой статус создан' },
        { root: true }
      )

      commit(types.CREATE_GOLD_STATUS, goldStatus)
    } catch (error) {
      console.error(error)

      const errorMessageType = get(error, 'error_type', null)

      const errorMessageDescription = get(error, 'error_description', null)

      if (errorMessageType === 'gold_status_already_exists') {
        dispatch(
          'snackbar/toggleSnackbar',
          {
            value: true,
            text: `Золотой статус в этом городе уже существует`,
          },
          { root: true }
        )
      } else {
        dispatch(
          'snackbar/toggleSnackbar',
          {
            value: true,
            text: `${errorMessageType} - ${errorMessageDescription}`,
          },
          { root: true }
        )
      }

      throw error
    }
  },

  async updateGoldStatus(
    { commit, dispatch },
    { id, location, company, point }
  ) {
    try {
      const goldStatus = await this.$goldStatusesApi.updateGoldStatus(id)({
        data: {
          location,
          company_id: company,
          point_id: point,
        },
      })

      commit(types.UPDATE_GOLD_STATUS, goldStatus)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Золотой статус обновлен' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      const errorMessageType = get(error, 'error_type', null)

      const errorMessageDescription = get(error, 'error_description', null)

      if (errorMessageType === 'gold_status_already_exists') {
        dispatch(
          'snackbar/toggleSnackbar',
          {
            value: true,
            text: `Золотой статус в этом городе уже существует`,
          },
          { root: true }
        )
      } else {
        dispatch(
          'snackbar/toggleSnackbar',
          {
            value: true,
            text: `${errorMessageType} - ${errorMessageDescription}`,
          },
          { root: true }
        )
      }

      throw error
    }
  },

  async deleteGoldStatus({ commit, dispatch }, id) {
    try {
      await this.$goldStatusesApi.deleteGoldStatus(id)()

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Золотой статус удален' },
        { root: true }
      )

      commit(types.DELETE_GOLD_STATUS, id)
    } catch (error) {
      console.error(error)

      const errorMessageDescription = get(error, 'error_description', null)

      dispatch(
        'snackbar/toggleSnackbar',
        {
          value: true,
          text: `${errorMessageDescription}`,
        },
        { root: true }
      )
    }
  },
}
