import axios from 'axios'
import get from 'lodash/get'
import qs from 'qs'

const HTTP_STATUSES_TO_CLEAR_TOKEN = [401, 403]

export const createAxiosInstance = ({ getToken, clearToken }) => {
  const axiosInstance = axios.create()

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = getToken()

      if (accessToken) {
        config.headers['X-Token'] = accessToken
      }

      config.paramsSerializer = (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' })

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (
        HTTP_STATUSES_TO_CLEAR_TOKEN.includes(get(error, 'response.status'))
      ) {
        clearToken()
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance
}
