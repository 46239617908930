import { BaseClient } from './BaseClient'
import {
  getReceptionPointsRequestStruct,
  getReceptionPointsResponseStruct,
  createReceptionPointRequestStruct,
  createReceptionPointResponseStruct,
  updateReceptionPointRequestStruct,
  updateReceptionPointResponseStruct,
  createReceptionPointScheduleRequestStruct,
  createReceptionPointScheduleResponseStruct,
  updateReceptionPointScheduleRequestStruct,
  updateReceptionPointScheduleResponseStruct,
  createReceptionPointMetalsRequestStruct,
  createReceptionPointMetalsResponseStruct,
  updateReceptionPointMetalsRequestStruct,
  updateReceptionPointMetalsResponseStruct,
  createReceptionPointFavorsRequestStruct,
  createReceptionPointFavorsResponseStruct,
  updateReceptionPointFavorsRequestStruct,
  updateReceptionPointFavorsResponseStruct,
} from './types/ReceptionPoints'

export class ReceptionPointsApi extends BaseClient {
  getReceptionPoints = this.createRequest(
    {
      url: '/api/admin/points',
      method: 'get',
    },
    getReceptionPointsRequestStruct,
    getReceptionPointsResponseStruct
  )

  createReceptionPoint = this.createRequest(
    {
      url: '/api/admin/points',
      method: 'post',
    },
    createReceptionPointRequestStruct,
    createReceptionPointResponseStruct
  )

  updateReceptionPoint = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}`,
        method: 'patch',
      },
      updateReceptionPointRequestStruct,
      updateReceptionPointResponseStruct
    )

  deleteReceptionPoint = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}`,
        method: 'delete',
      },
      null,
      null
    )

  createReceptionPointSchedule = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}/schedule`,
        method: 'post',
      },
      createReceptionPointScheduleRequestStruct,
      createReceptionPointScheduleResponseStruct
    )

  updateReceptionPointSchedule = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}/schedule`,
        method: 'patch',
      },
      updateReceptionPointScheduleRequestStruct,
      updateReceptionPointScheduleResponseStruct
    )

  createReceptionPointMetals = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}/metals`,
        method: 'post',
      },
      createReceptionPointMetalsRequestStruct,
      createReceptionPointMetalsResponseStruct
    )

  updateReceptionPointMetals = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}/metals`,
        method: 'patch',
      },
      updateReceptionPointMetalsRequestStruct,
      updateReceptionPointMetalsResponseStruct
    )

  createReceptionPointFavors = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}/favors`,
        method: 'post',
      },
      createReceptionPointFavorsRequestStruct,
      createReceptionPointFavorsResponseStruct
    )

  updateReceptionPointFavors = (id) =>
    this.createRequest(
      {
        url: `/api/admin/points/${id}/favors`,
        method: 'patch',
      },
      updateReceptionPointFavorsRequestStruct,
      updateReceptionPointFavorsResponseStruct
    )
}
