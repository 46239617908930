import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.faq = []
    state.total = null
  },

  [types.SET_FAQ]: (state, faq) => {
    state.faq = faq.items
    state.total = faq.total
  },

  [types.UPDATE_FAQ]: (state, payload) => {
    let faq = state.faq.find((item) => item.id === payload.id)

    faq.title = payload.title
    faq.description = payload.description
    faq.updated_at = payload.updated_at
  },

  [types.DELETE_FAQ]: (state, id) => {
    const removeIndex = state.faq.map((item) => item.id).indexOf(id)
    state.faq.splice(removeIndex, 1)
  },

  [types.CREATE_FAQ]: (state, faq) => {
    state.faq.push(faq)
  },

  [types.SET_CURRENT_FAQ]: (state, id) => {
    let faq = state.faq.find((item) => item.id === id)
    state.currentFaq = faq
  },
}
