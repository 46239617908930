var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","color":"white"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',[_c('v-toolbar',{staticClass:"d-flex align-center flex-wrap justify-space-between",attrs:{"color":"primary","flat":"","dark":"","width":"100%"}},[_c('h1',[_vm._v("Услуги")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"secondary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("Закрыть")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_subFavors,"disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.openUpdateModal},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.description || 'Отсутствует'))])]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.slug))])]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }