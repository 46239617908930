import * as types from './types'

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  setCurrentFavor({ commit }, id) {
    commit(types.SET_CURRENT_FAVOR, id)
  },

  setCurrentSubFavor({ commit }, id) {
    commit(types.SET_CURRENT_SUBFAVOR, id)
  },

  async getFavors({ commit }) {
    try {
      const favors = await this.$favorsApi.getFavors()

      commit(types.SET_FAVORS, favors)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async createFavor({ commit, dispatch }, { name, description, slug }) {
    try {
      const favor = await this.$favorsApi.createFavor({
        data: { name, description, slug },
      })

      commit(types.CREATE_FAVOR, favor)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуга создана' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать услугу' },
        { root: true }
      )

      throw error
    }
  },

  async deleteFavor({ commit, dispatch }, id) {
    try {
      await this.$favorsApi.deleteFavor(id)()

      commit(types.DELETE_FAVOR, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуга удалена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить услугу' },
        { root: true }
      )

      throw error
    }
  },

  async updateFavor({ commit, dispatch }, { id, name, description, slug }) {
    try {
      const favor = await this.$favorsApi.updateFavor(id)({
        data: { name, description, slug },
      })

      commit(types.UPDATE_FAVOR, favor)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуга обновлена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить услугу' },
        { root: true }
      )

      throw error
    }
  },

  async createSubFavor(
    { commit, dispatch },
    { name, slug, description, parentId }
  ) {
    try {
      const subFavor = await this.$favorsApi.createFavor({
        data: {
          name,
          slug,
          description,
          parent_id: parentId,
        },
      })

      commit(types.CREATE_SUBFAVOR, { subFavor })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуга создана' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать услугу' },
        { root: true }
      )
    }
  },

  async updateSubFavor({ commit, dispatch }, { id, name, slug, description }) {
    try {
      await this.$favorsApi.deleteFavor(id)({
        data: {
          name,
          slug,
          description,
        },
      })

      commit(types.UPDATE_SUBFAVOR, { id, name, slug, description })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуга обновлена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить услугу' },
        { root: true }
      )
    }
  },

  async deleteSubFavor({ commit, dispatch }, id) {
    try {
      await this.$favorsApi.deleteFavor(id)()

      commit(types.DELETE_SUBFAVOR, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуга удалена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить услугу' },
        { root: true }
      )
    }
  },
}
