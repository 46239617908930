import { assign, object, string, array, integer, nullable } from 'superstruct'

const faq = object({
  id: integer(),
  title: string(),
  description: string(),
  created_at: string(),
  updated_at: nullable(string()),
})

export const getFaqResponseStruct = object({
  total: integer(),

  items: array(faq),
})

export const updateFaqRequestStruct = object({
  data: object({
    title: string(),
    description: string(),
  }),
})

export const updateFaqResponseStruct = faq

export const createFaqRequestStruct = object({
  data: object({
    title: string(),
    description: string(),
  }),
})

export const createFaqResponseStruct = faq
