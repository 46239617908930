<script>
import { CREATE_METAL } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MetalsCreateModal',

  data: () => ({
    name: '',
    slug: '',
    priority: null,
    isMetalsFormValid: false,
    nameError: null,
    slugError: null,
    priorityError: null,
    maxPrice: null,
    declination: {
      V: '',
      D: '',
      P: '',
      R: '',
      T: '',
      multiple: {
        V: '',
        D: '',
        I: '',
        P: '',
        R: '',
        T: '',
      },
    },
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('metals', {
      $_metals: 'metals',
      $_total: 'total',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'MetalsCreateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'MetalsCreateModal', open: value })

        this.name = ''
        this.slug = ''
        this.priority = null
        this.nameError = null
        this.slugError = null
        this.priorityError = null
        this.maxPrice = null
        this.declination = {
          V: '',
          D: '',
          P: '',
          R: '',
          T: '',
          multiple: {
            V: '',
            D: '',
            I: '',
            P: '',
            R: '',
            T: '',
          },
        }

        this.$refs.form.reset()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_METAL)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),
    ...mapActions('metals', {
      $_createMetal: 'createMetal',
    }),

    async createMetal() {
      this.$wait.start(CREATE_METAL)

      this.nameError = null
      this.slugError = null
      this.priorityError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_metals.some((item) => {
            return item.name.toUpperCase() === this.name.toUpperCase()
          })

          const isSlugDublicate = this.$_metals.some((item) => {
            return item.slug.toUpperCase() === this.slug.toUpperCase()
          })

          const isPriorityDublicate = this.$_metals.some((item) => {
            if (!item.priority) return
            return Number(item.priority) === Number(this.priority)
          })

          if (isNameDublicate || isSlugDublicate || isPriorityDublicate) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'
            if (isPriorityDublicate) this.priorityError = 'Найден дубликат.'

            return
          }

          if (!this.isMetalsFormValid) return

          await this.$_createMetal({
            name: this.name,
            slug: this.slug,
            priority: Number(this.priority),
            max_price: Number(this.maxPrice),
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(CREATE_METAL)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Добавить вид металла
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isMetalsFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-text-field(
                label="slug"
                required
                v-model="slug"
                :rules="[() => Boolean(slug) || 'Поле обязательно']"
                :error-messages="slugError"
              )
            v-row
              v-text-field(
                label="Приоритет"
                v-model="priority"
                type="number"
                required
                :error-messages="priorityError"
              )
            v-row
              v-text-field(
                label="Максимальная цена (руб.)"
                v-model="maxPrice"
                type="number"
              )
            v-row
              .font-weight-medium Склонения
            v-row
              v-text-field(
                label="В"
                v-model="declination.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="declination.D"
              )
            v-row
              v-text-field(
                label="П"
                v-model="declination.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="declination.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="declination.T"
              )
            v-row
              .font-weight-medium Множественные Склонения
            v-row
              v-text-field(
                label="В"
                v-model="declination.multiple.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="declination.multiple.D"
              )
            v-row
              v-text-field(
                label="И"
                v-model="declination.multiple.I"
              )
            v-row
              v-text-field(
                label="П"
                v-model="declination.multiple.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="declination.multiple.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="declination.multiple.T"
              )
            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click='createMetal' :loading="isLoading") Добавить
</template>
