<script>
import { CREATE_COMPANY } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'GoldStatusConfirmModal',

  computed: {
    ...mapGetters('goldStatuses', {
      $_goldStatuses: 'goldStatuses',
      $_currentGoldStatus: 'currentGoldStatus',
    }),

    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'GoldStatusConfirmModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'GoldStatusConfirmModal', open: value })
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_COMPANY)
    },
  },
  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_closeModal: 'toggle',
    }),

    ...mapActions('receptionPoints', {
      $_clearReceptionPoints: 'clear',
    }),

    ...mapActions('goldStatuses', {
      $_setCurrentGoldStatus: 'setCurrentGoldStatus',
    }),

    confirmUpdate() {
      this.$emit('confirmUpdate')

      this.dialogVisible = false
    },

    cancel() {
      this.$emit('cancel')

      this.dialogVisible = false
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="secondary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Закрыть без сохранения?
        v-spacer
      v-card-actions.d-flex.flex-column
        v-btn.ma-0.pa-0.mt-2(
          color="secondary"
          block
          @click="dialogVisible = false"
        ) Продолжить редактирование

        v-btn.ma-0.pa-0.mt-2(
          color="red"
          block
          @click="cancel"
          :loading="isLoading"
        ) Закрыть без сохранения

        v-btn.ma-0.pa-0.mt-2(
          color="primary"
          block
          @click="confirmUpdate"
          :loading="isLoading"
        ) Сохранить изменения
</template>
