import * as types from './types'

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  async getUsers({ commit }, { limit, offset, q, phone }) {
    try {
      const users = await this.$usersApi.getUsers({
        params: {
          limit,
          offset,
          q,
          phone,
        },
      })

      commit(types.SET_USERS, users)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  setCurrentUser({ commit }, id) {
    commit(types.SET_CURRENT_USER, id)
  },

  async createUser({ commit, dispatch }, { phone, name }) {
    try {
      const user = await this.$usersApi.createUser({
        data: {
          phone,
          name,
        },
      })

      commit(types.CREATE_USER, user)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Пользователь создан' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать пользователя' },
        { root: true }
      )
    }
  },

  async updateUser({ commit, dispatch }, { id, phone, name }) {
    try {
      const user = await this.$usersApi.updateUser(id)({
        data: {
          name,
          phone,
        },
      })

      commit(types.UPDATE_USER, user)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Пользователь обновлен' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить пользователя' },
        { root: true }
      )
    }
  },

  async deleteUser({ commit, dispatch }, id) {
    try {
      await this.$usersApi.deleteUser(id)()

      commit(types.DELETE_USER, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Пользователь удален' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить пользователя' },
        { root: true }
      )
    }
  },

  async getUserToken({}, id) {
    try {
      const { token } = await this.$usersApi.getUserToken(id)()

      return token
    } catch (error) {
      console.error(error)
    }
  },
}
