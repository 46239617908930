<script>
import { SEND_CODE, VERIFY_CODE } from '@/common/constants/wait'
import { addMinutes, addSeconds } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CodeSendModal',

  data: () => ({
    timer: {
      countDownDate: null,
      interval: null,
      seconds: '',
    },
  }),

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'CodeSendModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'CodeSendModal', open: value })
      },
    },

    isLoading() {
      return this.$wait.waiting(CREATE_USER)
    },
  },

  methods: {
    ...mapActions('auth', {
      $_sendCode: 'sendCode',
      $_verifyCode: 'verifyCode',
      $_getUser: 'getUser',
      $_logout: 'logout',
    }),

    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),

    async sendCode() {
      try {
        this.$wait.start(SEND_CODE)

        await this.$recaptchaLoaded()

        const token = await this.$recaptcha()

        const data = await this.$_sendCode({ phone: this.phone, token })

        if (data) this.isCodeSend = true
      } catch (error) {
        console.error(error)
      } finally {
        this.$wait.end(SEND_CODE)
      }
    },

    resendCode() {
      this.sendCode()
      this.toggleInterval()
    },

    toggleInterval() {
      this.timer.seconds = 60

      this.timer.countDownDate = addMinutes(
        addSeconds(new Date(), 1),
        1
      ).getTime()

      this.timer.interval = setInterval(() => {
        const now = new Date().getTime()

        const distance = this.timer.countDownDate - now

        this.timer.seconds = Math.floor((distance % (1000 * 60)) / 1000)

        if (distance < 0) {
          clearInterval(this.timer.interval)

          this.timer.seconds = null
        }
      }, 1000)
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Новый СМС код отправлен
        v-spacer
      v-card-actions
        v-col.d-flex.justify-center.flex-column.align-center
          v-row
            v-btn.mt-4.border.border_solid(text color="blue" @click="dialogVisible = false") Я получил смс код
          v-row.d-flex.flex-column.align-center.justify-center.mt-8.resend-code
            v-btn.border.resend-code-button(text color="blue" :class="{'border_disabled': timer.seconds}" :disabled="Boolean(timer.seconds)" @click="resendCode") Отправить код ещё раз
            .text.resend-code-text(v-show="timer.seconds") Через {{ timer.seconds }} сек
</template>

<style lang="scss" scoped>
.resend-code {
  position: relative;
  height: 40px;
}

.resend-code-button {
  font-size: 14px !important;
}

.border {
  border-bottom: 2px dotted #62a8e5;
  height: 16px !important;
  padding: 0 !important;
  border-radius: 0 !important;

  &_disabled {
    border-color: #bdbdbd !important;
  }

  &_solid {
    border-bottom-style: solid !important;
  }
}

.resend-code-text {
  top: 14px;
  color: #bdbdbd;
  font-size: 12px !important;
}
</style>
