import * as types from './types'

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  setCompany({ commit }, { company }) {
    commit(types.SET_COMPANY, company)
  },

  async getCompanies(
    { commit },
    { limit, offset, userId, companyId, name, location }
  ) {
    try {
      const companies = await this.$companiesApi.getCompanies({
        params: {
          limit,
          offset,
          user_id: userId || undefined,
          company_id: companyId || undefined,
          name: name || undefined,
          location: location || undefined,
        },
      })

      commit(types.SET_COMPANIES, companies)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  setCurrentCompany({ commit }, id) {
    commit(types.SET_CURRENT_COMPANY, id)
  },

  async createCompany(
    { commit, dispatch },
    { avatar, email, site, name, userId }
  ) {
    try {
      const company = await this.$companiesApi.createCompany({
        data: {
          name,
          user_id: userId,
          avatar,
          email,
          site,
        },
      })

      commit(types.CREATE_COMPANY, company)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Компания создана' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать компанию' },
        { root: true }
      )
    }
  },

  async updateCompany(
    { commit, dispatch },
    { email, site, avatar, id, name, userId }
  ) {
    try {
      const company = await this.$companiesApi.updateCompany(id)({
        data: {
          user_id: userId,
          name,
          email,
          site,
          avatar,
        },
      })

      commit(types.UPDATE_COMPANY, company)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Компания обновлена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить компанию' },
        { root: true }
      )
    }
  },

  async deleteCompany({ commit, dispatch }, id) {
    try {
      await this.$companiesApi.deleteCompany(id)()

      commit(types.DELETE_COMPANY, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Компания удалена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить компанию' },
        { root: true }
      )
    }
  },

  async uploadFile({ dispatch }, file) {
    try {
      const fileUrl = await this.$metalsApi.uploadMetalFile({
        data: file,
      })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Файл загружен' },
        { root: true }
      )

      return fileUrl
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось загрузить файл' },
        { root: true }
      )
    }
  },
}
