<script>
import { CREATE_RECEPTION_POINT_FAVORS } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReceptionPointsCreateFavorsModal',

  data: () => ({
    favors: [{ id: null, priority: null }],
    isReceptionPointsFormValid: false,
  }),

  watch: {
    $_currentReceptionPoint: function (value) {
      this.favors = value.favors || [{ id: null, priority: null }]
    },

    favors: {
      handler() {
        if (this.isFavorsFieldsNotEmpty) {
          this.addFavorsField()
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('receptionPoints', {
      $_currentReceptionPoint: 'currentReceptionPoint',
    }),

    ...mapGetters('favors', {
      $_favors: 'favors',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'ReceptionPointsCreateFavorsModal' })
      },

      set(value) {
        this.$_toggleModal({
          name: 'ReceptionPointsCreateFavorsModal',
          open: value,
        })

        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_RECEPTION_POINT_FAVORS)
    },

    isFavorsFieldsNotEmpty() {
      return this.favors.every((item) => {
        return Boolean(item.id)
      })
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('receptionPoints', {
      $_updateReceptionPointFavors: 'updateReceptionPointFavors',
      $_setCurrentReceptionPoint: 'setCurrentReceptionPoint',
    }),

    async updateReceptionPointFavors() {
      this.$wait.start(CREATE_RECEPTION_POINT_FAVORS)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isReceptionPointsFormValid) return

          const filteredFavors = this.favors
            .filter((item) => item.id && item.priority)
            .map((item) => ({ ...item, priority: Number(item.priority) }))

          this.$_updateReceptionPointFavors({
            id: this.$_currentReceptionPoint.id,
            favors: filteredFavors,
          })

          this.favors = [{ id: null, priority: null }]

          this.dialogVisible = false
        } finally {
          this.$wait.end(CREATE_RECEPTION_POINT_FAVORS)
        }
      })
    },

    addFavorsField() {
      this.favors.push({ id: null, priority: null })
    },

    openUpdate() {
      this.$_showModal({ name: 'ReceptionPointsUpdateFavorsModal', open: true })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Услуги
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isReceptionPointsFormValid" ref="form")
            v-row(v-for="(favor, index) in favors" :key='`favor-${index}`')
              v-col
                v-autocomplete(
                  :items="$_favors"
                  label="Услуги"
                  required
                  clearable
                  v-model="favor.id"
                  item-text="name"
                  item-value="id"
                  :rules="[() => Boolean(favor.id) || index !==0 || 'Поле обязательно']"
                )
              v-col
                v-text-field(
                  label="Приоритет" 
                  type="number"
                  v-model="favor.priority"
                )
            v-row
              v-col
                v-btn(block color="primary" @click="updateReceptionPointFavors" :loading="isLoading") Сохранить
</template>
