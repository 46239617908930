<script>
import {
  GET_RECEPTION_POINTS,
  GET_COMPANIES,
  GET_USERS,
} from '@/common/constants/wait'
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

import ReceptionPointsCreateModal from './ReceptionPointsCreateModal.vue'
import ReceptionPointsDeleteModal from './ReceptionPointsDeleteModal.vue'
import ReceptionPointsFavorsModals from './ReceptionPointsFavorsModals'
import ReceptionPointsMetalsModals from './ReceptionPointsMetalsModals'
import ReceptionPointsScheduleModals from './ReceptionPointsScheduleModals'
import ReceptionPointsUpdateModal from './ReceptionPointsUpdateModal.vue'

const isURLNotValid = (url) => {
  return !/(https?:\/\/(.+?\.)?sdavalych\.ru(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/.test(
    url
  )
}

export default {
  name: 'ReceptionPoints',

  components: {
    ReceptionPointsCreateModal,
    ReceptionPointsDeleteModal,
    ReceptionPointsUpdateModal,
    ReceptionPointsFavorsModals,
    ReceptionPointsMetalsModals,
    ReceptionPointsScheduleModals,
  },

  data: () => ({
    noImageSrc: 'https://via.placeholder.com/300x300',
    search: {
      page: 1,
      itemsPerPage: 10,
      user: null,
      company: null,
      site: null,
      city: null,
    },
    noImageSrc: 'https://via.placeholder.com/300x300',
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'avatar', text: 'Логотип', align: 'center' },
      { value: 'company', text: 'Компания', align: 'center' },
      { value: 'region', text: 'Регион', align: 'center' },
      { value: 'city', text: 'Город', align: 'center' },

      { value: 'contacts', text: 'Контакты', align: 'center' },
      { value: 'created_at', text: 'Создана', align: 'center' },
      { value: 'updated_at', text: 'Обновлена', align: 'center' },
      { value: 'actions', text: 'Действия', align: 'center' },
    ],
    errorImg: [],
    siteRules: [
      (v) =>
        v.length === 0 ||
        /(https?:\/\/(.+?\.)?sdavalych\.ru(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/.test(
          v
        ) ||
        'Введите правильную ссылку на сайт',
    ],
  }),

  computed: {
    ...mapGetters('receptionPoints', {
      $_receptionPoints: 'receptionPoints',
      $_total: 'total',
    }),

    ...mapGetters('companies', {
      $_companies: 'companies',
    }),

    ...mapGetters('cities', {
      $_cities: 'cities',
    }),

    ...mapGetters('users', {
      $_users: 'users',
    }),

    isLoading() {
      return this.$wait.is(GET_RECEPTION_POINTS)
    },

    isUsersLoading() {
      return this.$wait.is(GET_USERS)
    },

    isCompaniesLoading() {
      return this.$wait.is(GET_COMPANIES)
    },

    pagesCount() {
      if (!this.$_total) return

      return Math.ceil(this.$_total / 10)
    },

    query() {
      let query = {
        limit: this.search.itemsPerPage,
        offset:
          this.search.page * this.search.itemsPerPage -
          this.search.itemsPerPage,
      }

      if (this.search.site && !isURLNotValid(this.search.site)) {
        const site = new URL(this.search.site)

        const path = site.pathname.split('/').filter((item) => item)

        const city = path[0]
        const slug = path[1]

        query.city = city || ''
        query.slug = slug || ''
      }

      if (this.search.user) query.user_id = this.search.user
      if (this.search.company) query.company_id = this.search.company
      if (this.search.city) query.city = this.search.city

      return query
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('receptionPoints', {
      $_getReceptionPoints: 'getReceptionPoints',
      $_setCurrentReceptionPoint: 'setCurrentReceptionPoint',
      $_clearReceptionPoints: 'clear',
    }),

    ...mapActions('companies', {
      $_getCompanies: 'getCompanies',
      $_clearCompanies: 'clear',
    }),

    ...mapActions('metals', {
      $_getMetals: 'getMetals',
      $_clearMetals: 'clear',
    }),

    ...mapActions('users', {
      $_getUsers: 'getUsers',
      $_clearUsers: 'clear',
    }),

    ...mapActions('favors', {
      $_getFavors: 'getFavors',
      $_clearFavors: 'clear',
    }),

    openCreateModal() {
      this.$_showModal({ name: 'ReceptionPointsCreateModal' })
    },

    openUpdateModal({ id, company }) {
      this.$_setCurrentReceptionPoint({ id, company })
      this.$_showModal({ name: 'ReceptionPointsUpdateModal', open: true })
    },

    async getCompanies() {
      this.$wait.start(GET_COMPANIES)

      await this.$_getCompanies({ limit: undefined, offset: undefined })

      this.$wait.end(GET_COMPANIES)
    },

    async getUsers() {
      this.$wait.start(GET_USERS)

      await this.$_getUsers({ limit: undefined, offset: undefined })

      this.$wait.end(GET_USERS)
    },

    async fetchReceptionPoints() {
      this.$wait.start(GET_RECEPTION_POINTS)

      this.$_getMetals()

      this.$_getFavors()

      await this.$_getReceptionPoints(this.query)

      this.$wait.end(GET_RECEPTION_POINTS)
    },

    timeFormat(time) {
      if (!time) return 'Не обновлялась'
      return format(new Date(time * 1000), 'dd/MM/yyyy HH:mm')
    },

    onImgError(errorId) {
      this.errorImg.push(errorId)
    },

    onCompanySearch() {
      this.$router.replace({
        path: 'reception-points',
        query: { company_id: this.search.company },
      })

      this.search.page = 1
      this.search.user = null

      this.fetchReceptionPoints()
    },

    onUserSearch() {
      this.$router.replace({
        path: 'reception-points',
        query: { user_id: this.search.user },
      })

      this.search.page = 1
      this.search.company = null

      this.fetchReceptionPoints()
    },

    onSiteSearch() {
      if (isURLNotValid(this.search.site)) return

      this.$router.replace({
        path: 'reception-points',
        query: { site: this.search.site },
      })

      this.search.page = 1
      this.search.user = null
      this.search.company = null

      this.fetchReceptionPoints()
    },

    onPageChange() {
      this.$router.replace({
        path: 'reception-points',
        query: {
          ...this.$router.query,
          page: this.search.page === 1 ? undefined : this.search.page,
        },
      })

      this.fetchReceptionPoints()
    },

    openCompany(item) {
      const location = item.location.city
        ? item.location.city.slug
        : item.location.region.slug

      window.open(`https://sdavalych.ru/${location}/${item.slug}`, '_blank')
    },

    openMetals({ id, company }) {
      this.$_setCurrentReceptionPoint({ id, company })
      this.$_showModal({ name: 'ReceptionPointsCreateMetalsModal', open: true })
    },

    openFavors({ id, company }) {
      this.$_setCurrentReceptionPoint({ id, company })
      this.$_showModal({ name: 'ReceptionPointsCreateFavorsModal', open: true })
    },

    openSchedule({ id, company }) {
      this.$_setCurrentReceptionPoint({ id, company })
      this.$_showModal({
        name: 'ReceptionPointsCreateScheduleModal',
        open: true,
      })
    },

    clearFilters() {
      this.search.user = null
      this.search.site = null
      this.search.company = null
      this.search.city = null
      this.search.page = 1

      this.onPageChange()
    },
  },

  mounted() {
    if (this.$route.query.page)
      this.search.page = Number(this.$route.query.page)
    if (this.$route.query.company_id)
      this.search.company = Number(this.$route.query.company_id)
    if (this.$route.query.user_id)
      this.search.user = Number(this.$route.query.user_id)
    if (this.$route.query.site) this.search.site = this.$route.query.site
    if (this.$route.query.city) this.search.city = this.$route.query.city

    this.getUsers()
    this.getCompanies()

    this.fetchReceptionPoints()
  },

  beforeDestroy() {
    this.$_clearUsers()
    this.$_clearFavors()
    this.$_clearReceptionPoints()
    this.$_clearCompanies()
    this.$_clearMetals()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Приёмки
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_receptionPoints"
      :loading="isLoading"
      disable-sort
      hide-default-footer
      :page.sync="search.page"
      :items-per-page="search.itemsPerPage"
      @click:row="openUpdateModal"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        ReceptionPointsCreateModal
        ReceptionPointsUpdateModal
        ReceptionPointsDeleteModal
        ReceptionPointsScheduleModals
        ReceptionPointsMetalsModals
        ReceptionPointsFavorsModals
        .d-flex.flex-wrap.align-center.pt-4
          v-autocomplete.ml-4.pa-0(
            :items="$_companies"
            clearable
            label="Компания"
            v-model="search.company"
            @input="onCompanySearch"
            item-text="name"
            item-value="id" 
            :loading="isCompaniesLoading"
            style="max-width: 200px"
          )
            template(slot="no-data")
              span.pa-2 Ничего не найдено
          .d-flex.justify-center.align-center.ml-4(style="width: 30px")
            .font-weight-medium или
          v-autocomplete.ml-4.pa-0(
            :items="$_users"
            clearable
            label="Пользователь"
            v-model="search.user"
            @input="onUserSearch"
            item-text="phone"
            item-value="id"
            :loading="isUsersLoading"
            style="max-width: 200px"
          )
            template(slot="no-data")
              span.pa-2 Ничего не найдено
          .d-flex.justify-center.align-center.ml-4(cols="1" style="width: 30px")
            .font-weight-medium или
          v-text-field.ml-4.pa-0(
            label="Ссылка на приёмку" 
            v-model="search.site"
            @input="onSiteSearch"
            :rules="siteRules"
            style="max-width: 250px"
          ) 

          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn.ml-4(icon color="primary" @click="clearFilters" v-bind="attrs" v-on="on") 
                v-icon mdi-autorenew
            span Очистить фильтры
      template(#item.id="{ item }")
        span {{ item.id }}
      template(#item.company="{ item }")
        router-link(:to='`/companies?company=${item.company.id}`').text-truncate.primary--text {{ item.company.name  }}
      template(#item.avatar="{ item }")
        .img-template.d-flex.align-center.justify-center(style="width: 100px;" v-if="!item.avatar || errorImg.some(id => id === item.id)") Нет изображения
        v-img(
          v-if="item.avatar"
          :src="item.avatar"
          max-height="80"
          max-width="100"

          @error="onImgError(item.id)"
        )
      template(#item.created_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.created_at) }}
      template(#item.updated_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.updated_at) }}
      template(#item.city="{item}")
        span {{ item.location.city ? item.location.city.name : 'Не установлен' }}
      template(#item.region="{item}")
        span {{ item.location.region ? item.location.region.name : 'Не установлен' }}
      template(#item.contacts="{item}")
        span {{ item.contacts.phone }}
      template(#item.actions="{ item }")
        v-row
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(small icon color="primary" @click.stop="openMetals(item)" v-bind="attrs" v-on="on") 
                v-icon(small) mdi-pipe-wrench
            span Металлолом
          v-tooltip(top)
            template( v-slot:activator="{ on, attrs }")
              v-btn(small icon color="primary" @click.stop="openSchedule(item)" v-bind="attrs" v-on="on")  
                v-icon(small) mdi-clock-outline
            span Расписание
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(small icon color="primary" @click.stop="openFavors(item)" v-bind="attrs" v-on="on")
                v-icon(small) mdi-format-list-bulleted-square
            span Услуги
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(small color="primary" @click.stop="openCompany(item)" icon v-bind="attrs" v-on="on") 
                v-icon(small) mdi-open-in-new
            span Открыть компанию в новой вкладке
    .text-center.pt-2
      v-pagination.my-6.px-4(
        :disabled="isLoading"
        color="primary"
        v-model='search.page'
        :total-visible="20"
        :length="pagesCount"
        @input="onPageChange"
      )
</template>

<style scoped>
.img-template {
  width: 100%;
  height: 80px;
  background: #81c784;
  font-weight: 700;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
