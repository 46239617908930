import { assert } from 'superstruct'

export const validateData = (dataType, data, message) => {
  if (!dataType) return

  try {
    assert(data, dataType)
  } catch (error) {
    console.error(message)
    console.error(error)
  }
}
