export const SETUP_USER = 'app/setupUser'
export const GET_FAQ = 'app/getFaq'
export const SEND_CODE = 'app/sendCode'
export const VERIFY_CODE = 'app/verifyCode'
export const GET_METALS = 'app/getMetals'

export const CREATE_FAQ = 'app/createFaq'
export const UPDATE_FAQ = 'app/updateFaq'
export const DELETE_FAQ = 'app/deleteFaq'

export const CREATE_SUBMETAL = 'app/createSubmetal'
export const UPDATE_SUBMETAL = 'app/updateSubmetal'
export const DELETE_SUBMETAL = 'app/deleteSubmetal'

export const CREATE_METAL = 'app/createMetal'
export const UPDATE_METAL = 'app/updateMetal'
export const DELETE_METAL = 'app/deleteMetal'

export const PHOTO_LOADING = 'app/photoLoading'

export const GET_CITIES = 'app/getCities'
export const UPDATE_CITY = 'app/updateCities'
export const CREATE_CITY = 'app/createCities'
export const DELETE_CITY = 'app/deleteCities'

export const GET_FAVORS = 'app/getFavors'
export const CREATE_FAVOR = 'app/createFavor'
export const UPDATE_FAVOR = 'app/updateFavor'
export const DELETE_FAVOR = 'app/deleteFavor'

export const CREATE_SUBFAVOR = 'app/createSubFavor'
export const UPDATE_SUBFAVOR = 'app/updateSubFavor'
export const DELETE_SUBFAVOR = 'app/deleteSubFavor'

export const GET_USERS = 'app/getUsers'
export const CREATE_USER = 'app/createUser'
export const UPDATE_USER = 'app/updateUser'
export const DELETE_USER = 'app/deleteUser'

export const GET_COMPANIES = 'app/getCompanies'
export const CREATE_COMPANY = 'app/createCompany'
export const UPDATE_COMPANY = 'app/updateCompany'
export const DELETE_COMPANY = 'app/deleteCompany'

export const GET_RECEPTION_POINTS = 'app/getReceptionPoints'
export const CREATE_RECEPTION_POINT = 'app/createReceptionPoint'
export const UPDATE_RECEPTION_POINT = 'app/updateReceptionPoint'
export const DELETE_RECEPTION_POINT = 'app/deleteReceptionPoint'

export const CREATE_RECEPTION_POINT_FAVORS = 'app/createReceptionPointFavors'
export const UPDATE_RECEPTION_POINT_FAVORS = 'app/updateReceptionPointFavors'

export const CREATE_RECEPTION_POINT_METALS = 'app/createReceptionPointMetals'
export const UPDATE_RECEPTION_POINT_METALS = 'app/updateReceptionPointMetals'

export const CREATE_RECEPTION_POINT_SCHEDULE =
  'app/createReceptionPointSchedule'
export const UPDATE_RECEPTION_POINT_SCHEDULE =
  'app/updateReceptionPointSchedule'

export const GET_GOLD_STATUSES = 'app/getGoldStatuses'
export const CREATE_GOLD_STATUS = 'app/createGoldStatus'
export const UPDATE_GOLD_STATUS = 'app/updateGoldStatus'
export const DELETE_GOLD_STATUS = 'app/deleteGoldStatus'

export const GET_MORE_CITIES = 'app/getMoreCities'
