import {
  object,
  string,
  integer,
  array,
  nullable,
  optional,
  assign,
  boolean,
} from 'superstruct'

const name = object({
  first: string(),
  last: string(),
  middle: optional(nullable(string())),
})

export const user = object({
  id: optional(integer()),
  phone: string(),
  joined_at: optional(integer()),
  name: nullable(name),
  is_admin: boolean(),
})

export const getUsersRequestStruct = object({
  params: object({
    limit: optional(integer()),
    offset: optional(integer()),
    q: optional(string()),
    phone: optional(string()),
  }),
})

export const getUsersResponseStruct = object({
  total: integer(),
  items: array(user),
})

export const createUserRequestStruct = object({
  data: user,
})

export const createUserResponseStruct = user

export const updateUserRequestStruct = object({
  data: user,
})

export const updateUserResponseStruct = user

export const getUserTokenResponseStruct = object({
  token: string(),
})
