<script>
import { UPDATE_METAL } from '@/common/constants/wait'
import isEmpty from 'lodash/isEmpty'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MetalsUpdateModal',

  watch: {
    $_currentMetal: function (value) {
      if (!value) return

      this.name = value.name
      this.slug = value.slug
      this.priority = value.priority
      this.nameError = null
      this.slugError = null
      this.priorityError = null
      this.maxPrice = value.max_price

      if (!isEmpty(this.$_currentMetal.declination)) {
        this.declination = {
          V: this.$_currentMetal.declination.В || '',
          D: this.$_currentMetal.declination.Д || '',
          P: this.$_currentMetal.declination.П || '',
          R: this.$_currentMetal.declination.Р || '',
          T: this.$_currentMetal.declination.Т || '',
          multiple: {
            V: this.$_currentMetal.declination.множественное?.В || '',
            D: this.$_currentMetal.declination.множественное?.Д || '',
            I: this.$_currentMetal.declination.множественное?.И || '',
            P: this.$_currentMetal.declination.множественное?.П || '',
            R: this.$_currentMetal.declination.множественное?.Р || '',
            T: this.$_currentMetal.declination.множественное?.Т || '',
          },
        }
      }
    },
  },

  data: () => ({
    name: '',
    slug: '',
    priority: null,
    isMetalsFormValid: false,
    nameError: null,
    slugError: null,
    priorityError: null,
    maxPrice: null,
    declination: {
      V: '',
      D: '',
      P: '',
      R: '',
      T: '',
      multiple: {
        V: '',
        D: '',
        I: '',
        P: '',
        R: '',
        T: '',
      },
    },
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('metals', {
      $_currentMetal: 'currentMetal',
      $_metals: 'metals',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'MetalsUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'MetalsUpdateModal', open: value })

        this.nameError = null
        this.slugError = null
        this.priorityError = null

        this.$refs.form.resetValidation()

        this.name = this.$_currentMetal.name
        this.slug = this.$_currentMetal.slug
        this.priority = this.$_currentMetal.priority
      },
    },

    isLoading() {
      this.$wait.is(UPDATE_METAL)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('metals', {
      $_updateMetal: 'updateMetal',
    }),

    openDeleteModal() {
      this.$_showModal({ name: 'MetalsDeleteModal' })
    },

    async updateMetal() {
      this.$wait.start(UPDATE_METAL)

      this.nameError = null
      this.slugError = null
      this.priorityError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_metals.some((item) => {
            return (
              item.name.toUpperCase() === this.name.toUpperCase() &&
              item.id !== this.$_currentMetal.id
            )
          })

          const isSlugDublicate = this.$_metals.some((item) => {
            return (
              item.slug.toUpperCase() === this.slug.toUpperCase() &&
              item.id !== this.$_currentMetal.id
            )
          })

          const isPriorityDublicate = this.$_metals.some((item) => {
            if (!item.priority) return
            return (
              Number(item.priority) === Number(this.priority) &&
              item.id !== this.$_currentMetal.id
            )
          })

          if (isNameDublicate || isSlugDublicate || isPriorityDublicate) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'
            if (isPriorityDublicate) this.priorityError = 'Найден дубликат.'

            return
          }

          if (!this.isMetalsFormValid) return

          await this.$_updateMetal({
            id: this.$_currentMetal.id,
            name: this.name,
            slug: this.slug,
            priority: Number(this.priority),
            declination: this.declination,
            max_price: Number(this.maxPrice),
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_METAL)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Изменить вид металла
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isMetalsFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-text-field(
                label="slug"
                required
                v-model="slug"
                :rules="[() => Boolean(slug) || 'Поле обязательно']"
                :error-messages="slugError"
              )
            v-row
              v-text-field(
                label="Приоритет"
                v-model="priority"
                type="number"
                :error-messages="priorityError"
              )
            v-row
              v-text-field(
                label="Максимальная цена (руб.)"
                v-model="maxPrice"
                type="number"
              )
            v-row
              .font-weight-medium Склонения
            v-row
              v-text-field(
                label="В"
                v-model="declination.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="declination.D"
              )
            v-row
              v-text-field(
                label="П"
                v-model="declination.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="declination.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="declination.T"
              )
            v-row
              .font-weight-medium Множественные Склонения
            v-row
              v-text-field(
                label="В"
                v-model="declination.multiple.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="declination.multiple.D"
              )
            v-row
              v-text-field(
                label="И"
                v-model="declination.multiple.I"
              )
            v-row
              v-text-field(
                label="П"
                v-model="declination.multiple.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="declination.multiple.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="declination.multiple.T"
              )
            v-row
              v-col(cols='6')
                v-btn(block color="primary" @click="updateMetal" :loading="isLoading") Сохранить
              v-col(cols='6')
                v-btn(
                  color="red"
                  block
                  @click="openDeleteModal"
                  :md="24"
                ) Удалить
</template>
