<script>
import { DELETE_CITY } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CitiesDeleteModal',

  computed: {
    ...mapGetters('cities', {
      $_currentCity: 'currentCity',
    }),

    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'CitiesDeleteModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'CitiesDeleteModal', open: value })
      },
    },

    isLoading() {
      return this.$wait.is(DELETE_CITY)
    },
  },
  methods: {
    ...mapActions('cities', {
      $_deleteCity: 'deleteCity',
    }),

    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_closeModal: 'toggle',
    }),

    async deleteCity() {
      this.$wait.start(DELETE_CITY)

      await this.$_deleteCity(this.$_currentCity.id)

      this.$_closeModal({ name: 'CitiesDeleteModal' })

      this.$_closeModal({ name: 'CitiesUpdateModal' })

      this.$wait.end(DELETE_CITY)
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="secondary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Удалить город
        v-spacer

      v-card-text.py-4.px-auto.text-center Уверены, что хотите удалить город?
      v-card-actions
        v-col(cols="6")
          v-btn(
            color="secondary"
            block
            @click="dialogVisible = false"
          ) Отмена
        v-col(cols="6")
          v-btn(
            color="red"
            block
            @click="deleteCity"
            :loading="isLoading"
          ) Удалить
</template>
