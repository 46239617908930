<script>
import { UPDATE_RECEPTION_POINT_SCHEDULE } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReceptionPointsCreateModal',

  data: () => ({
    isReceptionPointsFormValid: false,
    schedule: [
      {
        day: { name: 'Понедельник', slug: 'monday' },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
      {
        day: {
          name: 'Вторник',
          slug: 'tuesday',
        },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
      {
        day: {
          name: 'Среда',
          slug: 'wednesday',
        },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
      {
        day: {
          name: 'Четверг',
          slug: 'thursday',
        },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
      {
        day: {
          name: 'Пятница',
          slug: 'friday',
        },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
      {
        day: {
          name: 'Суббота',
          slug: 'saturday',
        },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
      {
        day: {
          name: 'Воскресенье',
          slug: 'sunday',
        },
        isWeekend: false,
        work: { fromTime: null, toTime: null },
        dinner: { fromTime: null, toTime: null },
      },
    ],
  }),

  watch: {
    $_currentReceptionPoint: function (value) {
      if (!value.schedule) return

      this.schedule = this.schedule.map((item) => {
        const _schedule = value.schedule.find(
          (_item) => _item.day === item.day.slug
        )

        return {
          ...item,
          isWeekend: _schedule?.is_weekend || false,
          work: {
            fromTime: _schedule?.work.from_time || null,
            toTime: _schedule?.work.to_time || null,
          },
          dinner: {
            fromTime: _schedule?.dinner.from_time || null,
            toTime: _schedule?.dinner.to_time || null,
          },
        }
      })
    },

    schedule: {
      handler() {
        if (this.isEveryScheduleFieldsNotEmpty) {
          this.addScheduleField()
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('receptionPoints', {
      $_currentReceptionPoint: 'currentReceptionPoint',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'ReceptionPointsUpdateScheduleModal' })
      },

      set(value) {
        this.$_toggleModal({
          name: 'ReceptionPointsUpdateScheduleModal',
          open: value,
        })

        this.$refs.form.resetValidation()
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_RECEPTION_POINT_SCHEDULE)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),

    ...mapActions('receptionPoints', {
      $_updateReceptionPointSchedule: 'updateReceptionPointSchedule',
    }),

    async updateReceptionPointSchedule() {
      this.$wait.start(UPDATE_RECEPTION_POINT_SCHEDULE)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isReceptionPointsFormValid) return

          const receptionPointParams = {
            id: this.$_currentReceptionPoint.id,
            schedule: this.schedule,
          }

          await this.$_updateReceptionPointSchedule(receptionPointParams)

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_RECEPTION_POINT_SCHEDULE)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Редактировать расписание
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isReceptionPointsFormValid" ref="form")
            div(v-for="(item, index) in schedule" :key="`schedule-${index}`")
              v-row.mt-4 {{ item.day.name }}
              v-row
                v-col.pa-0
                  v-checkbox(
                    v-model="item.isWeekend"
                    label="Выходной"
                  )
              v-row
                v-col.pa-0.pr-2
                  v-text-field(
                    label="Время открытия"
                    type="number"
                    v-model="item.work.fromTime"
                    :disabled="item.isWeekend"
                    :required="!item.isWeekend"
                    :rules="[() => Boolean(item.work.fromTime)  || item.isWeekend || 'Поле обязательно']"
                  )
                v-col.pa-0.pr-2
                    v-text-field(
                    label="Время закрытия"
                    type="number"
                    v-model="item.work.toTime"
                    :disabled="item.isWeekend"
                    :required="!item.isWeekend"
                    :rules="[() => Boolean(item.work.toTime) || item.isWeekend || 'Поле обязательно']"
                  )
              v-row
                v-col.pa-0.pr-2
                  v-text-field(
                    label="Обед от"
                    type="number"
                    v-model="item.dinner.fromTime"
                    :disabled="item.isWeekend"
                    :required="!item.isWeekend"
                    :rules="[() => Boolean(item.dinner.fromTime) || item.isWeekend || 'Поле обязательно']"
                  )
                v-col.pa-0.pr-2
                    v-text-field(
                    label="Обед до"
                    type="number"
                    v-model="item.dinner.toTime"
                    :disabled="item.isWeekend"
                    :required="!item.isWeekend"
                    :rules="[() => Boolean(item.dinner.toTime) || item.isWeekend || 'Поле обязательно']"
                  )

            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click="updateReceptionPointSchedule" :loading="isLoading") Сохранить
</template>
