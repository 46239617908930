<script>
import {
  GET_GOLD_STATUSES,
  GET_CITIES,
  GET_MORE_CITIES,
} from '@/common/constants/wait'
import { format } from 'date-fns'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'

import DeleteGoldStatusModal from './DeleteGoldStatusModal.vue'
import GoldStatusFormModal from './GoldStatusFormModal.vue'

export default {
  name: 'GoldStatuses',

  components: {
    GoldStatusFormModal,
    DeleteGoldStatusModal,
  },

  data: () => ({
    search: {
      page: 1,
      itemsPerPage: 10,
      location: '',
      locations: null,
    },

    cities: {
      limit: 100,
      offset: 0,
    },
    searchLocation: '',
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'location', text: 'Локация', align: 'center' },
      { value: 'company', text: 'Компания', align: 'center' },
      { value: 'point', text: 'Пункт приема', align: 'center' },
      { value: 'created_at', text: 'Дата присвоения', align: 'center' },
      { value: 'actions', text: 'Действия', align: 'center' },
    ],
  }),

  watch: {
    searchLocation: debounce(function () {
      this.getLocations()
    }, 500),
  },

  computed: {
    ...mapGetters('goldStatuses', {
      $_goldStatuses: 'goldStatuses',
      $_total: 'total',
    }),

    isLoading() {
      return this.$wait.is(GET_GOLD_STATUSES)
    },

    pagesCount() {
      if (!this.$_total) return

      return Math.ceil(this.$_total / 10)
    },

    query() {
      let query = {
        limit: this.search.itemsPerPage,
        offset:
          this.search.page * this.search.itemsPerPage -
          this.search.itemsPerPage,
        location: this.search.location,
      }

      return query
    },

    isCitiesLoading() {
      return this.$wait.is(GET_CITIES)
    },

    isMoreCitiesloading() {
      return this.$wait.is(GET_MORE_CITIES)
    },

    locations() {
      return [...this.search.locations.items, ...this.search.locations.regions]
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('goldStatuses', {
      $_getGoldStatuses: 'getGoldStatuses',
      $_setCurrentGoldStatus: 'setCurrentGoldStatus',
      $_clearGoldStatuses: 'clear',
    }),

    ...mapActions('cities', {
      $_getFilteredCities: 'getFilteredCities',
      $_getMoreCities: 'getMoreCities',
      $_clearCities: 'clear',
    }),

    openCreateModal() {
      this.$_showModal({ name: 'GoldStatusFormModal' })
    },

    openUpdateModal({ id, company }) {
      this.$_setCurrentGoldStatus({ id, company })
      this.$_showModal({ name: 'GoldStatusFormModal' })
    },

    openDeleteModal({ id, company }) {
      this.$_setCurrentGoldStatus({ id, company })
      this.$_showModal({ name: 'DeleteGoldStatusModal' })
    },

    async fetchGoldStatuses() {
      try {
        this.$wait.start(GET_GOLD_STATUSES)

        await this.$_getGoldStatuses(this.query)
      } catch (error) {
        console.error(error)
      } finally {
        this.$wait.end(GET_GOLD_STATUSES)
      }
    },

    timeFormat(time) {
      if (!time) return 'Не обновлялась'
      return format(new Date(time * 1000), 'dd/MM/yyyy HH:mm')
    },

    onPageChange() {
      this.$router.replace({
        path: 'gold-statuses',
        query: {
          ...this.$router.query,
          page: this.search.page === 1 ? undefined : this.search.page,
        },
      })

      this.fetchGoldStatuses()
    },

    clearFilters() {
      this.search.location = null
      this.search.page = 1

      this.onPageChange()
    },

    openCompany(item) {
      window.open(
        `https://sdavalych.ru/${item.location.city.slug}/${item.slug}`,
        '_blank'
      )
    },

    onLocationSearch() {
      this.$router.replace({
        path: 'gold-statuses',
        query: { location: this.location },
      })

      this.search.page = 1

      this.fetchGoldStatuses()
    },

    async getLocations() {
      this.$wait.start(GET_CITIES)

      const locations = await this.$_getFilteredCities({
        limit: this.cities.limit,
        offset: this.cities.offset,
        query: this.searchLocation,
      })

      this.search.locations = locations

      this.$wait.end(GET_CITIES)
    },

    getItemName(item) {
      if (item.name) {
        if (item.region) {
          return `${item.name} - ${item.region}`
        } else {
          return `${item.name}`
        }
      } else {
        return `${item.region} ${item.region_type}`
      }
    },

    getLink(item) {
      return `https://sdavalych.ru/${item.location.slug}/${item.point.slug}`
    },
  },

  mounted() {
    if (this.$route.query.page)
      this.search.page = Number(this.$route.query.page)

    if (this.$route.query.location)
      this.search.location = this.$route.query.location

    this.getLocations()
    this.fetchGoldStatuses()
  },

  beforeDestroy() {
    this.$_clearGoldStatuses()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Золотые статусы
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_goldStatuses"
      :loading="isLoading"
      disable-sort
      hide-default-footer
      :page.sync="search.page"
      :items-per-page="search.itemsPerPage"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        GoldStatusFormModal
        DeleteGoldStatusModal
        .d-flex.flex-wrap.align-center.pt-4
          v-autocomplete.ml-4.pa-0(
              :items="locations"
              clearable
              label="Локация"
              v-model="search.location"
              item-text="name"
              item-value="slug"
              @change="onLocationSearch"
              :loading="isCitiesLoading || isMoreCitiesloading"
              :search-input.sync="searchLocation"
              style="max-width: 250px"
            )
              template(slot="item" slot-scope="data")
                span {{ getItemName(data.item) }}
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn.ml-4(icon color="primary" @click="clearFilters" v-bind="attrs" v-on="on") 
                v-icon mdi-autorenew
            span Очистить фильтры
      template(#item.company="{ item }")
        router-link(:to='`/companies?company=${item.company.id}`').text-truncate.primary--text {{ item.company.name  }}
      template(#item.location="{item}")
        span {{ item.location.name ? item.location.name : 'Не установлен'}}
        span(v-if="item.location.city") {{ item.location.city.name }} 
      template(#item.point="{item}")
        router-link(v-if="item.point" :to="{path: '/reception-points', query: {site: getLink(item)}}").text-truncate.primary--text 
          span(v-if="item.point.location.city.slug === item.location.slug") {{ item.point.location.city.name }}, 
          span {{ item.point.location.address  }}
        v-tooltip(max-width="250px" v-else top)
          template(v-slot:activator="{ on, attrs }")
            span(v-bind="attrs" v-on="on") Не установлен
          span Золотой статус  автоматически устанавливается приемке с самым большим баллом по выдаче в данной местности.
      template(#item.created_at="{item}")
        span {{ timeFormat(item.created_at) }}
      template(#item.actions="{item}")
        v-btn(@click="openUpdateModal(item)" icon color="primary")
          v-icon mdi-pencil
        v-btn(@click="openDeleteModal(item)" icon color="red")
          v-icon mdi-delete
    .text-center.pt-2(v-if="pagesCount > 1")
      v-pagination.my-6.px-4(
        :disabled="isLoading"
        color="primary"
        v-model='search.page'
        :total-visible="20"
        :length="pagesCount"
        @input="onPageChange"
      )
</template>

<style scoped>
.img-template {
  width: 100%;
  height: 80px;
  background: #81c784;
  font-weight: 700;
}
</style>
