<script>
import { CREATE_SUBMETAL, PHOTO_LOADING } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MetalsSubmetalsCreateModal',

  data: () => ({
    name: '',
    slug: '',
    description: '',
    photo: null,
    isSubmetalsFormValid: false,
    priority: null,
    nameError: null,
    slugError: null,
    priorityError: null,
    maxPrice: null,
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('metals', {
      $_submetals: 'submetals',
      $_currentMetal: 'currentMetal',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'MetalsSubmetalsCreateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'MetalsSubmetalsCreateModal', open: value })

        this.name = ''
        this.slug = ''
        this.description = ''
        this.photo = ''
        this.priority = null

        this.nameError = null
        this.slugError = null
        this.priorityError = null

        this.$refs.form.reset()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_SUBMETAL)
    },

    isPhotoLoading() {
      return this.$wait.is(PHOTO_LOADING)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),

    ...mapActions('metals', {
      $_uploadFile: 'uploadFile',
      $_createSubmetal: 'createSubmetal',
    }),

    async uploadFile(files) {
      this.$wait.start(PHOTO_LOADING)

      const file = files[0]

      const formData = new FormData()

      formData.append('file', file, file.name)

      const photo = await this.$_uploadFile(formData)

      this.photo = photo.url

      this.$wait.end(PHOTO_LOADING)
    },

    uploadPhoto() {
      this.$refs.fileInput.click()
    },

    async createSubmetal() {
      this.$wait.start(CREATE_SUBMETAL)

      this.nameError = null
      this.slugError = null
      this.priorityError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_submetals?.some((item) => {
            return item.name.toUpperCase() === this.name.toUpperCase()
          })

          const isSlugDublicate = this.$_submetals?.some((item) => {
            return item.slug.toUpperCase() === this.slug.toUpperCase()
          })

          const isPriorityDublicate = this.$_submetals?.some((item) => {
            return Number(item.priority) === Number(this.priority)
          })

          if (isNameDublicate || isSlugDublicate || isPriorityDublicate) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'
            if (isPriorityDublicate) this.priorityError = 'Найден дубликат.'

            return
          }

          if (!this.isSubmetalsFormValid) return

          const submetalParams = {
            name: this.name,
            slug: this.slug,
            description: this.description,
            parentId: this.$_currentMetal.id,
            priority: Number(this.priority),
            max_price: Number(this.maxPrice),
          }

          if (this.photo) {
            submetalParams.photo = this.photo
          }

          await this.$_createSubmetal(submetalParams)

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(CREATE_SUBMETAL)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Добавить вид металлолома
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isSubmetalsFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-textarea(
                label="Описание" 
                required
                v-model="description"
                auto-grow
                rows="1"
                :rules="[() => Boolean(description) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="slug"
                required
                v-model="slug"
                :rules="[() => Boolean(slug) || 'Поле обязательно']"
                :error-messages="slugError"
              )
            v-row
              v-text-field(
                label="Приоритет"
                v-model="priority"
                type="number"
                :error-messages="priorityError"
              )
            v-row
              v-text-field(
                label="Максимальная цена (руб.)"
                v-model="maxPrice"
                type="number"
              )
            v-row
              input(
                type="file"
                ref="fileInput"
                v-show="false"
                accept=".png, .jpg, .jpeg"
                @change="uploadFile($event.target.files)"
              )
              v-card(@click="uploadPhoto" width="100%")
                v-card-title Фото
                v-card-actions.pa-0
                  v-row(
                    v-if="!photo"
                    align="center"
                    justify="center"
                  )
                    v-progress-circular.mb-6(
                      v-if="isPhotoLoading && !photo"
                      indeterminate
                      color="primary"
                    )
                  v-img(
                    v-else
                    :aspect-ratio="16/9"
                    :src="photo"
                    contain
                  )
                    template(slot:placeholder v-if="isPhotoLoading")
                      v-row.fill-height.ma-0.background(
                        align="center"
                        justify="center"
                        color="primary"
                        :class="{'mb-10': isPhotoLoading}"
                      )
                        v-progress-circular(
                          indeterminate
                          color="primary"
                        )
            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click="createSubmetal" :loading="isLoading") Добавить
</template>
