<script>
import { SEND_CODE, VERIFY_CODE } from '@/common/constants/wait'
import { addMinutes, addSeconds } from 'date-fns'
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

import Logo from '../../assets/logo.svg'
import NotAdminModal from './AuthNotAdminModal.vue'
import CodeSendModal from './CodeSendModal.vue'

const ERROR_MESSAGE = {
  captcha_not_resolved_error: 'Капча не валидна',
  code_not_found_error: 'Неверный код',
  sms_error: 'Неверный номер',
}

export default {
  name: 'Auth',

  data: () => ({
    isFormValid: false,
    phone: '',
    code: '',
    isCodeSend: false,
    serverError: '',
    phoneMask: ['+', ...Array(20).fill(/[0-9]/)],
    codeMask: '#####',
    phoneRules: [
      (v) => !!v || 'Заполните номер телефона',
      (v) =>
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
          v
        ) || 'Введите правильный номер телефона',
    ],
    codeRules: [(v) => v?.length === 5 || 'Код состоит из 5 цифр'],
    timer: {
      countDownDate: null,
      interval: null,
      seconds: '',
    },
    isChangePhone: false,
  }),

  components: {
    NotAdminModal,
    CodeSendModal,
    Logo,
  },

  computed: {
    isCodeSendLoading() {
      return this.$wait.is(SEND_CODE)
    },

    isVerifyCodeLoading() {
      return this.$wait.is(VERIFY_CODE)
    },
  },

  methods: {
    ...mapActions('auth', {
      $_sendCode: 'sendCode',
      $_verifyCode: 'verifyCode',
      $_getUser: 'getUser',
      $_logout: 'logout',
    }),

    ...mapActions('modal', {
      $_showModal: 'show',
    }),

    async sendCode(e) {
      try {
        this.$wait.start(SEND_CODE)

        await this.$recaptchaLoaded()

        const token = await this.$recaptcha()

        const data = await this.$_sendCode({ phone: this.phone, token })

        if (data) this.isCodeSend = true
      } catch (error) {
        const errorMessageType = get(error, 'error_type', null)

        const errorMessageDescription = get(error, 'error_description', null)

        if (
          errorMessageType === 'sms_error' &&
          errorMessageDescription ===
            'status_code: 201 (Не хватает средств на лицевом счету)'
        ) {
          this.serverError = 'Не хватает средств на лицевом счету'

          return
        }

        const errorMessage = get(ERROR_MESSAGE, errorMessageType, null)

        if (errorMessage) this.serverError = errorMessage
        else this.serverError = 'Неизвестная ошибка'

        console.error(error)
      } finally {
        this.$wait.end(SEND_CODE)
      }
    },

    async verifyCode() {
      try {
        const { token } = await this.$_verifyCode({
          phone: this.phone,
          code: this.code,
        })

        if (token) {
          this.$token.set(token)

          const user = await this.$_getUser()

          if (!user.is_admin) {
            this.$_logout()

            this.$_showModal({ name: 'NotAdminModal' })

            this.phone = ''
            this.code = ''

            this.$refs['form'].reset()

            this.isCodeSend = false
          }
        }
      } catch (error) {
        const errorMessageType = get(error, 'error_type', null)
        const errorMessage = get(ERROR_MESSAGE, errorMessageType, null)

        if (errorMessage) this.serverError = errorMessage
        else this.serverError = 'Неизвестная ошибка'

        console.error(error)
      }
    },

    onSubmit() {
      if (this.isCodeSend) {
        this.verifyCode()
      } else {
        this.toggleInterval()

        this.sendCode()
      }
    },

    resendCode() {
      this.$_showModal({ name: 'CodeSendModal' })

      this.$refs['CodeSendRef'].resendCode()

      this.toggleInterval()
    },

    changePhone() {
      this.isCodeSend = false

      clearInterval(this.timer.interval)
    },

    toggleInterval() {
      this.timer.seconds = 60

      this.timer.countDownDate = addMinutes(
        addSeconds(new Date(), 1),
        1
      ).getTime()

      this.timer.interval = setInterval(() => {
        const now = new Date().getTime()

        const distance = this.timer.countDownDate - now

        this.timer.seconds = Math.floor((distance % (1000 * 60)) / 1000)

        if (distance < 0) {
          clearInterval(this.timer.interval)

          this.timer.seconds = null
        }
      }, 1000)
    },
  },
}
</script>

<template lang="pug">
  v-container.fill-height(fluid)


    NotAdminModal
    CodeSendModal(ref="CodeSendRef" :phone="phone")
    v-row(align='center' justify='center')
      v-col(cols='12' sm='8' md='4')
        .logo.mx-auto
          Logo
          .logo-text Админ-панель
        v-card
          v-toolbar(
            color="primary"
            dark
            flat
          )
            v-toolbar-title Авторизуйтесь
          v-card-actions
            v-col
              v-form(v-model="isFormValid"  @submit.prevent="onSubmit" ref="form")
                v-row.position-parent
                  v-text-field.padding(
                    label="Ваш номер телефона" 
                    required
                    :rules="phoneRules"
                    v-model="phone"
                    v-mask="phoneMask"
                    :error-messages="serverError"
                    :disabled="isCodeSend"
                  )
                  
                  v-btn.position.border(text color="blue" v-show="isCodeSend" @click="changePhone") Изменить номер
                v-row.position-parent(v-show="isCodeSend")
                  v-text-field.padding(
                    label="Код из СМС"
                    required
                    :rules="codeRules"
                    v-model="code"
                    v-mask="codeMask"
                    counter="5"
                    :error-messages="serverError"
                  )
                  .text.resend-code-text(v-show="timer.seconds") Через {{ timer.seconds }} сек
                  v-btn.position.border(@click="resendCode" text color="blue" v-show="isCodeSend" :disabled="Boolean(timer.seconds)" :class="{'border_disabled': timer.seconds}") Отправить код ещё раз
                v-row
                  v-col(cols='12')
                    v-btn(block color="secondary" @click="onSubmit" :loading="isCodeSendLoading || isVerifyCodeLoading") {{isCodeSend ? 'Авторизоваться' : 'Далее'}}
</template>

<style lang="scss" scoped>
.logo {
  position: relative;
  margin-bottom: 12vh !important;
  width: 200px !important;
}

.logo-text {
  position: absolute;
  color: #bababa;
  right: -23px;
  bottom: -14px;
}

.padding {
  label {
    padding-left: 4px;
  }
  input {
    padding-left: 4px;
  }
}
.position-parent {
  position: relative;
}
.position {
  position: absolute !important;
  outline: none !important;
  right: 0;
  top: 28px;
  font-size: 10px !important;
  font-weight: 400 !important;
}
.border {
  border-bottom: 2px dotted #62a8e5;
  height: 16px !important;
  padding: 0 !important;
  border-radius: 0 !important;

  &_disabled {
    border-color: #bdbdbd !important;
  }
}

.resend-code-text {
  position: absolute !important;
  right: 0;
  top: 14px;
  color: #bdbdbd;
  font-size: 10px;
}
</style>
