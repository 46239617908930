<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheHeader',

  methods: {
    ...mapActions('sidebar', {
      $_changeSidebarVisibility: 'changeVisibility',
    }),
  },
}
</script>

<template lang="pug">
  v-app-bar(app clipped-left dark color='primary')
    v-app-bar-nav-icon(@click.stop="$_changeSidebarVisibility")
    v-toolbar-title Sdavalych Admin
</template>
