var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap mt-2 mb-4"},[_c('h1',[_vm._v("Пользователи")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_users,"loading":_vm.isLoading,"disable-sort":"","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.openUpdateModal},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',[_vm._v("Загрузка")])]},proxy:true},{key:"top",fn:function(){return [_c('UsersCreateModal'),_c('UsersUpdateModal'),_c('UsersDeleteModal'),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],staticClass:"ma-4",attrs:{"label":"Номер телефона","rules":_vm.optionalPhoneRules},on:{"input":_vm.onPhoneSearch},model:{value:(_vm.search.phone),callback:function ($$v) {_vm.$set(_vm.search, "phone", $$v)},expression:"search.phone"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("или")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"ma-4",attrs:{"label":"ФИО"},on:{"input":_vm.onNameSearch},model:{value:(_vm.search.q),callback:function ($$v) {_vm.$set(_vm.search, "q", $$v)},expression:"search.q"}})],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name && ((item.name.last) + " " + (item.name.first) + " " + (item.name.middle)) || 'Отсутствует'))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.phone))])]}},{key:"item.joined_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.timeFormat(item.joined_at)))])]}},{key:"item.is_admin",fn:function(ref){
var item = ref.item;
return [(item.is_admin)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openSite(item)}}},[_vm._v("ЛК на сайте")]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":("/companies?user=" + (item.id)),"target":"_blank"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("Компании пользователя")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"my-6",attrs:{"disabled":_vm.isLoading,"color":"primary","length":_vm.pagesCount},on:{"input":_vm.fetchUsers},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }