import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    goldStatuses: [],
    currentGoldStatus: null,
    total: null,
  }),

  mutations,
  getters,
  actions,
}
