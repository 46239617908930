import { AuthApi } from '@/common/services/api/AuthApi'
import { CitiesApi } from '@/common/services/api/CitiesApi'
import { CompaniesApi } from '@/common/services/api/CompaniesApi'
import { FaqApi } from '@/common/services/api/FaqApi'
import { FavorsApi } from '@/common/services/api/FavorsApi'
import { GoldStatusesApi } from '@/common/services/api/GoldStatuses'
import { MetalsApi } from '@/common/services/api/MetalsApi'
import { ReceptionPointsApi } from '@/common/services/api/ReceptionPointsApi'
import { UsersApi } from '@/common/services/api/UsersApi'

import { TokenWrapper } from './token'

export function setup(_, inject) {
  const token = new TokenWrapper()

  const getToken = () => token.get()
  const clearToken = () => token.remove()

  const apiInject = (config) => {
    inject({
      $authApi: new AuthApi(config),
      $faqApi: new FaqApi(config),
      $metalsApi: new MetalsApi(config),
      $citiesApi: new CitiesApi(config),
      $favorsApi: new FavorsApi(config),
      $usersApi: new UsersApi(config),
      $companiesApi: new CompaniesApi(config),
      $receptionPointsApi: new ReceptionPointsApi(config),
      $goldStatusesApi: new GoldStatusesApi(config),
    })
  }

  apiInject({ getToken, clearToken })
}
