import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import cities from './cities'
import companies from './companies'
import faq from './faq'
import favors from './favors'
import goldStatuses from './goldStatuses'
import metals from './metals'
import modal from './modal'
import receptionPoints from './receptionPoints'
import sidebar from './sidebar'
import snackbar from './snackbar'
import users from './users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    sidebar,
    faq,
    modal,
    metals,
    cities,
    favors,
    users,
    companies,
    receptionPoints,
    snackbar,
    goldStatuses,
  },
})
