<script>
export default {
  name: 'TheSidebar',
  data: () => ({
    items: [
      { icon: 'mdi-home', text: 'Главная', path: '/' },
      { icon: 'mdi-frequently-asked-questions', text: 'ЧАВО', path: '/faq' },
      {
        icon: 'mdi-format-list-bulleted-square',
        text: 'Виды металлолома',
        path: '/metals',
      },
      { icon: 'mdi-city', text: 'Города и регионы', path: '/cities' },
      { icon: 'mdi-text-box', text: 'Услуги', path: '/favors' },
      { icon: 'mdi-account-multiple', text: 'Пользователи', path: '/users' },
      { icon: 'mdi-domain', text: 'Компании', path: '/companies' },
      {
        icon: 'mdi-credit-card-marker',
        text: 'Приёмки',
        path: '/reception-points',
      },
      {
        icon: 'mdi-podium-gold',
        text: 'Золотые статусы',
        path: '/gold-statuses',
      },
    ],
  }),
}
</script>

<template lang="pug">
  v-navigation-drawer(v-model='$store.state.sidebar.sidebarVisibility' app clipped)
    v-list(dense)
      v-list-item(
        v-for='(item, index) in items'
        :key="item.text"
        :to='item.path'
      )
        v-list-item-action
          v-icon {{ item.icon }}
        v-list-item-content
          v-list-item-title {{ item.text }}
</template>
