import types from './types'

export default {
  toggle({ commit }, modal) {
    if (modal.open) {
      commit(types.SHOW_MODAL, modal)
    } else {
      commit(types.CLOSE_MODAL, modal)
    }
  },

  show({ commit }, modal) {
    commit(types.SHOW_MODAL, modal)
  },

  close({ commit }, modal) {
    commit(types.CLOSE_MODAL, modal)
  },
}
