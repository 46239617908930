<script>
import ReceptionPointsCreateMetalsModal from './ReceptionPointsCreateMetalsModal.vue'
import ReceptionPointsUpdateMetalsModal from './ReceptionPointsUpdateMetalsModal.vue'

export default {
  name: 'ReceptionPointsMetalsModals',

  components: {
    ReceptionPointsCreateMetalsModal,
    ReceptionPointsUpdateMetalsModal,
  },
}
</script>

<template lang="pug">
  .Metals
    ReceptionPointsCreateMetalsModal
    //ReceptionPointsUpdateMetalsModal
</template>
