import * as types from './types'

const convertTimeToSeconds = (time) => {
  if (!time) return

  const date = new Date('1970-01-01T' + time + ':00' + 'Z')

  return date.getTime() / 1000
}

export default {
  async clear({ commit }) {
    commit(types.CLEAR)
  },

  async getReceptionPoints(
    { commit },
    { limit, offset, user_id, company_id, city, slug }
  ) {
    try {
      const receptionPoints = await this.$receptionPointsApi.getReceptionPoints(
        {
          params: {
            user_id,
            company_id,
            limit,
            offset,
            city,
            slug,
          },
        }
      )

      commit(types.SET_RECEPTION_POINTS, receptionPoints)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  setCurrentReceptionPoint({ commit, dispatch }, { id, company }) {
    commit(types.SET_CURRENT_RECEPTION_POINT, id)

    dispatch('companies/setCompany', { company }, { root: true })
  },

  async createReceptionPoint(
    { commit, dispatch },
    { companyId, name, cityId, location, contacts, avatar, regionId }
  ) {
    try {
      const receptionPoint =
        await this.$receptionPointsApi.createReceptionPoint({
          params: {
            company_id: companyId,
          },
          data: {
            city_id: cityId ?? undefined,
            name,
            location,
            contacts,
            avatar,
            region_id: regionId,
          },
        })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Приемка создана' },
        { root: true }
      )

      commit(types.CREATE_RECEPTION_POINT, receptionPoint)
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать приемку' },
        { root: true }
      )

      throw error
    }
  },

  async updateReceptionPoint(
    { commit, dispatch },
    { id, companyId, name, cityId, location, contacts, avatar, regionId }
  ) {
    try {
      const receptionPoint =
        await this.$receptionPointsApi.updateReceptionPoint(id)({
          data: {
            company_id: companyId,
            city_id: cityId ?? undefined,
            name,
            location,
            contacts,
            avatar,
            region_id: regionId,
          },
        })

      commit(types.UPDATE_RECEPTION_POINT, receptionPoint)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Приемка обновлена' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить приемку' },
        { root: true }
      )

      throw error
    }
  },

  async deleteReceptionPoint({ commit, dispatch }, id) {
    try {
      await this.$receptionPointsApi.deleteReceptionPoint(id)()

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Приемка удалена' },
        { root: true }
      )

      commit(types.DELETE_RECEPTION_POINT, id)
    } catch (error) {
      console.error(error)
    }
  },

  async uploadFile({ dispatch }, file) {
    try {
      const fileUrl = await this.$metalsApi.uploadMetalFile({
        data: file,
      })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Файл загружен' },
        { root: true }
      )

      return fileUrl
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось загрузить файл' },
        { root: true }
      )

      throw error
    }
  },

  async createReceptionPointSchedule({ commit, dispatch }, { id, schedule }) {
    const _schedule = schedule.map((item) => ({
      day: item.day.slug,
      is_weekend: item.isWeekend,
      work: {
        from_time: convertTimeToSeconds(item.work.fromTime),
        to_time: convertTimeToSeconds(item.work.toTime),
      },
      dinner: {
        from_time: convertTimeToSeconds(item.dinner.fromTime) || 0,
        to_time: convertTimeToSeconds(item.dinner.toTime) || 0,
      },
    }))

    try {
      const payload =
        await this.$receptionPointsApi.createReceptionPointSchedule(id)({
          data: {
            schedule: _schedule,
          },
        })

      commit(types.SET_RECEPTION_POINT_SCHEDULE, { id, payload })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Расписание создано' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать расписание' },
        { root: true }
      )

      throw error
    }
  },

  async updateReceptionPointSchedule({ commit, dispatch }, { id, schedule }) {
    const _schedule = schedule.map((item) => {
      const temp = {
        day: item.day.slug,
        is_weekend: item.isWeekend,
      }

      if (item.work.fromTime && item.work.toTime)
        temp.work = {
          from_time: convertTimeToSeconds(item.work.fromTime),
          to_time: convertTimeToSeconds(item.work.toTime),
        }

      if (item.dinner.fromTime && item.toTime)
        temp.dinner = {
          from_time: convertTimeToSeconds(item.dinner.fromTime) || 0,
          to_time: convertTimeToSeconds(item.dinner.toTime) || 0,
        }

      return temp
    })

    try {
      const payload =
        await this.$receptionPointsApi.updateReceptionPointSchedule(id)({
          data: {
            schedule: _schedule,
          },
        })

      commit(types.SET_RECEPTION_POINT_SCHEDULE, { id, payload })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Расписание обновлено' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить расписание' },
        { root: true }
      )

      throw error
    }
  },

  async createReceptionPointMetals(
    { commit, dispatch },
    { pricesValidityTime, id, metals }
  ) {
    const _metals = metals
      .map((item) => ({
        id: item.id,
        price: Number(item.price),
        min_weight: Number(item.minWeight),
        priority: Number(item.priority),
      }))
      .filter((item) => item.id)

    try {
      const payload = await this.$receptionPointsApi.createReceptionPointMetals(
        id
      )({
        data: {
          prices_validity_time: new Date(pricesValidityTime).getTime() / 1000,
          metals: _metals,
        },
      })

      commit(types.SET_RECEPTION_POINT_METALS, { id, payload })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металлы созданы' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать металлы' },
        { root: true }
      )

      throw error
    }
  },

  async updateReceptionPointMetals(
    { commit, dispatch },
    { pricesValidityTime, id, metals }
  ) {
    const _metals = metals
      .map((item) => ({
        id: item.id,
        price: Number(item.price),
        min_weight: Number(item.minWeight),
        priority: Number(item.priority),
      }))
      .filter((item) => item.id)

    try {
      const payload = await this.$receptionPointsApi.updateReceptionPointMetals(
        id
      )({
        data: {
          prices_validity_time: new Date(pricesValidityTime).getTime() / 1000,
          metals: _metals,
        },
      })

      commit(types.SET_RECEPTION_POINT_METALS, { id, payload })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Металлы обновлены' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить металлы' },
        { root: true }
      )

      throw error
    }
  },

  async createReceptionPointFavors({ commit, dispatch }, { id, favors }) {
    try {
      const payload = await this.$receptionPointsApi.createReceptionPointFavors(
        id
      )({
        data: {
          favors,
        },
      })

      commit(types.SET_RECEPTION_POINT_FAVORS, { id, payload })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуги созданы' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать услуги' },
        { root: true }
      )

      throw error
    }
  },

  async updateReceptionPointFavors({ commit, dispatch }, { id, favors }) {
    try {
      const payload = await this.$receptionPointsApi.updateReceptionPointFavors(
        id
      )({
        data: {
          favors,
        },
      })

      commit(types.SET_RECEPTION_POINT_FAVORS, { id, payload })

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Услуги обновлены' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить услуги' },
        { root: true }
      )

      throw error
    }
  },
}
