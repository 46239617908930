<template lang="pug">
  div
    h1 Админка
    v-btn(color="primary" @click="$_logout") Выйти
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeView',

  methods: {
    ...mapActions('auth', {
      $_logout: 'logout',
    }),
  },
}
</script>
