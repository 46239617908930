<script>
import { CREATE_RECEPTION_POINT_SCHEDULE } from '@/common/constants/wait'
import { format, parseISO } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

const convertSecondsToTime = (time) => {
  if (!time) return

  const date = new Date(time * 1000).toISOString().slice(0, -1)

  return format(parseISO(date), 'HH:mm')
}

const scheduleTemplate = [
  {
    day: { name: 'Понедельник', slug: 'monday' },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
  {
    day: {
      name: 'Вторник',
      slug: 'tuesday',
    },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
  {
    day: {
      name: 'Среда',
      slug: 'wednesday',
    },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
  {
    day: {
      name: 'Четверг',
      slug: 'thursday',
    },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
  {
    day: {
      name: 'Пятница',
      slug: 'friday',
    },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
  {
    day: {
      name: 'Суббота',
      slug: 'saturday',
    },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
  {
    day: {
      name: 'Воскресенье',
      slug: 'sunday',
    },
    isWeekend: false,
    work: { fromTime: '', toTime: '' },
    dinner: { fromTime: '', toTime: '' },
  },
]

export default {
  name: 'ReceptionPointsCreateScheduleModal',

  data: () => ({
    isReceptionPointsFormValid: false,
    schedule: [...scheduleTemplate],
  }),

  watch: {
    $_currentReceptionPoint: function (value) {
      if (!value.schedule) return

      this.schedule = this.schedule.map((item) => {
        const _schedule = value.schedule.find(
          (_item) => _item.day === item.day.slug
        )

        return {
          ...item,
          isWeekend: _schedule?.is_weekend || false,
          work: {
            fromTime: convertSecondsToTime(_schedule?.work?.from_time) || '',
            toTime: convertSecondsToTime(_schedule?.work?.to_time) || '',
          },
          dinner: {
            fromTime: convertSecondsToTime(_schedule?.dinner?.from_time) || '',
            toTime: convertSecondsToTime(_schedule?.dinner?.to_time) || '',
          },
        }
      })
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('receptionPoints', {
      $_currentReceptionPoint: 'currentReceptionPoint',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'ReceptionPointsCreateScheduleModal' })
      },

      set(value) {
        this.$_toggleModal({
          name: 'ReceptionPointsCreateScheduleModal',
          open: value,
        })

        this.$refs.form.resetValidation()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_RECEPTION_POINT_SCHEDULE)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('receptionPoints', {
      $_updateReceptionPointSchedule: 'updateReceptionPointSchedule',
    }),

    async updateReceptionPointSchedule() {
      this.$wait.start(CREATE_RECEPTION_POINT_SCHEDULE)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isReceptionPointsFormValid) return

          const receptionPointParams = {
            id: this.$_currentReceptionPoint.id,
            schedule: this.schedule,
          }

          await this.$_updateReceptionPointSchedule(receptionPointParams)

          this.schedule = [...scheduleTemplate]

          this.dialogVisible = false
        } catch (error) {
          console.error(error)
        } finally {
          this.$wait.end(CREATE_RECEPTION_POINT_SCHEDULE)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Добавить расписание
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isReceptionPointsFormValid" ref="form")
            div(v-for="(item, index) in schedule" :key="`schedule-${index}`")
              v-row.mt-4 {{ item.day.name }}
              v-row
                v-col.pa-0
                  v-checkbox(
                    v-model="item.isWeekend"
                    label="Выходной"
                  )
              v-row
                v-col.pa-0.pr-2
                  v-text-field(
                    label="Время открытия"
                    v-model="item.work.fromTime"
                    :disabled="item.isWeekend"
                    :required="!item.isWeekend"
                    v-mask="'##:##'"
                    :rules="[() => Boolean(item.work.fromTime)  || item.isWeekend || 'Поле обязательно', (v) => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || item.isWeekend || 'Введите правильное время']"
                  )
                v-col.pa-0.pr-2
                    v-text-field(
                    label="Время закрытия"
                    v-model="item.work.toTime"
                    :disabled="item.isWeekend"
                    :required="!item.isWeekend"
                    v-mask="'##:##'"
                    :rules="[() => Boolean(item.work.toTime) || item.isWeekend || 'Поле обязательно', (v) => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || item.isWeekend || 'Введите правильное время']"
                  )
              v-row
                v-col.pa-0.pr-2
                  v-text-field(
                    label="Обед от"
                    v-model="item.dinner.fromTime"
                    :disabled="item.isWeekend"
                    v-mask="'##:##'"
                    :rules="[ (v) => item.dinner.fromTime.length === 0 || /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || item.isWeekend || 'Введите правильное время']"
                  )
                v-col.pa-0.pr-2
                    v-text-field(
                    label="Обед до"
                    v-model="item.dinner.toTime"
                    :disabled="item.isWeekend"
                    v-mask="'##:##'"
                    :rules="[ (v) => item.dinner.toTime.length === 0 || /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || item.isWeekend || 'Введите правильное время']"
                  )

            v-row
              v-col(cols='12')
                v-btn(block color="primary" @click="updateReceptionPointSchedule" :loading="isLoading") Сохранить
</template>
