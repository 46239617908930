import { BaseClient } from './BaseClient'
import {
  getMetalsResponseStruct,
  createMetalRequestStruct,
  createMetalResponseStruct,
  updateMetalRequestStruct,
  uploadMetalFileRequestStruct,
  uploadMetalFileResponseStruct,
} from './types/Metals'

export class MetalsApi extends BaseClient {
  getMetals = this.createRequest(
    {
      url: '/api/metals',
      method: 'get',
    },
    null,
    getMetalsResponseStruct
  )

  createMetal = this.createRequest(
    {
      url: '/api/admin/metals',
      method: 'post',
    },
    createMetalRequestStruct,
    createMetalResponseStruct
  )

  updateMetal = (id) =>
    this.createRequest(
      {
        url: `/api/admin/metals/${id}`,
        method: 'put',
      },
      updateMetalRequestStruct,
      null
    )

  deleteMetal = (id) =>
    this.createRequest(
      {
        url: `/api/admin/metals/${id}`,
        method: 'delete',
      },
      null,
      null
    )

  uploadMetalFile = this.createRequest(
    {
      url: '/api/admin/metals/file',
      method: 'post',
    },
    null,
    uploadMetalFileResponseStruct
  )
}
