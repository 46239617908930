<script>
import { GET_FAQ } from '@/common/constants/wait'
import { format } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'

import FaqCreateModal from './FaqCreateModal'
import FaqDeleteModal from './FaqDeleteModal'
import FaqUpdateModal from './FaqUpdateModal'

export default {
  name: 'Faq',

  data: () => ({
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'title', text: 'Заголовок', align: 'center' },
      { value: 'description', text: 'Описание', align: 'center' },
      { value: 'created_at', text: 'Создано', align: 'center' },
      { value: 'updated_at', text: 'Обновлено', align: 'center' },
    ],
  }),

  components: {
    FaqUpdateModal,
    FaqCreateModal,
    FaqDeleteModal,
  },

  computed: {
    ...mapGetters('faq', {
      $_faq: 'faq',
      $_total: 'total',
    }),

    isLoading() {
      return this.$wait.is(GET_FAQ)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
    }),

    ...mapActions('faq', {
      $_getFaq: 'getFaq',
      $_setCurrentFaq: 'setCurrentFaq',
      $_clearFaq: 'clear',
    }),

    async fetchFaq() {
      try {
        this.$wait.start(GET_FAQ)

        await this.$_getFaq()
      } catch (error) {
        return
      } finally {
        this.$wait.end(GET_FAQ)
      }
    },

    openCreateModal() {
      this.$_showModal({ name: 'FaqCreateModal' })
    },

    openUpdateModal({ id }) {
      this.$_setCurrentFaq(id)
      this.$_showModal({ name: 'FaqUpdateModal', open: true })
    },

    timeFormat(time) {
      if (!time) return 'Не обновлялось'
      return format(new Date(time), 'dd/MM/yyyy HH:mm')
    },
  },

  mounted() {
    this.fetchFaq()
  },

  beforeDestroy() {
    this.$_clearFaq()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 ЧаВО
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_faq"
      :loading="isLoading"
      disable-sort
      hide-default-footer
      @click:row="openUpdateModal"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        FaqDeleteModal
        FaqCreateModal
        FaqUpdateModal
      template(#item.id="{ item }")
        span {{ item.id }}
      template(#item.title="{ item }")
        .text-truncate {{ item.title }}
      template(#item.description="{ item }")
        .text-truncate {{ item.description }}
      template(#item.created_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.created_at) }}
      template(#item.updated_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.updated_at) }}
</template>

<style scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
