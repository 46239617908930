<script>
import {
  UPDATE_RECEPTION_POINT,
  PHOTO_LOADING,
  GET_CITIES,
  GET_MORE_CITIES,
} from '@/common/constants/wait'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReceptionPointsCreateModal',

  data: () => ({
    name: '',
    avatar: '',
    location: {
      address: '',
      longitude: '',
      latitude: '',
    },
    contacts: {
      phone: '',
      whatsapp: '',
      viber: '',
      telegram: '',
    },
    company: null,
    city: null,
    region: null,
    isReceptionPointsFormValid: false,
    phoneMask: ['+', ...Array(20).fill(/[0-9]|[-+() ]/)],
    phoneRules: [
      (v) => !!v || 'Заполните номер телефона',
      (v) =>
        /\+*(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
          v
        ) || 'Введите правильный номер телефона',
    ],
    optionalPhoneRules: [
      (v) =>
        !v ||
        /\+*(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
          v
        ) ||
        'Введите правильный номер телефона',
    ],
    cities: {
      limit: 100,
      offset: 0,
    },
    searchLocation: null,
  }),

  watch: {
    searchLocation: debounce(function () {
      this.getCities()
    }, 500),

    $_currentReceptionPoint: function (value) {
      if (!value) return

      const city = value.location.city
      const region = value.location.region

      this.name = value.name || ''
      this.avatar = value.avatar || ''
      this.location = value.location || ''
      this.contacts.phone = value.contacts.phone || ''
      this.contacts.whatsapp = value.contacts.whatsapp || ''
      this.contacts.viber = value.contacts.viber || ''
      this.contacts.telegram = value.contacts.telegram || ''
      this.company = value.company.id || null
      this.city = city ? city.id : null
      this.region = region ? region.id : null
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('companies', {
      $_companies: 'companies',
    }),

    ...mapGetters('cities', {
      $_cities: 'cities',
      $_regions: 'regions',
    }),

    ...mapGetters('receptionPoints', {
      $_currentReceptionPoint: 'currentReceptionPoint',
    }),

    dialogVisible: {
      get() {
        const isVisible = this.$_isVisible({
          name: 'ReceptionPointsUpdateModal',
        })

        return isVisible
      },

      set(value) {
        this.$_toggleModal({ name: 'ReceptionPointsUpdateModal', open: value })

        this.$refs.form.resetValidation()
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_RECEPTION_POINT)
    },

    isPhotoLoading() {
      return this.$wait.is(PHOTO_LOADING)
    },

    isCitiesLoading() {
      return this.$wait.is(GET_CITIES)
    },

    isMoreCitiesloading() {
      return this.$wait.is(GET_MORE_CITIES)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('receptionPoints', {
      $_uploadFile: 'uploadFile',
      $_updateReceptionPoint: 'updateReceptionPoint',
    }),

    ...mapActions('cities', {
      $_getCities: 'getCities',
      $_getMoreCities: 'getMoreCities',
      $_clearCities: 'clear',
    }),

    async uploadFile(files) {
      this.$wait.start(PHOTO_LOADING)

      const file = files[0]

      const formData = new FormData()

      formData.append('file', file, file.name)

      const avatar = await this.$_uploadFile(formData)

      this.avatar = avatar.url

      this.$wait.end(PHOTO_LOADING)
    },

    uploadPhoto() {
      this.$refs.fileInput.click()
    },

    async updateReceptionPoint() {
      this.$wait.start(UPDATE_RECEPTION_POINT)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isReceptionPointsFormValid) return

          const receptionPointParams = {
            id: this.$_currentReceptionPoint.id,
            name: this.name,
            companyId: this.company,
            cityId: this.city,
            contacts: this.contacts,
            location: this.location,
            regionId: this.region,
          }

          if (this.avatar) {
            receptionPointParams.avatar = this.avatar
          }

          await this.$_updateReceptionPoint(receptionPointParams)

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_RECEPTION_POINT)
        }
      })
    },

    async getCities() {
      this.$wait.start(GET_CITIES)

      await this.$_getCities({
        limit: this.cities.limit,
        offset: this.cities.offset,
        query: this.searchLocation,
      })

      this.$wait.end(GET_CITIES)
    },

    async onCitiesEndIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.cities.offset = this.$_cities.length

        this.$wait.start(GET_MORE_CITIES)

        await this.$_getMoreCities({
          limit: this.cities.limit,
          offset: this.cities.offset,
        })

        this.$wait.end(GET_MORE_CITIES)
      }
    },

    openDeleteModal() {
      this.$_showModal({ name: 'ReceptionPointsDeleteModal' })
    },

    getItemName(item) {
      if (item.name) {
        if (item.region) {
          return `${item.name} - ${item.region}`
        } else {
          return `${item.name}`
        }
      } else {
        return `${item.region} ${item.region_type}`
      }
    },
  },

  mounted() {
    this.getCities()
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Редактировать приёмку
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isReceptionPointsFormValid" ref="form")
            v-row
              v-autocomplete(
                  :items="$_companies"
                  label="Компания"
                  required
                  clearable
                  v-model="company"
                  item-text="name"
                  item-value="id"
                  :rules="[() => Boolean(company) || 'Поле обязательно']"
                )
            v-row
              v-autocomplete(
                  :items="$_regions"
                  label="Регион"
                  required
                  clearable
                  v-model="region"
                  item-text="name"
                  item-value="id"
                  :rules="[() => Boolean(region) || 'Поле обязательно']"
                  :loading="isCitiesLoading"
                )
            v-row
              v-autocomplete(
                  :items="$_cities"
                  label="Город (не обязательно)"
                  v-model="city"
                  clearable
                  item-text="name"
                  item-value="id"
                  :loading="isCitiesLoading || isMoreCitiesloading"
                  :search-input.sync="searchLocation"
                )
                  template(slot="item" slot-scope="data")
                    span {{ getItemName(data.item) }}
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
              )
            
            v-row
              .font-weight-medium Местонахождение
            v-row
              v-text-field(
                label="Адрес" 
                v-model="location.address"
                :rules="[() => Boolean(location.address) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Широта"
                type="number"
                v-model="location.latitude"
                :rules="[() => Boolean(location.latitude) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Долгота"
                type="number"
                v-model="location.longitude"
                :rules="[() => Boolean(location.longitude) || 'Поле обязательно']"
              )

            v-row.mt-2
              .font-weight-medium Контакты
            v-row
              v-text-field(
                label="Номер телефона" 
                v-model="contacts.phone"
                v-mask="phoneMask"
                :rules="phoneRules"
              )
            v-row
              v-text-field(
                label="Whatsapp (не обязательно)"
                v-model="contacts.whatsapp"
                v-mask="phoneMask"
                :rules="optionalPhoneRules"
              )
            v-row
              v-text-field(
                label="Viber  (не обязательно)"
                v-model="contacts.viber"
                v-mask="phoneMask"
                :rules="optionalPhoneRules"
              )
            v-row
              v-text-field(
                label="Telegram  (не обязательно)"
                v-model="contacts.telegram"
              )
            v-row
              input(
                type="file"
                ref="fileInput"
                v-show="false"
                accept=".png, .jpg, .jpeg"
                @change="uploadFile($event.target.files)"
              )
              v-card(@click="uploadPhoto" width="100%")
                v-card-title Фото
                v-card-actions.pa-0
                  v-row(
                    v-if="!avatar"
                    align="center"
                    justify="center"
                  )
                    v-progress-circular.mb-6(
                      v-if="isPhotoLoading && !avatar"
                      indeterminate
                      color="primary"
                    )
                  v-img(
                    v-else
                    :aspect-ratio="16/9"
                    :src="avatar"
                    contain
                  )
                    template(slot:placeholder v-if="isPhotoLoading")
                      v-row.fill-height.ma-0.background(
                        align="center"
                        justify="center"
                        color="primary"
                        :class="{'mb-10': isPhotoLoading}"
                      )
                        v-progress-circular(
                          indeterminate
                          color="primary"
                        )
            v-row
              v-col(cols='6')
                v-btn(block color="secondary" @click="updateReceptionPoint" :loading="isLoading") Сохранить
              v-col(cols='6')
                v-btn(
                  color="red"
                  block
                  @click="openDeleteModal"
                  :md="24"
                ) Удалить
</template>
