<script>
import { GET_FAVORS } from '@/common/constants/wait'
import { mapGetters, mapActions } from 'vuex'

import FavorsCreateModal from './FavorsCreateModal'
import FavorsDeleteModal from './FavorsDeleteModal'
import FavorsSubFavors from './FavorsSubFavors'
import FavorsUpdateModal from './FavorsUpdateModal'

export default {
  name: 'Favors',

  data: () => ({
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'name', text: 'Название', align: 'center' },
      { value: 'slug', text: 'Slug', align: 'center' },
      { value: 'description', text: 'Описание', align: 'center' },
      { value: 'actions', text: 'Действия', align: 'center' },
    ],
  }),

  components: {
    FavorsCreateModal,
    FavorsDeleteModal,
    FavorsUpdateModal,
    FavorsSubFavors,
  },

  computed: {
    ...mapGetters('favors', {
      $_favors: 'favors',
      $_total: 'total',
    }),

    isLoading() {
      return this.$wait.is(GET_FAVORS)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_showModal: 'show',
    }),

    ...mapActions('favors', {
      $_getFavors: 'getFavors',
      $_setCurrentFavor: 'setCurrentFavor',
      $_clearFavors: 'clear',
    }),

    async fetchFavors() {
      try {
        this.$wait.start(GET_FAVORS)

        await this.$_getFavors()
      } catch (error) {
        return
      } finally {
        this.$wait.end(GET_FAVORS)
      }
    },

    openCreateModal() {
      this.$_showModal({ name: 'FavorsCreateModal' })
    },

    openUpdateModal({ id }) {
      this.$_setCurrentFavor(id)
      this.$_showModal({ name: 'FavorsUpdateModal', open: true })
    },

    openSubFavorsModal({ id }) {
      this.$_setCurrentFavor(id)
      this.$_showModal({ name: 'FavorsSubFavorsModal', open: true })
    },
  },

  mounted() {
    this.fetchFavors()
  },

  beforeDestroy() {
    this.$_clearFavors()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Услуги
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_favors"
      :loading="isLoading"
      disable-sort
      hide-default-footer
      @click:row="openUpdateModal"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        FavorsDeleteModal
        FavorsCreateModal
        FavorsUpdateModal
        FavorsSubFavors
      template(#item.id="{ item }")
        span {{ item.id }}
      template(#item.name="{ item }")
        .text-truncate {{ item.name }}
      template(#item.description="{ item }")
        .text-truncate {{ item.description || 'Отсутствует' }}
      template(#item.slug="{ item }")
        .text-truncate {{ item.slug }}
      template(#item.actions="{ item }")
        v-btn(text color="primary" @click.stop="openSubFavorsModal(item)") Список услуг
</template>

<style scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
