<script>
import FavorsSubFavorsCreateModal from './FavorsSubFavorsCreateModal'
import FavorsSubFavorsDeleteModal from './FavorsSubFavorsDeleteModal'
import FavorsSubFavorsModal from './FavorsSubFavorsModal'
import FavorsSubFavorsUpdateModal from './FavorsSubFavorsUpdateModal'

export default {
  name: 'FavorsSubFavors',

  components: {
    FavorsSubFavorsModal,
    FavorsSubFavorsCreateModal,
    FavorsSubFavorsUpdateModal,
    FavorsSubFavorsDeleteModal,
  },
}
</script>

<template lang="pug">
  div
    FavorsSubFavorsModal
    FavorsSubFavorsDeleteModal
    FavorsSubFavorsUpdateModal
    FavorsSubFavorsCreateModal
</template>
