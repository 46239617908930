import cookie from 'tiny-cookie'

export class TokenWrapper {
  constructor() {
    this.key = 'token'
  }

  set(token) {
    cookie.set(this.key, token, { path: '/', sameSite: false })
  }

  get() {
    return cookie.get(this.key)
  }

  remove() {
    cookie.remove(this.key)
  }
}

export function setup(_, inject) {
  inject({ $token: new TokenWrapper() })
}
