import {
  object,
  string,
  optional,
  integer,
  nullable,
  boolean,
} from 'superstruct'

const name = object({
  first: string(),
  last: string(),
  middle: optional(string()),
})

export const sendCodeRequestStruct = object({
  data: object({
    phone: string(),
    name: optional(name),
  }),

  params: object({
    token: string(),
  }),
})

export const sendCodeResponseStruct = object({
  status: string(),
  id: string(),
  is_new: boolean(),
})

export const verifyCodeReqestStruct = object({
  params: object({
    code: string(),
    phone: string(),
  }),
})

export const getUserResponseStruct = object({
  id: integer(),
  name: nullable(name),
  phone: string(),
  joined_at: integer(),
  is_admin: boolean(),
})
