<template lang="pug">
  v-app
    v-snackbar(v-model="snackbar" color="secondary"  top) {{ $_text || '' }}
    .application(v-if='!isLoading && $_user && $_user.is_admin')
      TheHeader
      TheSidebar
      v-main
        v-container(fluid)
          RouterView
    Auth(v-if="!isLoading && !$_user || !isLoading && !$_user.is_admin")
    v-progress-circular(
      :size="70"
      :width="7"
      color="secondary"
      indeterminate
      v-if="isLoading"
    ).ma-auto
</template>

<script>
import { SETUP_USER } from '@/common/constants/wait'
import Auth from '@/components/Auth'
import TheHeader from '@/components/layout/TheHeader'
import TheSidebar from '@/components/layout/TheSidebar'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    Auth,
    TheSidebar,
    TheHeader,
  },

  data: () => ({
    snackbar: false,
  }),

  watch: {
    $_isSnackbarVisible: function (value) {
      this.snackbar = value
    },

    snackbar: function (value) {
      if (!value) this.$_toggleSnackbar({ value: false, text: '' })
    },
  },

  async mounted() {
    try {
      this.$wait.start(SETUP_USER)

      await this.$_setupUser()
    } catch (error) {
      console.error(error)
    } finally {
      this.$wait.end(SETUP_USER)
    }
  },

  computed: {
    ...mapGetters('auth', {
      $_user: 'user',
    }),

    ...mapGetters('snackbar', {
      $_isSnackbarVisible: 'isVisible',
      $_text: 'text',
    }),

    isLoading() {
      return this.$wait.is(SETUP_USER)
    },
  },

  methods: {
    ...mapActions('auth', {
      $_setupUser: 'setupUser',
    }),

    ...mapActions('snackbar', {
      $_toggleSnackbar: 'toggleSnackbar',
    }),
  },
}
</script>
