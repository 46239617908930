<script>
import { CREATE_USER } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'UsersCreateModal',

  data: () => ({
    phone: '',
    name: {
      first: '',
      last: '',
      middle: '',
    },
    isUserFormValid: false,
    phoneError: null,
    phoneMask: ['+', ...Array(20).fill(/[0-9]|[-+() ]/)],
    phoneRules: [
      (v) => !!v || 'Заполните номер телефона',
      (v) =>
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(
          v
        ) || 'Введите правильный номер телефона',
    ],
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'UsersCreateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'UsersCreateModal', open: value })

        this.name = {
          first: '',
          last: '',
          middle: '',
        }

        this.phone = ''

        this.phoneError = null

        this.$refs.form.reset()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_USER)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),

    ...mapActions('users', {
      $_createUser: 'createUser',
    }),

    async createUser() {
      this.$wait.start(CREATE_USER)

      this.phoneError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isUserFormValid) return

          await this.$_createUser({
            phone: this.phone,
            name: this.name,
          })

          this.dialogVisible = false
        } catch (error) {
          if (error.type === 'user_already_exists_error')
            this.phoneError = 'Пользователь с таким телефоном уже существует.'
          return
        } finally {
          this.$wait.end(CREATE_USER)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Добавить пользователя
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isUserFormValid" ref="form")
            v-row
              v-text-field(
                    label="Номер телефона" 
                    required
                    :rules="phoneRules"
                    v-model="phone"
                    v-mask="phoneMask"
                  )
            v-row
              v-text-field(
                label="Фамилия" 
                required
                v-model="name.last"
                :rules="[(v) => Boolean(v) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Имя" 
                required
                v-model="name.first"
                :rules="[(v) => Boolean(v) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Отчество (если есть)" 
                v-model="name.middle"
              )
            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click="createUser") Добавить
</template>
