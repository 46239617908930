export const CREATE_FAVOR = 'CREATE_FAVOR'
export const DELETE_FAVOR = 'DELETE_FAVOR'
export const UPDATE_FAVOR = 'UPDATE_FAVOR'
export const SET_FAVORS = 'SET_FAVORS'
export const SET_CURRENT_FAVOR = 'SET_CURRENT_FAVOR'
export const SET_CURRENT_SUBFAVOR = 'SET_CURRENT_SUBFAVOR'
export const CREATE_SUBFAVOR = 'CREATE_SUBFAVOR'
export const UPDATE_SUBFAVOR = 'UPDATE_SUBFAVOR'
export const DELETE_SUBFAVOR = 'DELETE_SUBFAVOR'
export const CLEAR = 'CLEAR'
