var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap mt-2 mb-4"},[_c('h1',[_vm._v("Золотые статусы")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_goldStatuses,"loading":_vm.isLoading,"disable-sort":"","hide-default-footer":"","page":_vm.search.page,"items-per-page":_vm.search.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.search, "page", $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',[_vm._v("Загрузка")])]},proxy:true},{key:"top",fn:function(){return [_c('GoldStatusFormModal'),_c('DeleteGoldStatusModal'),_c('div',{staticClass:"d-flex flex-wrap align-center pt-4"},[_c('v-autocomplete',{staticClass:"ml-4 pa-0",staticStyle:{"max-width":"250px"},attrs:{"items":_vm.locations,"clearable":"","label":"Локация","item-text":"name","item-value":"slug","loading":_vm.isCitiesLoading || _vm.isMoreCitiesloading,"search-input":_vm.searchLocation},on:{"change":_vm.onLocationSearch,"update:searchInput":function($event){_vm.searchLocation=$event},"update:search-input":function($event){_vm.searchLocation=$event}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.getItemName(data.item)))])]}}]),model:{value:(_vm.search.location),callback:function ($$v) {_vm.$set(_vm.search, "location", $$v)},expression:"search.location"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"primary"},on:{"click":_vm.clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-autorenew")])],1)]}}])},[_c('span',[_vm._v("Очистить фильтры")])])],1)]},proxy:true},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-truncate primary--text",attrs:{"to":("/companies?company=" + (item.company.id))}},[_vm._v(_vm._s(item.company.name))])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.location.name ? item.location.name : 'Не установлен'))]),(item.location.city)?_c('span',[_vm._v(_vm._s(item.location.city.name)+" ")]):_vm._e()]}},{key:"item.point",fn:function(ref){
var item = ref.item;
return [(item.point)?_c('router-link',{staticClass:"text-truncate primary--text",attrs:{"to":{path: '/reception-points', query: {site: _vm.getLink(item)}}}},[(item.point.location.city.slug === item.location.slug)?_c('span',[_vm._v(_vm._s(item.point.location.city.name)+", ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.point.location.address))])]):_c('v-tooltip',{attrs:{"max-width":"250px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Не установлен")])]}}],null,true)},[_c('span',[_vm._v("Золотой статус автоматически устанавливается приемке с самым большим баллом по выдаче в данной местности.")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.timeFormat(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openUpdateModal(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.openDeleteModal(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2),(_vm.pagesCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"my-6 px-4",attrs:{"disabled":_vm.isLoading,"color":"primary","total-visible":20,"length":_vm.pagesCount},on:{"input":_vm.onPageChange},model:{value:(_vm.search.page),callback:function ($$v) {_vm.$set(_vm.search, "page", $$v)},expression:"search.page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }