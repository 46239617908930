import Vue from 'vue'
import VueWait from 'vue-wait'

export function setup(app) {
  Vue.use(VueWait)

  app.wait = new VueWait({
    useVuex: true,
    vuexModuleName: 'wait',
  })
}
