import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    cities: [],
    regions: [],
    currentCity: null,
    total: null,
  }),

  mutations,
  getters,
  actions,
}
