<script>
import { UPDATE_CITY } from '@/common/constants/wait'
import isEmpty from 'lodash/isEmpty'
import { mapActions, mapGetters } from 'vuex'

import CitiesTextEditor from './CitiesTextEditor'

const formTemplate = {
  name: '',
  postalCode: null,
  region: '',
  federalDistrict: null,
  longitude: null,
  latitude: null,
  population: null,
  seo: {
    reception: '',
    export: '',
    dismantling: '',
    prices: '',
  },
  priority: null,
  slug: '',
  regionType: null,
  declination: {
    V: '',
    D: '',
    P: '',
    R: '',
    T: '',
    multiple: {
      V: '',
      D: '',
      I: '',
      P: '',
      R: '',
      T: '',
    },
  },
}

export default {
  name: 'CitiesUpdateModal',

  components: {
    CitiesTextEditor,
  },

  data: () => ({
    form: { ...formTemplate },

    isCityFormValid: false,
    federalDistrictItems: [
      'Центральный',
      'Северо-Западный',
      'Южный',
      'Северо-Кавказский',
      'Приволжский',
      'Уральский',
      'Сибирский',
      'Дальевосточный',
    ],
    regionTypeItems: [
      'республика',
      'область',
      'край',
      'чувашия',
      'автономный округ',
    ],

    slugError: null,
    nameError: null,
    postalCodeError: null,
    priorityError: null,
  }),

  watch: {
    $_currentCity: function (value) {
      if (!value) return

      let declination = {
        V: '',
        D: '',
        P: '',
        R: '',
        T: '',
        multiple: {
          V: '',
          D: '',
          I: '',
          P: '',
          R: '',
          T: '',
        },
      }

      if (!isEmpty(value.declination)) {
        declination = {
          V: value.declination.В || null,
          D: value.declination.Д || null,
          P: value.declination.П || null,
          R: value.declination.Р || null,
          T: value.declination.Т || null,
          multiple: {
            V: value.declination.множественное?.В,
            D: value.declination.множественное?.Д,
            I: value.declination.множественное?.И,
            P: value.declination.множественное?.П,
            R: value.declination.множественное?.Р,
            T: value.declination.множественное?.Т,
          },
        }
      }

      const form = {
        name: value.name,
        postalCode: value.postal_code,
        region: value.region,
        federalDistrict: value.federal_district,
        longitude: value.longitude,
        latitude: value.latitude,
        population: value.population,
        seo: {
          reception: value.seo.reception || '',
          export: value.seo.export || '',
          dismantling: value.seo.dismantling || '',
          prices: value.seo.prices || '',
        },
        priority: value.priority || null,
        slug: value.slug || null,
        regionType: value.region_type || null,
        declination,
      }

      this.form = form
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('cities', {
      $_currentCity: 'currentCity',
      $_cities: 'cities',
      $_total: 'total',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'CitiesUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'CitiesUpdateModal', open: value })

        let declination = {
          V: '',
          D: '',
          P: '',
          R: '',
          T: '',
          multiple: {
            V: '',
            D: '',
            I: '',
            P: '',
            R: '',
            T: '',
          },
        }

        if (!isEmpty(this.$_currentCity.declination)) {
          declination = {
            V: this.$_currentCity.declination.В || null,
            D: this.$_currentCity.declination.Д || null,
            P: this.$_currentCity.declination.П || null,
            R: this.$_currentCity.declination.Р || null,
            T: this.$_currentCity.declination.Т || null,
            multiple: {
              V: this.$_currentCity.declination.множественное?.В,
              D: this.$_currentCity.declination.множественное?.Д,
              I: this.$_currentCity.declination.множественное?.И,
              P: this.$_currentCity.declination.множественное?.П,
              R: this.$_currentCity.declination.множественное?.Р,
              T: this.$_currentCity.declination.множественное?.Т,
            },
          }
        }

        const form = {
          name: this.$_currentCity.name,
          postalCode: this.$_currentCity.postal_code,
          region: this.$_currentCity.region,
          federalDistrict: this.$_currentCity.federal_district,
          longitude: this.$_currentCity.longitude,
          latitude: this.$_currentCity.latitude,
          population: this.$_currentCity.population,
          seo: {
            reception: this.$_currentCity.seo.reception || '',
            export: this.$_currentCity.seo.export || '',
            dismantling: this.$_currentCity.seo.dismantling || '',
            prices: this.$_currentCity.seo.prices || '',
          },
          priority: this.$_currentCity.priority || null,
          slug: this.$_currentCity.slug || null,
          regionType: this.$_currentCity.region_type || null,
          declination,
        }

        this.form = form

        this.postalCodeError = null
        this.priorityError = null
        this.nameError = null
        this.slugError = null
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_CITY)
    },

    isDisabled() {
      return !this.isCityFormValid
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('cities', {
      $_updateCity: 'updateCity',
    }),

    async updateCity() {
      this.$wait.start(UPDATE_CITY)

      this.$refs.form.resetErrorBag()
      this.nameError = null
      this.slugError = null
      this.postalCodeError = null

      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_cities.some((item) => {
            return (
              item.name.toUpperCase() === this.form.name.toUpperCase() &&
              item.id !== this.$_currentCity.id
            )
          })

          const isPostalCodeDublicate = this.$_cities.some((item) => {
            return (
              item.postal_code === this.form.postalCode &&
              item.id !== this.$_currentCity.id
            )
          })

          const isSlugDublicate = this.$_cities.some((item) => {
            if (!item.slug) return
            return (
              item.slug.toUpperCase() === this.form.slug.toUpperCase() &&
              item.id !== this.$_currentCity.id
            )
          })

          const isPriorityDublicate = this.$_cities.some((item) => {
            if (!item.priority) return
            return (
              Number(item.priority) === Number(this.form.priority) &&
              item.id !== this.$_currentCity.id
            )
          })

          if (
            isNameDublicate ||
            isSlugDublicate ||
            isPostalCodeDublicate ||
            isPriorityDublicate
          ) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'
            if (isPostalCodeDublicate) this.postalCodeError = 'Найден дубликат.'
            if (isPriorityDublicate) this.priorityError = 'Найден дубликат.'

            this.$nextTick(() => {
              const el = document.querySelector(
                '.v-input.error--text:first-of-type'
              )

              el.scrollIntoView({ block: 'center' })
            })

            return
          }

          if (!this.isCityFormValid) return

          await this.$_updateCity({
            id: this.$_currentCity.id,
            city: this.form,
          })

          this.nameError = null
          this.slugError = null
          this.postalCodeError = null
          this.priorityError = null

          this.form = { ...formTemplate }

          this.$wait.end(UPDATE_CITY)

          this.dialogVisible = false
        } catch (error) {
          console.log(error)
        } finally {
          this.$wait.end(UPDATE_CITY)
        }
      })
    },

    openDeleteModal() {
      this.$_showModal({ name: 'CitiesDeleteModal' })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
    fullscreen
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-toolbar-title Редактирование города
        v-spacer
        v-btn(color="red" @click="dialogVisible = false") Закрыть
      v-card-actions
        v-col
          v-form(v-model="isCityFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="form.name"
                :rules="[() => Boolean(form.name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-text-field(
                label="Почтовый код"
                type="number" 
                required
                v-model="form.postalCode"
                :rules="[() => Boolean(form.postalCode) || 'Поле обязательно']"
                :error-messages="postalCodeError"
              )
            v-row
              v-text-field(
                label="Регион"
                required
                v-model="form.region"
                :rules="[() => Boolean(form.region) || 'Поле обязательно']"
              )
            v-row
              v-select(
                label="Федеральный округ"
                :items="federalDistrictItems"
                v-model='form.federalDistrict'
                required
                :rules="[() => Boolean(form.federalDistrict) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Долгота"
                required
                type="number"
                v-model="form.longitude"
                :rules="[() => Boolean(form.longitude) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Широта"
                required
                type="number"
                v-model="form.latitude"
                :rules="[() => Boolean(form.latitude) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Население"
                type="number"
                required
                v-model="form.population"
                :rules="[() => Boolean(form.population) || 'Поле обязательно']"
              )
            v-row.mt-8
              .font-weight-bold SEO
            v-row Прием металлолома
            v-row
              CitiesTextEditor(v-model="form.seo.reception" placeholder="Прием")
            v-row.mt-8 Вывоз металлолома
            v-row
              CitiesTextEditor(v-model="form.seo.export" placeholder="Вывоз")
            v-row.mt-8 Демонтаж металлолома
            v-row
              CitiesTextEditor(v-model="form.seo.dismantling" placeholder="Демонтаж")
            v-row.mt-8 Цены
            v-row
              CitiesTextEditor(v-model="form.seo.prices" placeholder="Цены")
            v-row.mt-8
              .font-weight-bold Не обязательно
            v-row
              v-text-field(
                label="Приоритет"
                type="number"
                v-model="form.priority"
                :error-messages="priorityError"
              )
            v-row
              v-text-field(
                label="Slug"
                v-model="form.slug"
                :error-messages="slugError"
              )
            v-row
              v-select(
                :items="regionTypeItems"
                label="Тип региона"
                v-model="form.regionType"
                clearable
              )
            v-row
              .font-weight-medium Склонения
            v-row
              v-text-field(
                label="В"
                v-model="form.declination.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="form.declination.D"
              )
            v-row
              v-text-field(
                label="П"
                v-model="form.declination.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="form.declination.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="form.declination.T"
              )
            v-row
              .font-weight-medium Множественные Склонения
            v-row
              v-text-field(
                label="В"
                v-model="form.declination.multiple.V"
              )
            v-row
              v-text-field(
                label="Д"
                v-model="form.declination.multiple.D"
              )
            v-row
              v-text-field(
                label="И"
                v-model="form.declination.multiple.I"
              )
            v-row
              v-text-field(
                label="П"
                v-model="form.declination.multiple.P"
              )
            v-row
              v-text-field(
                label="Р"
                v-model="form.declination.multiple.R"
              )
            v-row
              v-text-field(
                label="Т"
                v-model="form.declination.multiple.T"
              )
            v-row
              v-col(cols='6')
                v-btn(block color="secondary" @click="updateCity" :loading="isLoading") Сохранить
              v-col(cols='6')
                v-btn(
                  color="red"
                  block
                  @click="openDeleteModal"
                  :md="24"
                ) Удалить
</template>
