<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FavorsSubFavorsModal',

  data: () => ({
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'name', text: 'Название', align: 'center' },
      { value: 'slug', text: 'Slug', align: 'center' },
      { value: 'description', text: 'Описание', align: 'center' },
    ],
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('favors', {
      $_subFavors: 'subFavors',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'FavorsSubFavorsModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'FavorsSubFavorsModal', open: value })
      },
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('favors', {
      $_setCurrentSubFavor: 'setCurrentSubFavor',
    }),

    openCreateModal() {
      this.$_showModal({ name: 'FavorsSubFavorsCreateModal' })
    },

    openUpdateModal({ id }) {
      this.$_setCurrentSubFavor(id)
      this.$_showModal({ name: 'FavorsSubFavorsUpdateModal', open: true })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    fullscreen
    color="white"
  )
    v-card
      v-toolbar.d-flex.align-center.flex-wrap.justify-space-between(color="primary" flat dark width="100%")
        h1 Услуги
        v-btn.mx-4(color="secondary" @click="openCreateModal") Добавить
        v-btn(color="red" @click="dialogVisible = false") Закрыть
      v-data-table.elevation-1.row-pointer(
        :headers="headers"
        :items="$_subFavors"
        disable-sort
        hide-default-footer
        @click:row="openUpdateModal"
      )
        template(slot="no-data")
          span.pa-2 Ничего не найдено
        template(#item.id="{ item }")
          span {{ item.id }}
        template(#item.name="{ item }")
          .text-truncate {{ item.name }}
        template(#item.description="{ item }")
          .text-truncate {{ item.description || 'Отсутствует' }}
        template(#item.slug="{ item }")
          .text-truncate {{ item.slug }}
</template>

<style scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
