<script>
import { UPDATE_COMPANY, PHOTO_LOADING } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CompaniesUpdateModal',

  data: () => ({
    name: '',
    avatar: '',
    email: '',
    site: '',
    user: null,
    isCompaniesFormValid: false,
    siteRules: [
      (v) =>
        !v ||
        /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
          v
        ) ||
        'Введите правильную ссылку на сайт',
    ],
    emailRules: [
      (v) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        'Введите правильный Email',
    ],
  }),

  watch: {
    $_currentCompany: function (value) {
      if (!value) return

      this.name = value.name || ''
      this.avatar = value.avatar || ''
      this.email = value.email || ''
      this.site = value.site || ''
      this.user = value.user.id || null
    },
  },

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('users', {
      $_users: 'users',
    }),

    ...mapGetters('companies', {
      $_companies: 'companies',
      $_currentCompany: 'currentCompany',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'CompaniesUpdateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'CompaniesUpdateModal', open: value })

        this.name = this.$_currentCompany.name || ''
        this.avatar = this.$_currentCompany.avatar || ''
        this.email = this.$_currentCompany.email || ''
        this.site = this.$_currentCompany.site || ''
      },
    },

    isLoading() {
      return this.$wait.is(UPDATE_COMPANY)
    },

    isPhotoLoading() {
      return this.$wait.is(PHOTO_LOADING)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('companies', {
      $_uploadFile: 'uploadFile',
      $_updateCompany: 'updateCompany',
    }),

    async uploadFile(files) {
      this.$wait.start(PHOTO_LOADING)

      const file = files[0]

      const formData = new FormData()

      formData.append('file', file, file.name)

      const avatar = await this.$_uploadFile(formData)

      this.avatar = avatar.url

      this.$wait.end(PHOTO_LOADING)
    },

    uploadPhoto() {
      this.$refs.fileInput.click()
    },

    async updateCompany() {
      this.$wait.start(UPDATE_COMPANY)

      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          if (!this.isCompaniesFormValid) return

          const companyParams = {
            id: this.$_currentCompany.id,
            userId: this.user,
            name: this.name,
            email: this.email || undefined,
            site: this.site || undefined,
          }

          if (this.avatar) {
            companyParams.avatar = this.avatar
          }

          await this.$_updateCompany(companyParams)

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(UPDATE_COMPANY)
        }
      })
    },

    openDeleteModal() {
      this.$_showModal({ name: 'CompaniesDeleteModal' })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Редактировать компанию
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isCompaniesFormValid" ref="form")
            v-row
              v-autocomplete(
                  :items="$_users"
                  label="Пользователь"
                  clearable
                  required
                  v-model="user"
                  item-text="phone"
                  item-value="id"
                  :rules="[() => Boolean(user) || 'Поле обязательно']"
                )
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
              )
            v-row
              v-text-field(
                label="Email" 
                v-model="email"
                :rules="emailRules"
              )
            v-row
              v-text-field(
                label="Сайт"
                v-model="site"
                :rules="siteRules"
              )
            v-row
              input(
                type="file"
                ref="fileInput"
                v-show="false"
                accept=".png, .jpg, .jpeg"
                @change="uploadFile($event.target.files)"
              )
              v-card(@click="uploadPhoto" width="100%")
                v-card-title Фото
                v-card-actions.pa-0
                  v-row(
                    v-if="!avatar"
                    align="center"
                    justify="center"
                  )
                    v-progress-circular.mb-6(
                      v-if="isPhotoLoading && !avatar"
                      indeterminate
                      color="primary"
                    )
                  v-img(
                    v-else
                    :aspect-ratio="16/9"
                    :src="avatar"
                    contain
                  )
                    template(slot:placeholder v-if="isPhotoLoading")
                      v-row.fill-height.ma-0.background(
                        align="center"
                        justify="center"
                        color="primary"
                        :class="{'mb-10': isPhotoLoading}"
                      )
                        v-progress-circular(
                          indeterminate
                          color="primary"
                        )
            v-row(justify='end')
              v-col(cols='6')
                v-btn(block color="secondary" @click="updateCompany" :loading="isLoading") Сохранить
</template>
