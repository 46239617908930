<script>
import { CREATE_FAVOR } from '@/common/constants/wait'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FavorsCreateModal',

  data: () => ({
    name: '',
    description: '',
    slug: '',
    isFavorsFormValid: false,
    nameError: null,
    slugError: null,
  }),

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('favors', {
      $_favors: 'favors',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'FavorsCreateModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'FavorsCreateModal', open: value })

        this.name = ''
        this.description = ''
        this.slug = ''

        this.nameError = null
        this.slugError = null

        this.$refs.form.reset()
      },
    },

    isLoading() {
      return this.$wait.is(CREATE_FAVOR)
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
    }),

    ...mapActions('favors', {
      $_createFavor: 'createFavor',
    }),

    createFavor() {
      this.$wait.start(CREATE_FAVOR)

      this.nameError = null
      this.slugError = null
      this.$refs.form.resetErrorBag()
      this.$refs.form.validate()

      this.$nextTick(async () => {
        try {
          const isNameDublicate = this.$_favors.some((item) => {
            return item.name.toUpperCase() === this.name.toUpperCase()
          })

          const isSlugDublicate = this.$_favors.some((item) => {
            return item.slug.toUpperCase() === this.slug.toUpperCase()
          })

          if (isNameDublicate || isSlugDublicate) {
            if (isNameDublicate) this.nameError = 'Найден дубликат.'
            if (isSlugDublicate) this.slugError = 'Найден дубликат.'

            this.$wait.end(CREATE_FAVOR)

            return
          }

          if (!this.isFavorsFormValid) return

          await this.$_createFavor({
            name: this.name,
            description: this.description,
            slug: this.slug,
          })

          this.dialogVisible = false
        } catch (error) {
          return
        } finally {
          this.$wait.end(CREATE_FAVOR)
        }
      })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    width="500"
  )
    v-card
      v-toolbar(
        color="primary"
        dark
        flat
      )
        v-spacer
        v-toolbar-title Добавить услугу
        v-spacer
      v-card-actions
        v-col
          v-form(v-model="isFavorsFormValid" ref="form")
            v-row
              v-text-field(
                label="Название" 
                required
                v-model="name"
                :rules="[() => Boolean(name) || 'Поле обязательно']"
                :error-messages="nameError"
              )
            v-row
              v-text-field(
                label="Slug" 
                required
                v-model="slug"
                :rules="[() => Boolean(slug) || 'Поле обязательно']"
                :error-messages="slugError"
              )
            v-row
              v-textarea(
                label="Описание (не обязательно)" 
                v-model="description"
                auto-grow
                rows="1"
              )
            v-row
              v-col(cols='12')
                v-btn(block color="secondary" @click="createFavor") Добавить
</template>
