<script>
import { GET_COMPANIES } from '@/common/constants/wait'
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

import CompaniesCreateModal from './CompaniesCreateModal.vue'
import CompaniesDeleteModal from './CompaniesDeleteModal.vue'
import CompaniesUpdateModal from './CompaniesUpdateModal.vue'

export default {
  name: 'Companies',

  components: {
    CompaniesCreateModal,
    CompaniesUpdateModal,
    CompaniesDeleteModal,
  },

  data: () => ({
    noImageSrc: 'https://via.placeholder.com/300x300',
    search: {
      page: 1,
      itemsPerPage: 10,
      user: null,
      company: null,
      name: '',
    },
    noImageSrc: 'https://via.placeholder.com/300x300',
    headers: [
      { value: 'id', text: 'ID' },
      { value: 'avatar', text: 'Логотип', align: 'center' },
      { value: 'name', text: 'Название', align: 'center' },
      { value: 'email', text: 'Email', align: 'center' },
      { value: 'site', text: 'Сайт', align: 'center' },
      { value: 'user', text: 'Пользователь', align: 'center' },
      { value: 'created_at', text: 'Создана', align: 'center' },
      { value: 'updated_at', text: 'Обновлена', align: 'center' },
    ],
    errorImg: [],
  }),

  computed: {
    ...mapGetters('companies', {
      $_companies: 'companies',
      $_total: 'total',
    }),

    ...mapGetters('users', {
      $_users: 'users',
    }),

    isLoading() {
      return this.$wait.is(GET_COMPANIES)
    },

    pagesCount() {
      if (!this.$_total) return

      return Math.ceil(this.$_total / 10)
    },

    query() {
      return {
        limit: this.search.itemsPerPage,
        offset:
          this.search.page * this.search.itemsPerPage -
          this.search.itemsPerPage,
        userId: this.search.user,
        companyId: this.search.company,
        name: this.search.name,
      }
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('companies', {
      $_getCompanies: 'getCompanies',
      $_setCurrentCompany: 'setCurrentCompany',
      $_clearCompanies: 'clear',
    }),

    ...mapActions('users', {
      $_getUsers: 'getUsers',
      $_clearUsers: 'clear',
    }),

    openCreateModal() {
      this.$_showModal({ name: 'CompaniesCreateModal' })
    },

    openUpdateModal({ id }) {
      this.$_setCurrentCompany(id)
      this.$_showModal({ name: 'CompaniesUpdateModal', open: true })
    },

    async fetchCompanies() {
      try {
        this.$wait.start(GET_COMPANIES)

        await this.$_getCompanies(this.query)

        await this.$_getUsers({ limit: undefined, offset: undefined })
      } catch (error) {
        return
      } finally {
        this.$wait.end(GET_COMPANIES)
      }
    },

    timeFormat(time) {
      if (!time) return 'Не обновлялась'
      return format(new Date(time * 1000), 'dd/MM/yyyy HH:mm')
    },

    onImgError(errorId) {
      this.errorImg.push(errorId)
    },

    onSearch() {
      this.$router.replace({
        path: 'companies',
        query: { user: this.search.user },
      })

      this.search.page = 1
      this.search.company = null

      this.fetchCompanies()
    },

    onCompanySearch() {
      this.$router.replace({
        path: 'companies',
        query: { name: this.search.name },
      })

      this.search.page = 1
      this.search.company = null
      this.search.user = null

      this.fetchCompanies()
    },

    onPageChange() {
      this.$router.replace({
        path: 'companies',
        query: {
          ...this.$router.query,
          page: this.search.page === 1 ? undefined : this.search.page,
        },
      })

      this.fetchCompanies()
    },

    clearFilters() {
      this.search.user = null
      this.search.name = ''
      this.search.company = null
      this.search.page = 1

      this.onPageChange()
    },
  },

  mounted() {
    if (this.$route.query.page)
      this.search.page = Number(this.$route.query.page)
    if (this.$route.query.user)
      this.search.user = Number(this.$route.query.user)
    if (this.$route.query.company)
      this.search.company = Number(this.$route.query.company)

    this.fetchCompanies()
  },

  beforeDestroy() {
    this.$_clearCompanies()
    this.$_clearUsers()
  },
}
</script>

<template lang="pug">
  div
    .d-flex.align-center.flex-wrap.mt-2.mb-4
      h1 Компании
      v-btn.mx-4(color="primary" @click="openCreateModal") Добавить
    v-data-table.elevation-1.row-pointer(
      :headers="headers"
      :items="$_companies"
      :loading="isLoading"
      disable-sort
      hide-default-footer
      :page.sync="search.page"
      :items-per-page="search.itemsPerPage"
      @click:row="openUpdateModal"
    )
      template(slot="no-data")
        span.pa-2 Ничего не найдено
      template(#loading)
        span Загрузка
      template(#top)
        CompaniesCreateModal
        CompaniesUpdateModal
        CompaniesDeleteModal
        .d-flex.flex-wrap.pt-4
          v-autocomplete.ml-4.pa-0(
            :items="$_users"
            label="Пользователь"
            clearable
            v-model="search.user"
            @input="onSearch"
            item-text="phone"
            item-value="id"
            style="max-width: 200px"
          )
            template(slot="no-data")
              span.pa-2 Ничего не найдено
          .d-flex.justify-center.align-center.ml-4(cols="1")
            .font-weight-medium или
          v-text-field.ml-4.pa-0(
            label="Название" 
            v-model="search.name"
            @input="onCompanySearch"
            style="max-width: 200px"
          )
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn.ml-4(icon color="primary" @click="clearFilters" v-bind="attrs" v-on="on") 
                v-icon mdi-autorenew
            span Очистить фильтры
      template(#item.id="{ item }")
        span {{ item.id }}
      template(#item.name="{ item }")
        .text-truncate {{ item.name }}
      template(#item.site="{ item }")
        .text-truncate {{ item.site || 'Отсутствует' }} 
      template(#item.email="{ item }")
        .text-truncate {{ item.email || 'Отсутствует' }} 
      template(#item.avatar="{ item }")
        .img-template.d-flex.align-center.justify-center( style="width: 100px;" v-if="!item.avatar || errorImg.some(id => id === item.id)") Нет изображения
        v-img(
          v-if="item.avatar"
          :src="item.avatar"
          max-height="80"
          max-width="100"
          contain
          @error="onImgError(item.id)"
        )
      template(#item.created_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.created_at) }}
      template(#item.updated_at="{ item }")
        span.text-no-wrap {{ timeFormat(item.updated_at) }}
      template(#item.user="{ item }")
        router-link(:to="{path: '/users', query: {phone: item.user.phone}}").text-truncate.primary--text {{ item.user.phone  }}
    .text-center.pt-2
      v-pagination.my-6.px-4(
        :disabled="isLoading"
        color="primary"
        v-model='search.page'
        :total-visible="20"
        :length="pagesCount"
        @input="onPageChange"
      )
</template>

<style scoped>
.img-template {
  width: 300px;
  height: 80px;
  background: #81c784;
  font-weight: 700;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
