var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap mt-2 mb-4"},[_c('h1',[_vm._v("Компании")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_companies,"loading":_vm.isLoading,"disable-sort":"","hide-default-footer":"","page":_vm.search.page,"items-per-page":_vm.search.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.search, "page", $event)},"click:row":_vm.openUpdateModal},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',[_vm._v("Загрузка")])]},proxy:true},{key:"top",fn:function(){return [_c('CompaniesCreateModal'),_c('CompaniesUpdateModal'),_c('CompaniesDeleteModal'),_c('div',{staticClass:"d-flex flex-wrap pt-4"},[_c('v-autocomplete',{staticClass:"ml-4 pa-0",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.$_users,"label":"Пользователь","clearable":"","item-text":"phone","item-value":"id"},on:{"input":_vm.onSearch},model:{value:(_vm.search.user),callback:function ($$v) {_vm.$set(_vm.search, "user", $$v)},expression:"search.user"}},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2),_c('div',{staticClass:"d-flex justify-center align-center ml-4",attrs:{"cols":"1"}},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("или")])]),_c('v-text-field',{staticClass:"ml-4 pa-0",staticStyle:{"max-width":"200px"},attrs:{"label":"Название"},on:{"input":_vm.onCompanySearch},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"primary"},on:{"click":_vm.clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-autorenew")])],1)]}}])},[_c('span',[_vm._v("Очистить фильтры")])])],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.site",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.site || 'Отсутствует')+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.email || 'Отсутствует')+" ")])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(!item.avatar || _vm.errorImg.some(function (id) { return id === item.id; }))?_c('div',{staticClass:"img-template d-flex align-center justify-center",staticStyle:{"width":"100px"}},[_vm._v("Нет изображения")]):_vm._e(),(item.avatar)?_c('v-img',{attrs:{"src":item.avatar,"max-height":"80","max-width":"100","contain":""},on:{"error":function($event){return _vm.onImgError(item.id)}}}):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.timeFormat(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.timeFormat(item.updated_at)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-truncate primary--text",attrs:{"to":{path: '/users', query: {phone: item.user.phone}}}},[_vm._v(_vm._s(item.user.phone))])]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"my-6 px-4",attrs:{"disabled":_vm.isLoading,"color":"primary","total-visible":20,"length":_vm.pagesCount},on:{"input":_vm.onPageChange},model:{value:(_vm.search.page),callback:function ($$v) {_vm.$set(_vm.search, "page", $$v)},expression:"search.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }