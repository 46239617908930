import { toNumber } from 'lodash'
import values from 'lodash/values'

import * as types from './types'

const getCityParams = (city) => {
  let declination = {
    В: city.declination.V || undefined,
    Д: city.declination.D || undefined,
    П: city.declination.P || undefined,
    Р: city.declination.R || undefined,
    Т: city.declination.T || undefined,
  }

  if (values(city.declination.multiple).some((item) => Boolean(item))) {
    declination.множественное = {
      В: city.declination.multiple.V || undefined,
      Д: city.declination.multiple.D || undefined,
      И: city.declination.multiple.I || undefined,
      П: city.declination.multiple.P || undefined,
      Р: city.declination.multiple.R || undefined,
      Т: city.declination.multiple.T || undefined,
    }
  }

  return {
    name: city.name,
    postal_code: toNumber(city.postalCode),
    region: city.region,
    federal_district: city.federalDistrict,
    longitude: toNumber(city.longitude),
    latitude: toNumber(city.latitude),
    population: toNumber(city.population),
    seo: {
      reception: city.seo.reception || '',
      export: city.seo.export || '',
      dismantling: city.seo.dismantling || '',
      prices: city.seo.prices || '',
    },
    priority: toNumber(city.priority) || undefined,
    slug: city.slug || undefined,
    region_type: city.regionType || undefined,
    declination: declination || undefined,
  }
}

export default {
  setCurrentCity({ commit }, id) {
    commit(types.SET_CURRENT_CITY, id)
  },

  async createCity({ commit, dispatch }, city) {
    try {
      const cityParams = getCityParams(city)

      const _city = await this.$citiesApi.createCity({
        data: { ...cityParams },
      })

      commit(types.CREATE_CITY, _city)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Город создан' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось создать город' },
        { root: true }
      )

      throw error
    }
  },

  async getCities({ commit }, { limit, offset, query }) {
    try {
      const cities = await this.$citiesApi.getCities({
        params: {
          limit,
          offset,
          query: query || undefined,
        },
      })

      commit(types.SET_CITIES, cities)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async getFilteredCities({ commit }, { limit, offset, query }) {
    try {
      const cities = await this.$citiesApi.getCities({
        params: {
          limit,
          offset,
          query: query || undefined,
        },
      })

      return cities
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async getMoreCities({ commit }, { limit, offset }) {
    try {
      const cities = await this.$citiesApi.getCities({
        params: {
          limit,
          offset,
        },
      })

      commit(types.SET_MORE_CITIES, cities)
    } catch (error) {
      console.error(error)

      throw error
    }
  },

  async clear({ commit }) {
    commit(types.CLEAR)
  },

  async deleteCity({ commit, dispatch }, id) {
    try {
      await this.$citiesApi.deleteCity(id)()

      commit(types.DELETE_CITY, id)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Город удален' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось удалить город' },
        { root: true }
      )

      throw error
    }
  },

  async updateCity({ commit, dispatch }, { id, city }) {
    try {
      const cityParams = getCityParams(city)

      const _city = await this.$citiesApi.updateCity(id)({
        data: {
          ...cityParams,
        },
      })

      commit(types.UPDATE_CITY, _city)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Город обновлен' },
        { root: true }
      )
    } catch (error) {
      console.error(error)

      dispatch(
        'snackbar/toggleSnackbar',
        { value: true, text: 'Не удалось обновить город' },
        { root: true }
      )

      throw error
    }
  },
}
