<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MetalsSubmetalsModal',

  computed: {
    ...mapGetters('modal', {
      $_isVisible: 'isVisible',
    }),

    ...mapGetters('metals', {
      $_submetals: 'submetals',
    }),

    dialogVisible: {
      get() {
        return this.$_isVisible({ name: 'MetalsSubmetalsModal' })
      },

      set(value) {
        this.$_toggleModal({ name: 'MetalsSubmetalsModal', open: value })
      },
    },
  },

  methods: {
    ...mapActions('modal', {
      $_toggleModal: 'toggle',
      $_showModal: 'show',
      $_closeModal: 'close',
    }),

    ...mapActions('metals', {
      $_setCurrentSubmetal: 'setCurrentSubmetal',
    }),

    openCreateModal() {
      this.$_showModal({ name: 'MetalsSubmetalsCreateModal' })
    },

    openUpdateModal(id) {
      this.$_setCurrentSubmetal(id)
      this.$_showModal({ name: 'MetalsSubmetalsUpdateModal', open: true })
    },
  },
}
</script>

<template lang="pug">
  v-dialog(
    v-model="dialogVisible"
    fullscreen
    color="white"
  )
    v-card
      v-toolbar.d-flex.align-center.flex-wrap.justify-space-between(color="primary" flat dark width="100%")
        h1 Виды металлолома
        v-btn.mx-4(color="secondary" @click="openCreateModal") Добавить
        v-btn(color="red" @click="dialogVisible = false") Закрыть
      .d-flex.flex-wrap.flex-row
        v-card.ma-4(
          v-if="$_submetals"
          width="350" 
          v-for="submetal, index in $_submetals" 
          :key="`${submetal.name}-${index}`" 
          align="center"
          @click="openUpdateModal(submetal.id)"
        )
          .img-template.d-flex.align-center.justify-center(v-if="!submetal.photo") Нет изображения
          v-img(
            v-if="submetal.photo"
            :src="submetal.photo"
            max-height="80"
            width="100%"
          )
          v-card-text
            h2 {{ submetal.name }}
            .text-truncate {{ submetal.description || 'Нет описания' }}
          v-card-text.font-weight-bold Приоритет: {{ submetal.priority || 'не установлен'}}
          v-card-text.font-weight-bold Макс. цена: {{ submetal.max_price || 'не установлена' }}
</template>

<style scoped>
.img-template {
  width: 100%;
  height: 80px;
  background: #81c784;
  font-weight: 700;
}
</style>
