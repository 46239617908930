import {
  object,
  boolean,
  string,
  array,
  integer,
  number,
  optional,
  unknown,
  enums,
  nullable,
  assign,
  type,
  any,
} from 'superstruct'

export const location = type({
  name: string(),
  slug: string(),
  longitude: any(),
  latitude: any(),
  declination: unknown(),
  index: boolean(),
})

export const region = type({
  id: integer(),
  name: string(),
  slug: string(),
})

export const city = object({
  id: optional(integer()),
  name: string(),
  postal_code: nullable(integer()),
  region: string(),
  slug: optional(string()),
  federal_district: nullable(string()),
  longitude: number(),
  latitude: number(),
  population: integer(),
  index: optional(boolean()),
  declination: optional(unknown()),
  seo: object({
    reception: nullable(string()),
    export: nullable(string()),
    dismantling: nullable(string()),
    prices: nullable(string()),
  }),
  priority: optional(nullable(integer())),
  region_type: optional(
    nullable(
      enums([
        'республика',
        'область',
        'край',
        'чувашия',
        'автономный округ',
        'город',
      ])
    )
  ),
})

export const getCitiesRequestStruct = object({
  params: object({
    query: optional(string()),
    is_priority: optional(boolean()),
    large_or_with_reception: optional(boolean()),
    limit: optional(integer()),
    offset: optional(integer()),
  }),
})

export const getCitiesResponseStruct = object({
  total: integer(),
  regions: array(region),
  items: array(city),
})

export const updateCityRequestStruct = object({ data: city })

export const createCityRequestStruct = object({ data: city })

export const createCityResponseStruct = city
