import { BaseClient } from './BaseClient'
import {
  getCitiesRequestStruct,
  getCitiesResponseStruct,
  updateCityRequestStruct,
  createCityRequestStruct,
  createCityResponseStruct,
} from './types/Cities'

export class CitiesApi extends BaseClient {
  createCity = this.createRequest(
    {
      url: '/api/admin/cities',
      method: 'post',
    },
    createCityRequestStruct,
    createCityResponseStruct
  )

  deleteCity = (id) =>
    this.createRequest(
      {
        url: `/api/admin/cities/${id}`,
        method: 'delete',
      },
      null,
      null
    )

  updateCity = (id) =>
    this.createRequest(
      {
        url: `/api/admin/cities/${id}`,
        method: 'put',
      },
      updateCityRequestStruct,
      null
    )

  getCities = this.createRequest(
    {
      url: '/api/locations',
      method: 'get',
    },
    getCitiesRequestStruct,
    getCitiesResponseStruct
  )
}
