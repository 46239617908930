import {
  object,
  string,
  integer,
  array,
  nullable,
  optional,
  assign,
  unknown,
} from 'superstruct'

import { location } from './Cities'
import { company } from './Companies'

export const goldStatus = object({
  id: integer(),
  company: company,
  location: location,
  point_id: nullable(integer()),
  created_at: integer(),
})

export const getGoldStatusesRequestStruct = object({
  params: object({
    limit: optional(integer()),
    offset: optional(integer()),
  }),
})

export const getGoldStatusesResponseStruct = object({
  total: integer(),
  items: array(goldStatus),
})

export const createGoldStatusRequestStruct = object({
  data: object({
    location: string(),
    company_id: integer(),
    point_id: optional(integer()),
  }),
})

export const createGoldStatusResponseStruct = goldStatus

export const updateGoldStatusRequestStruct = object({
  data: object({
    location: string(),
    company_id: integer(),
    point_id: optional(integer()),
  }),
})

export const updateGoldStatusResponseStruct = goldStatus
