import CitiesView from '@/views/CitiesView'
import CompaniesView from '@/views/CompaniesView'
import FaqView from '@/views/FaqView'
import FavorsView from '@/views/FavorsView'
import GoldStatusesView from '@/views/GoldStatusesView'
import HomeView from '@/views/HomeView'
import MetalsView from '@/views/MetalsView'
import ReceptionPointsView from '@/views/ReceptionPointsView'
import UsersView from '@/views/UsersView'

export default [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/faq',
    name: 'Faq',
    component: FaqView,
  },
  {
    path: '/metals',
    name: 'Metals',
    component: MetalsView,
  },
  {
    path: '/cities',
    name: 'Cities',
    component: CitiesView,
  },
  {
    path: '/favors',
    name: 'Favors',
    component: FavorsView,
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersView,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: CompaniesView,
  },
  {
    path: '/reception-points',
    name: 'ReceptionPoints',
    component: ReceptionPointsView,
  },
  {
    path: '/gold-statuses',
    name: 'GoldStatuses',
    component: GoldStatusesView,
  },
]
