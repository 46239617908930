<template lang="pug">
  Cities
</template>

<script>
import Cities from '@/components/Cities'

export default {
  name: 'CitiesView',

  components: {
    Cities,
  },
}
</script>
