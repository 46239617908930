import * as types from './types'

export default {
  [types.CLEAR]: (state) => {
    state.companies = []
    state.total = null
  },

  [types.SET_COMPANY]: (state, company) => {
    state.companies = [company]
    state.total = 1
  },

  [types.SET_COMPANIES]: (state, companies) => {
    state.companies = companies.items
    state.total = companies.total
  },

  [types.SET_CURRENT_COMPANY]: (state, id) => {
    let company = state.companies.find((item) => item.id === id)
    state.currentCompany = company
  },

  [types.CREATE_COMPANY]: (state, company) => {
    state.companies.push(company)
  },

  [types.UPDATE_COMPANY]: (state, payload) => {
    let company = state.companies.find((item) => item.id === payload.id)

    company.name = payload.name
    company.phone = payload.phone
  },

  [types.DELETE_COMPANY]: (state, id) => {
    const removeIndex = state.companies.map((item) => item.id).indexOf(id)

    state.companies.splice(removeIndex, 1)

    state.currentCompany = null
  },
}
