<template lang="pug">
  Metals
</template>

<script>
import Metals from '@/components/Metals'

export default {
  name: 'MetalsView',

  components: {
    Metals,
  },
}
</script>
