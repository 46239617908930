<template lang="pug">
  GoldStatuses
</template>

<script>
import GoldStatuses from '@/components/GoldStatuses'

export default {
  name: 'GoldStatusesView',

  components: {
    GoldStatuses,
  },
}
</script>
