var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap mt-2 mb-4"},[_c('h1',[_vm._v("ЧаВО")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_vm._v("Добавить")])],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.$_faq,"loading":_vm.isLoading,"disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.openUpdateModal},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span',[_vm._v("Загрузка")])]},proxy:true},{key:"top",fn:function(){return [_c('FaqDeleteModal'),_c('FaqCreateModal'),_c('FaqUpdateModal')]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.title))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.description))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.timeFormat(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.timeFormat(item.updated_at)))])]}}])},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"pa-2"},[_vm._v("Ничего не найдено")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }