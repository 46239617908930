<template lang="pug">
  Companies
</template>

<script>
import Companies from '@/components/Companies'

export default {
  name: 'CompaniesView',

  components: {
    Companies,
  },
}
</script>
